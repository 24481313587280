import React, { useState, useRef, useEffect } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./RealtorAddTeammember.css";
import { useNavigate } from "react-router-dom";

type Office = {
  id: string;
  officeName: string;
};

interface PasswordInputProps {
  formik: FormikProps<{ password: string }>;
}

type SelectOption = {
  value: string;
  label: string;
};

const RealtorAddTeammember = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();
  const [offices, setOffices] = useState<Office[] | null>(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validation, setValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  const togglePasswordVisibility = () => {
    setPasswordVisible((prevVisibility) => !prevVisibility);
  };

  const validatePassword = (password: string) => {
    setValidation({
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      digit: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    formik.handleChange(e);
    validatePassword(value);
  };

  const [showValidationBox, setShowValidationBox] = useState(false);

  const handleFocus = () => {
    setShowValidationBox(true);
  };

  const handleBlur = () => {
    setShowValidationBox(false);
  };

  const permissionsOptions: SelectOption[] = [
    { value: "all", label: "All" },
    { value: "view", label: "View" },
    { value: "edit", label: "Edit" },
    { value: "delete", label: "Delete" },
  ];

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/offices`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setOffices(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOffices();
  }, [token]);

  const officesOptions =
    offices?.map((office) => ({
      value: office,
      label: office.officeName,
    })) || [];

  const formik = useFormik({
    initialValues: {
      photo: "",
      firstName: "",
      lastName: "",
      jobTitle: "",
      phone: "",
      email: "",
      assignedOffices: [] as Office[], // initialized as array for React Select
      permissions: [] as string[], // initialized as array for React Select
      password: "",
      statuss: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name can only contain letters")
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name can only contain letters")
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      jobTitle: Yup.string().required("Role is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      assignedOffices: Yup.array()
        // .of(Yup.string().required("Office is required"))
        // .min(1, "At least one office must be assigned")
        .required("Assign Offices is required"),
      permissions: Yup.array()
        .of(Yup.string().required("Permissions is required"))
        .min(1, "At least one permission must be assigned")
        .required("Permissions is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters long")
        .max(16, "Password must be max 16 characters long")
        .required("Password is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: values.email,
              password: values.password,
              userType: "team_member",
              firstName: values.firstName,
              lastName: values.lastName,
              jobTitle: values.jobTitle,
              phone1: values.phone,
              licenseType: "BROKERAGE_TEAM_MEMBER",
              status: values.statuss,
              offices: values.assignedOffices,
              accessLevel: values.permissions,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        toast.success("Teammember added successfully!");
        console.log("Form submission successful:", result);
        navigate("/realtor/teammembers", {
          state: { successMessage: "Teammember added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Teammember.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Add Team Member </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-1">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Job Title/Role{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="jobTitle"
                            name="jobTitle"
                            required
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                          >
                            <option value="">- Select -</option>
                            <option value="Agent">Agent</option>
                            <option value="Manager">Manager</option>
                            <option value="Admin">Admin</option>
                            <option value="VP">VP</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Legal">Legal</option>
                            <option value="Custom">Custom</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select role
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            required
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Password <span className="text-danger">*</span>
                          </label>
                          <div className="position-relative auth-pass-inputgroup">
                            <input
                              type={passwordVisible ? "text" : "password"}
                              className="form-control pe-5 password-input"
                              id="password"
                              name="password"
                              onChange={handlePasswordChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={formik.values.password}
                              aria-describedby="password"
                              // pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                              required
                            />
                            <button
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                              type="button"
                              id="password-addon"
                              onClick={togglePasswordVisibility}
                            >
                              <i
                                className={
                                  passwordVisible
                                    ? "ri-eye-off-fill align-middle"
                                    : "ri-eye-fill align-middle"
                                }
                              ></i>
                            </button>
                            {formik.touched.password &&
                            formik.errors.password ? (
                              <div className="text-danger">
                                {formik.errors.password}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* Password Validation Box */}
                        {showValidationBox && (
                        <div className="password-validation-box mt-2">
                          <p>Password must contain:</p>
                          <ul className="list-unstyled">
                            <li>
                              <i
                                className={
                                  validation.length
                                    ? "ri-check-line text-success"
                                    : "ri-close-line text-danger"
                                }
                              ></i>
                              <span className="ms-2">
                                At least 8 characters
                              </span>
                            </li>
                            <li>
                              <i
                                className={
                                  validation.uppercase
                                    ? "ri-check-line text-success"
                                    : "ri-close-line text-danger"
                                }
                              ></i>
                              <span className="ms-2">
                                At least one uppercase letter
                              </span>
                            </li>
                            <li>
                              <i
                                className={
                                  validation.lowercase
                                    ? "ri-check-line text-success"
                                    : "ri-close-line text-danger"
                                }
                              ></i>
                              <span className="ms-2">
                                At least one lowercase letter
                              </span>
                            </li>
                            <li>
                              <i
                                className={
                                  validation.digit
                                    ? "ri-check-line text-success"
                                    : "ri-close-line text-danger"
                                }
                              ></i>
                              <span className="ms-2">At least one digit</span>
                            </li>
                            <li>
                              <i
                                className={
                                  validation.specialChar
                                    ? "ri-check-line text-success"
                                    : "ri-close-line text-danger"
                                }
                              ></i>
                              <span className="ms-2">
                                At least one special character (e.g., !@#$%^&*)
                              </span>
                            </li>
                          </ul>
                        </div>
                        )}

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Photo/Avatar Upload{" "}
                          </label>
                          <input
                            type="file"
                            className="form-control"
                            id="photo"
                            name="photo"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.photo}
                          />
                          {formik.touched.photo && formik.errors.photo ? (
                            <div className="text-danger">
                              {formik.errors.photo}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Assign Offices{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            id="assignedOffices"
                            name="assignedOffices"
                            options={officesOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            value={officesOptions.filter((option) =>
                              formik.values.assignedOffices.includes(
                                option.value
                              )
                            )}
                            onChange={(selectedOptions) =>
                              formik.setFieldValue(
                                "assignedOffices",
                                selectedOptions
                                  ? selectedOptions.map(
                                      (option) => option.value
                                    )
                                  : []
                              )
                            }
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.assignedOffices &&
                          formik.errors.assignedOffices ? (
                            <div className="text-danger">
                              {/* {formik.errors.assignedOffices} */}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Permissions/Access Level{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Select
                            id="permissions"
                            name="permissions"
                            options={permissionsOptions} // The options array
                            isMulti // Enable multiple selection
                            closeMenuOnSelect={false} // Keep the menu open while selecting multiple
                            value={permissionsOptions.filter((option) =>
                              formik.values.permissions.includes(option.value)
                            )} // Map the selected values to the select component
                            onChange={(permissionsOptions) => {
                              formik.setFieldValue(
                                "permissions",
                                permissionsOptions
                                  ? permissionsOptions.map(
                                      (option) => option.value
                                    )
                                  : []
                              );
                            }}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.permissions &&
                          formik.errors.permissions ? (
                            <div className="text-danger">
                              {formik.errors.permissions}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            required
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                          >
                            <option value="">- Select -</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select status
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorAddTeammember;
