import React, { useState, useEffect, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom"; // Import useParams from React Router
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: string;
}

interface BrokerageFirm {
  id: string;
  companyName: string;
  firstName: string;
  lastName: string;
  phone1: string;
  phone2: string;
  email: string;
  status: string;
}

type Teammember = {
  id: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  phone1: string;
  phone2: string;
  email: string;
  offices: Office[];
  status: string;
};

type officeAddress = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone: string;
};
type Office = {
  id: string;
  officeName: string;
  officeType: string;
  officeAddress: officeAddress;
};

interface Agent {
  id: string;
  companyID: string;
  companyName: string;
  licenseType: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  email: string;
  phone1: string;
  firmDetails: FirmInfo;
  modulePermissions: string[];
  created_at: string;
}
type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  // product_id: string;
  name: string;
  description: string;
  sku: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  qty: number;
  threshold_qty: number;
  package_dimensions: Dimensions;
  notes: string;
  image: Image | null;
  status: string;
  linked_mode: string;
  linked_installer: {
    id: string;
    name: string;
  };
  variants: Variant[];
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      location_id: string;
      location_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

type TaxGroup = {
  id: string;
  name: string;
  status: string;
};

type Image = {
  url: string;
};

type OfficeInventoryObj = {
  product_id: string;
  office: Office;
  product: Product;
  created_by: {
    id: string;
    name: string;
  };
};

type AgentProduct = {
  _id: string;
  agent_id: string;
  agent_name: string;
  product: Product;
};

const SignageBrokeragePage = () => {
  const { firmId } = useParams<{ firmId: string }>();
  const token = localStorage.getItem("sessionToken");
  const [brokerageFirm, setBrokerageFirm] = useState<BrokerageFirm | null>(
    null
  ); // To store the brokerageFirm data
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [teammembers, setTeammembers] = useState<Teammember[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [officeProducts, setOfficeProducts] = useState<OfficeInventoryObj[]>(
    []
  );
  const [agentproducts, setAgentProducts] = useState<AgentProduct[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBrokerageFirmData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${firmId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch brokerageFirm data");
        }
        const data = await response.json();
        // setBrokerageFirm(data);
        if (data && data.firmDetails && data.firmDetails.address) {
          setBrokerageFirm({
            id: data.id || "",
            companyName: data.firmDetails.companyName || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            email: data.email || "",
            phone1: data.phone1 || "",
            phone2: data.phone2 || "",
            status: data.status || "Active",
          });

          // setUserModules(data.modulePermissions);
          // console.log(userModules);
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        setError("Error fetching brokerageFirm data");
        console.error("Error fetching brokerageFirm data:", error);
      }
    };

    if (firmId) {
      fetchBrokerageFirmData();
    }
  }, [firmId, token]);

  useEffect(() => {
    const fetchTeammembers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users-search?userType=team_member&created_by.id=${firmId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setTeammembers(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTeammembers();
  }, [token]);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/offices-search?created_by.id=${firmId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setOffices(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOffices();
  }, [token]);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users-search?userType=agent&created_by.id=${firmId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch agents");
        }

        const data: Agent[] = await response.json();
        console.log(data);
        setAgents(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, [token]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/brokerage-offices/${firmId}/inventory`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        // console.log("Fetched data:", data);
        // setTempObject(data);
        // Ensure the response contains the expected structure
        if (data && Array.isArray(data)) {
          // setAgentProducts(data.products);
          setOfficeProducts(data);
        } else {
          console.error("Unexpected API response structure:", data);
          // setAgentProducts([]);
          // setProducts(data.products);
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/agent-inventories`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        // console.log("Fetched data:", data);
        // setTempObject(data);
        // Ensure the response contains the expected structure
        if (data && Array.isArray(data)) {
          // setAgentProducts(data.products);
          setAgentProducts(data);
        } else {
          console.error("Unexpected API response structure:", data);
          // setAgentProducts([]);
          // setProducts(data.products);
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token]);

  const handleActivate = async (licenseId: string) => {
    setDeleting(licenseId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${licenseId}/activate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response);
      if (!response.ok) {
        throw new Error("Failed to activate the license.");
      }

      toast.success("Agent activated successfully!");
      window.location.reload();
      // navigate("/signage/brokerage/agents", {
      //   state: { successMessage: "Agent activated successfully!" },
      // });
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while activating."
      );
    }
  };

  const handleDeactivate = async (licenseId: string) => {
    setDeleting(licenseId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${licenseId}/deactivate`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Response:", response);
      if (!response.ok) {
        throw new Error("Failed to deactivate the license.");
      }

      toast.success("License deactivated successfully!");
      window.location.reload();
      // navigate("/signage/brokerage/agents", {
      //   state: { successMessage: "Agent deactivated successfully!" },
      // });
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deactivating."
      );
    }
  };

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  // if (!brokerageFirm) return <div>Loading BrokerageFirm Data...</div>;

  // Format the date to be more human-readable
  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  useEffect(() => {
    if(teammembers!=null && teammembers.length > 0) {
      const table = $("#transactions1").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [teammembers]);

  useEffect(() => {
    if(offices!=null && offices.length > 0) {
      const table = $("#transactions2").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [offices]);

  useEffect(() => {
    if(officeProducts!=null && officeProducts.length > 0) {
      const table = $("#transactions3").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [officeProducts]);

  useEffect(() => {
    if(agents!=null && agents.length > 0) {
      const table = $("#transactions4").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [agents]);

  useEffect(() => {
    if(agentproducts!=null && agentproducts.length > 0) {
      const table = $("#transactions5").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [agentproducts]);

  const handleDeleteOffice = async (officeId:string) => {
    setDeleting(officeId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/offices/${officeId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the Office.");
      }

      toast.success("Office deleted successfully!");
      // Update the state by removing the deleted license
      setOffices((prev) => prev.filter((office) => office.id !== officeId));
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the Office.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  const handleDeleteOfficeProduct = async (officeId:string, productId: string) => {
    setDeleting(productId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/office/${officeId}/inventory/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the Product.");
      }

      toast.success("Product deleted successfully!");
      // Update the state by removing the deleted license
      setAgents((prev) => prev.filter((product) => product.id !== productId));
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the Product.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  const handleDeleteAgent = async (agentId:string) => {
    setDeleting(agentId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${agentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the agent.");
      }

      toast.success("Agent deleted successfully!");
      // Update the state by removing the deleted license
      setAgents((prev) => prev.filter((agent) => agent.id !== agentId));
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the agent.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  const handleDeleteAgentProduct = async (agentId:string, productId: string) => {
    setDeleting(productId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the product.");
      }

      toast.success("Product deleted successfully!");
      // Update the state by removing the deleted license
      setAgentProducts((prev) => prev.filter((product) => product._id !== productId));
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the product.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Brokerage Firm Details </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table className="table">
                      <tbody>
                        {/* <tr>
                        <th colSpan={4}>BrokerageFirm Information </th>
                      </tr> */}
                        <tr>
                          <th>Company Name: </th>
                          <td colSpan={3}>{brokerageFirm?.companyName}</td>
                        </tr>
                        <tr>
                          <th>First Name: </th>
                          <td>{brokerageFirm?.firstName}</td>
                          <th>Last Name </th>
                          <td>{brokerageFirm?.lastName}</td>
                        </tr>
                        <tr>
                          <th>Phone Number </th>
                          <td>{brokerageFirm?.phone1}</td>
                          <th>Secondary Phone Number </th>
                          <td>{brokerageFirm?.phone2}</td>
                        </tr>
                        <tr>
                          <th>Email </th>
                          <td>{brokerageFirm?.email}</td>
                          <th>Status </th>
                          <td>{brokerageFirm?.status}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div className="card">
                <div className="card-body">
                  <ul
                    className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#teammembers"
                        role="tab"
                      >
                        Teammembers
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#offices"
                        role="tab"
                      >
                        Offices
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#officeInventory"
                        role="tab"
                      >
                        Office Inventory
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#agents"
                        role="tab"
                      >
                        Agents
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#agentInventory"
                        role="tab"
                      >
                        Agent Inventory
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content text-muted">
                    <div
                      className="tab-pane active"
                      id="teammembers"
                      role="tabpanel"
                    >
                      <div className="dflex">
                        <div className="row">
                          <div className="text-end">
                            <Link
                              to={`/signage/brokerage/addTeammember/${firmId}`}
                              className="btn btn-primary"
                            >
                              Add Team Member
                            </Link>
                          </div>
                        </div>
                        <br />
                        {teammembers === null ? (
                          <div>No team members found.</div>
                        ) : (
                          <div className="table-container">
                            <table
                              className="table table-bordered table-striped align-middle"
                              style={{ width: "100%" }}
                              id="transactions1"
                            >
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Role</th>
                                  <th>First Name</th>
                                  <th>Last Name</th>
                                  <th>Phone </th>
                                  <th>Email</th>
                                  <th>Status</th>
                                  <th>Office Linked</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {Array.isArray(teammembers) &&
                                  teammembers.map((teammember, index) => (
                                    <tr key={teammember.id}>
                                      <td>{index + 1}</td>
                                      <td>{teammember.jobTitle}</td>
                                      <td>{teammember.firstName}</td>
                                      <td>{teammember.lastName}</td>
                                      <td>{teammember.phone1}</td>
                                      <td>{teammember.email}</td>
                                      <td>{teammember.status}</td>
                                      <td>
                                        {teammember.offices !== null &&
                                          teammember.offices.map((office) => (
                                            <Link
                                              to={`/signage/brokerage/view-office/${office.id}`}
                                              key={office.id}
                                              className="btn waves-effect waves-light"
                                            >
                                              {office.officeName}
                                            </Link>
                                          ))}
                                      </td>
                                      <td>
                                        {/* <Link
                                          to={`/signage/brokerage/view-teammember/${teammember.id}`}
                                          className="btn btn-secondary"
                                        >
                                          <i className="ri-eye-fill align-bottom me-2"></i>
                                        </Link> */}
                                        <Link
                                          to={`/signage/brokerage/editTeammember/${firmId}/${teammember.id}`}
                                          className="btn btn-warning"
                                        >
                                          <i className="ri-pencil-fill align-bottom me-2"></i>
                                        </Link>
                                        {/* <button
                          onClick={() =>
                            window.confirm("Are you sure you want to delete this team member?") &&
                            handleDelete(teammember.id)
                          }
                          className="btn btn-danger"
                          disabled={deleting === teammember.id}
                        >
                          <i className="ri-delete-bin-fill align-bottom me-2"></i>
                        </button> */}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="tab-pane" id="offices" role="tabpanel">
                      <div className="dflex">
                        <div className="text-end">
                          <Link
                            to={`/signage/brokerage/addOffice/${firmId}`}
                            className="btn btn-primary float-right"
                          >
                            Add Office
                          </Link>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-md-12 col-lg-12 col-xl-12">
                            <div className="card mt-4 card-bg-fill">
                              <div className="card-body p-4">
                                <div className="table-container">
                                  <table
                                    id="transactions2"
                                    className="table table-bordered dt-responsive nowrap table-striped align-middle"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          scope="col"
                                          style={{ width: "10px" }}
                                        >
                                          <div className="form-check">
                                            <input
                                              className="form-check-input fs-15"
                                              type="checkbox"
                                              id="checkAll"
                                              value="option"
                                            />
                                          </div>
                                        </th>
                                        <th>S.No.</th>
                                        <th>Office Name</th>
                                        <th>Phone</th>
                                        <th>Street Address</th>
                                        <th>Address 2</th>
                                        <th>County</th>
                                        <th>State</th>
                                        <th>Country</th>
                                        <th>Zip Code</th>
                                        <th>Team member </th>
                                        <th>Actions</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {offices != null &&
                                        offices.map((office, index) => (
                                          <tr key={office.id}>
                                            <th scope="row">
                                              <div className="form-check">
                                                <input
                                                  className="form-check-input fs-15"
                                                  type="checkbox"
                                                  name="checkAll"
                                                  value="option1"
                                                />
                                              </div>
                                            </th>
                                            <td>{index + 1}</td>
                                            <td>{office.officeName}</td>
                                            {/* <td>{office.office_id}</td> */}
                                            {/* <td>{office.officeType}</td> */}
                                            <td>
                                              {office.officeAddress.phone}
                                            </td>
                                            <td>
                                              {
                                                office.officeAddress
                                                  .addressLine1
                                              }
                                            </td>
                                            <td>
                                              {
                                                office.officeAddress
                                                  .addressLine2
                                              }
                                            </td>
                                            <td>{office.officeAddress.city}</td>
                                            <td>
                                              {office.officeAddress.state}
                                            </td>
                                            <td>
                                              {office.officeAddress.country}
                                            </td>
                                            <td>{office.officeAddress.zip}</td>
                                            <td></td>
                                            <td>
                                              {/* <Link
                                                to={`/signage/brokerage/view-office/${office.id}`}
                                                className="btn btn-secondary waves-effect waves-light"
                                              >
                                                <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                                              </Link> */}

                                              <Link
                                                to={`/signage/brokerage/editOffice/${firmId}/${office.id}`}
                                                className="btn btn-warning waves-effect waves-light"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                              </Link>

                                              <button
                                            onClick={() =>
                                              window.confirm(
                                                "Are you sure you want to delete this office?"
                                              ) && handleDeleteOffice(office.id)
                                            }
                                            className="btn btn-danger waves-effect waves-light"
                                          >
                                            <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                          </button>
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="officeInventory"
                      role="tabpanel"
                    >
                      <div className="dflex">
                        <div className="text-end">
                          <Link
                            to={`/signage/brokerage/addOfficeProduct/${firmId}`}
                            className="btn btn-primary float-right"
                          >
                            Add Office Product
                          </Link>
                        </div>
                        <table
                          id="transactions3"
                          className="table table-bordered dt-responsive nowrap table-striped align-middle"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              {/* <th>
                                                    <input type="checkbox" />
                                                  </th> */}
                              <th>S.No.</th>
                              <th>Office</th>
                              {/* <th>Image</th> */}
                              <th>Product Name</th>
                              <th>Category</th>
                              <th>Qty</th>
                              <th>Status</th>
                              {/* <th>Installer Status</th>
                              <th>Condition</th>
                              <th>Dimensions</th>
                              <th>Weight</th> */}
                              <th>Linked Installer</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {officeProducts != null &&
                              officeProducts.map((officeproduct, index) => (
                                <tr key={officeproduct.product_id}>
                                  {/* <td><input type="checkbox" /></td> */}
                                  <td>{index + 1}</td>
                                  <td>{officeproduct.office.officeName} </td>
                                  {/* <td className="text-center">
                                    {officeproduct.product.image?.url ? (
                                      <img
                                        src={`https://dev.reelty.app/${officeproduct.product.image.url}`}
                                        alt="Product"
                                        height={50}
                                      />
                                    ) : (
                                      "No Image"
                                    )}
                                  </td> */}
                                  <td>{officeproduct.product.name}</td>
                                  <td>{officeproduct.product.category}</td>
                                  <td>
                                    {officeproduct?.product.variants?.[0]
                                      ?.inventory?.[0]?.on_hand ?? 0}
                                  </td>
                                  <td>{officeproduct.product.status}</td>
                                  {/* <td>{officeproduct.product.linked_mode}</td>
                                  <td>
                                    {officeproduct.product.item_condition}
                                  </td>
                                  <td>
                                    {
                                      officeproduct.product.package_dimensions
                                        ?.length
                                    }{" "}
                                    x{" "}
                                    {
                                      officeproduct.product.package_dimensions
                                        ?.height
                                    }{" "}
                                    x{" "}
                                    {
                                      officeproduct.product.package_dimensions
                                        ?.width
                                    }
                                  </td>
                                  <td>
                                    {
                                      officeproduct.product.package_dimensions
                                        ?.weight
                                    }
                                  </td> */}
                                  <td>
                                    {officeproduct.product.linked_installer
                                      ?.name || "None"}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/signage/brokerage/editOfficeProduct/${officeproduct.office.id}/${officeproduct.product_id}`}
                                      className="btn btn-warning me-2"
                                    >
                                      Edit
                                    </Link>
                                    <button
                                                          onClick={() =>
                                                            window.confirm("Delete this product?") &&
                                                            handleDeleteOfficeProduct(officeproduct.office.id, officeproduct.product_id)
                                                          }
                                                          className="btn btn-danger"
                                                        >
                                                          Delete
                                                        </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="tab-pane" id="agents" role="tabpanel">
                      <div className="dflex">
                        <div className="text-end">
                          <Link
                            to={`/signage/brokerage/addAgent/${firmId}`}
                            className="btn btn-primary float-right"
                          >
                            Add Agent
                          </Link>
                        </div>

                        <table
                          id="transactions4"
                          className="table table-bordered dt-responsive nowrap table-striped align-middle"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              <th>Agent ID</th>
                              <th>First Name</th>
                              <th>Last Name</th>
                              <th>Email</th>
                              <th>Phone Number</th>
                              <th>Status</th>
                              <th>Action</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {agents &&
                              agents
                                // .filter((agent) => agent.userType === "agent") // Filter out 'super_admin' suppliers
                                .map((agent) => (
                                  <tr key={agent.id}>
                                    <td>{agent.id}</td>
                                    <td>{agent.firstName}</td>
                                    <td>{agent.lastName}</td>
                                    <td>{agent.email}</td>
                                    <td>{agent.phone1}</td>
                                    <td>{agent.status}</td>
                                    <td>
                                      {agent.status === "active" ||
                                      agent.status === "Active" ? (
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            window.confirm(
                                              "Are you sure you want to deactivate this agent?"
                                            ) && handleDeactivate(agent.id)
                                          }
                                          className="btn btn-danger waves-effect waves-light"
                                        >
                                          Deactivate
                                        </Link>
                                      ) : (
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            window.confirm(
                                              "Are you sure you want to activate this agent?"
                                            ) && handleActivate(agent.id)
                                          }
                                          className="btn btn-success waves-effect waves-light"
                                        >
                                          Activate
                                        </Link>
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        to={`/signage/brokerage/view-agent/${agent.id}`}
                                        className="btn btn-secondary waves-effect waves-light"
                                      >
                                        <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                                      </Link>

                                      <Link
                                        to={`/signage/brokerage/editAgent/${firmId}/${agent.id}`}
                                        className="btn btn-warning waves-effect waves-light"
                                      >
                                        <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                      </Link>

                                      <Link
                                             to="#"
                                             onClick={() =>
                                               window.confirm(
                                                 "Are you sure you want to delete this agent?"
                                               ) && handleDeleteAgent(agent.id)
                                             }
                                             className="btn btn-danger waves-effect waves-light"
                                           >
                                             <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                           </Link>
                                    </td>
                                  </tr>
                                ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      className="tab-pane"
                      id="agentInventory"
                      role="tabpanel"
                    >
                      <div className="dflex">
                        <div className="text-end">
                          <Link
                            to={`/signage/brokerage/addAgentProduct/${firmId}`}
                            className="btn btn-primary float-right"
                          >
                            Add Agent Product
                          </Link>
                        </div>
                        <table
                          id="transactions5"
                          className="table table-bordered dt-responsive nowrap table-striped align-middle"
                          style={{ width: "100%" }}
                        >
                          <thead>
                            <tr>
                              {/* <th>
                                                    <input type="checkbox" />
                                                  </th> */}
                              <th>S.No.</th>
                              <th>Image</th>
                              <th>Product Name</th>
                              <th>Category</th>
                              <th>Qty</th>
                              <th>Status</th>
                              <th>Installer Status</th>
                              <th>Condition</th>
                              <th>Dimensions</th>
                              <th>Weight</th>
                              <th>Linked Installer</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {agentproducts != null &&
                              agentproducts.map((agentproduct, index) => (
                                <tr key={agentproduct._id}>
                                  <td>{index + 1}</td>
                                  <th>{agentproduct.agent_name}</th>
                                  {/* <td className="text-center">
                                                          {agentproduct.product.image?.url ? (
                                                            <img
                                                              src={`https://dev.reelty.app/${agentproduct.product.image.url}`}
                                                              alt="Product"
                                                              height={50}
                                                            />
                                                          ) : (
                                                            "No Image"
                                                          )}
                                                        </td> */}
                                  <td>{agentproduct?.product?.name}</td>
                                  <td>{agentproduct?.product?.category}</td>
                                  <td>
                                    {agentproduct?.product.variants?.[0]
                                      ?.inventory?.[0]?.on_hand ?? 0}
                                  </td>
                                  <td>{agentproduct?.product?.status}</td>
                                  <td>{agentproduct.product?.linked_mode}</td>
                                  <td>{agentproduct.product.item_condition}</td>
                                  <td>
                                    {
                                      agentproduct.product.package_dimensions
                                        ?.length
                                    }{" "}
                                    x{" "}
                                    {
                                      agentproduct.product.package_dimensions
                                        ?.height
                                    }{" "}
                                    x{" "}
                                    {
                                      agentproduct.product.package_dimensions
                                        ?.width
                                    }
                                  </td>
                                  <td>
                                    {
                                      agentproduct.product.package_dimensions
                                        ?.weight
                                    }
                                  </td>
                                  <td>
                                    {agentproduct.product.linked_installer
                                      ?.name || "None"}
                                  </td>
                                  <td>
                                    <Link
                                      to={`/signage/brokerage/editAgentProduct/${agentproduct.agent_id}/${agentproduct._id}`}
                                      className="btn btn-warning me-2"
                                    >
                                      Edit
                                    </Link>
                                    <button onClick={() =>
                                                              window.confirm("Delete this product?") &&
                                                              handleDeleteAgentProduct(agentproduct.agent_id, agentproduct._id)
                                                            }
                                                            className="btn btn-danger"
                                                          >
                                                            Delete
                                                          </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageBrokeragePage;
