import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

type FileWithPreview = {
  file: File;
  preview: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Office = {
  id: number;
  officeName: string;
  officeType: string; 
  status: boolean;
};

interface Installer {
  id: string;
  firstName: string;
  lastName: string;
  statuss: string;
  licenseType: string;
  firmDetails: FirmDetails;
}

interface FirmDetails {
  companyID: string;
  companyName: string;
}

const TeammemberAddProduct = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [offices, setOffices] = useState<Office[]>([]);
  const [installers, setInstallers] = useState<Installer[]>([]);

  useEffect(() => {
      const fetchOffices = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${userId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
  
          if (!response.ok) {
            throw new Error("Failed to fetch license data");
          }
  
          const data = await response.json();
          console.log("Fetched offices Data:", data.offices);
  
          if (data && Array.isArray(data.offices)) {
            setOffices(data.offices);
          } else {
            console.error("Invalid offices data:", data);
          }
          
        } catch (error) {
          console.error("Error fetching offices data:", error);
          setError("Failed to fetch offices data.");
        } finally {
          setLoading(false);
        }
      };
  
      if (!offices) {
        fetchOffices();
      }
    }, [userId, token]);

    useEffect(() => {
        const fetchBrands = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/brands`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            });
            const data = await response.json();
            setBrands(data);
          } catch (err) {
            setError(
              err instanceof Error ? err.message : "An unknown error occurred"
            );
          } finally {
            setLoading(false);
          }
        };
    
        fetchBrands();
      }, [token]);
    
      useEffect(() => {
        const fetchCategories = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/categories`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
            const data = await response.json();
            setCategories(data);
          } catch (err) {
            setError(
              err instanceof Error ? err.message : "An unknown error occurred"
            );
          } finally {
            setLoading(false);
          }
        };
    
        fetchCategories();
      }, [token]);
    
      useEffect(() => {
        const fetchInstallers = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/users?userType=signage_firm`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
    
            if (!response.ok) {
              throw new Error("Failed to fetch installers data");
            }
    
            const data = await response.json();
            console.log("Fetched installers Data:", data);
    
            if (Array.isArray(data)) {
              setInstallers(data);
            } else {
              console.error("Unexpected data format:", data);
            }
          } catch (error) {
            console.error("Error fetching installers data:", error);
            setError("Failed to fetch installers data.");
          } finally {
            setLoading(false);
          }
        };
    
        // Only fetch if the installers array is empty
        if (installers.length === 0) {
          fetchInstallers();
        }
      }, [token]); 

  useEffect(() => {
    return () => {
      files.forEach(({ preview }) => URL.revokeObjectURL(preview));
    };
  }, [files]);

  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  // const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      officeId: "",
      name: "",
      sku: "",
      description: "",
      brand: "",
      category: "",
      item_condition: "",
      purchase_price: "",
      retail_price: "",
      length: "",
      height: "",
      width: "",
      weight: "",
      qty: "",
      threshold_qty: "",
      installerId: "",
      notes: "",
      image: null,
      statuss: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      const groupStatus = values.statuss == "Active" ? true : false;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tax-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              status: groupStatus,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        // toast.success("GeoLocation added successfully!");
        // console.log("Form submission successful:", result);
        navigate("/signage/taxGroups", {
          state: { successMessage: "Tax group added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add GeoLocation.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Add Product </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-1 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                          <div className="col-md-3 mb-3">
                              <label className="form-label">Select Office</label>
                              <select
                                className="form-control"
                                id="officeId"
                                name="officeId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.officeId}
                              >
                                <option value="">- Select -</option>
                                {offices !== null &&
                                  offices.map((office) => (
                                    <option value={office.id}>
                                      {office.officeName}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.officeId && formik.errors.officeId ? (
                                <div className="text-danger">
                                  {formik.errors.officeId}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-9 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                               SKU
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="sku"
                                name="sku"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.sku}
                              />
                              {formik.touched.sku &&
                              formik.errors.sku ? (
                                <div className="text-danger">
                                  {formik.errors.sku}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">Brand</label>
                              <select
                                className="form-control"
                                id="brand"
                                name="brand"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.brand}
                              >
                                <option value="">- Select -</option>
                                {brands !== null &&
                                  brands.map((brand) => (
                                    <option value={brand.name}>
                                      {brand.name}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.brand && formik.errors.brand ? (
                                <div className="text-danger">
                                  {formik.errors.brand}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">Category</label>
                              <select
                                className="form-control"
                                id="category"
                                name="category"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category}
                              >
                                <option value="">- Select -</option>
                                {categories !== null &&
                                  categories.map((category) => (
                                    <option value={category.name}>
                                      {category.name}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.category &&
                              formik.errors.category ? (
                                <div className="text-danger">
                                  {formik.errors.category}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Item Condition
                              </label>
                              <select
                                className="form-control"
                                id="item_condition"
                                name="item_condition"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.item_condition}
                              >
                                <option value="">- Select -</option>
                                <option value="new">New</option>
                                <option value="used">Used</option>
                                <option value="damaged">Damaged</option>
                              </select>
                              {formik.touched.item_condition &&
                              formik.errors.item_condition ? (
                                <div className="text-danger">
                                  {formik.errors.item_condition}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Purchase Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="purchase_price"
                                name="purchase_price"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.purchase_price}
                              />
                              {formik.touched.purchase_price &&
                              formik.errors.purchase_price ? (
                                <div className="text-danger">
                                  {formik.errors.purchase_price}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Retail Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="retail_price"
                                name="retail_price"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.retail_price}
                              />
                              {formik.touched.retail_price &&
                              formik.errors.retail_price ? (
                                <div className="text-danger">
                                  {formik.errors.retail_price}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Quantity{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="qty"
                                name="qty"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.qty}
                              />
                              {formik.touched.qty &&
                              formik.errors.qty ? (
                                <div className="text-danger">
                                  {formik.errors.qty}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Threshold Quantity{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="threshold_qty"
                                name="threshold_qty"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.threshold_qty}
                              />
                              {formik.touched.threshold_qty &&
                              formik.errors.threshold_qty ? (
                                <div className="text-danger">
                                  {formik.errors.threshold_qty}
                                </div>
                              ) : null}
                            </div>
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                              ></textarea>
                              {formik.touched.description &&
                              formik.errors.description ? (
                                <div className="text-danger">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Dimensions/Measurements (Inches)
                              </label>
                              <div className="row">
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="length"
                                    name="length"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.length}
                                    placeholder="Length"
                                  />
                                  {formik.touched.length &&
                                  formik.errors.length ? (
                                    <div className="text-danger">
                                      {formik.errors.length}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="height"
                                    name="height"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.height}
                                    placeholder="Height"
                                  />
                                  {formik.touched.height &&
                                  formik.errors.height ? (
                                    <div className="text-danger">
                                      {formik.errors.height}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="width"
                                    name="width"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.width}
                                    placeholder="Width"
                                  />
                                  {formik.touched.width &&
                                  formik.errors.width ? (
                                    <div className="text-danger">
                                      {formik.errors.width}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Weight(in gms)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="weight"
                                name="weight"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weight}
                              />
                              {formik.touched.weight && formik.errors.weight ? (
                                <div className="text-danger">
                                  {formik.errors.weight}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Installer</label>
                              <select
                                name="installerId"
                                id="installerId"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.installerId} // Must match formik's state
                              >
                                <option value="">-Select-</option>
                                {installers &&
                                  installers.map((installer) => (
                                    <option
                                      key={installer?.firmDetails.companyID}
                                      value={installer?.firmDetails.companyID}
                                    >
                                      {installer?.firmDetails.companyName}{" "}
                                      {installer.lastName}
                                    </option>
                                  ))}
                              </select>

                              {formik.touched.installerId &&
                              formik.errors.installerId ? (
                                <div className="text-danger">
                                  {formik.errors.installerId}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="text-danger">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Notes for the Installer{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              ></textarea>
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            
                          </div>
                        </div>
                        
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Save Inventory Item
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeammemberAddProduct;
