import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type Attribute = {
    id: string;
    name: string;
    values: string[];
    description: string;
    status: string;
};

const SignageEditAttribute = () => {
  const { attributeId } = useParams<{ attributeId: string }>();
  const [attribute, setAttribute] = useState<Attribute | null>(null);
  const [loading, setLoading] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  // Fetch attribute data
  useEffect(() => {
    const fetchAttribute = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/product-attributes/${attributeId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch attribute data");
        }
        const data = await response.json();
        setAttribute(data);
      } catch (err) {
        console.error("Error fetching attribute data:", err);
        toast.error("Error fetching attribute data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (attributeId) fetchAttribute();
  }, [attributeId, token]);

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
        name: "",
        parameters: "",
        description: "",
        statuss: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);      

      try {        
        // Update attribute data
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/product-attributes/${attributeId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                name: values.name,
                values: values.parameters.split(","),
                description: values.description,
                status: values.statuss === "active",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update attribute");
        }

        toast.success("Attribute updated successfully!");
        navigate("/signage/attributes", {
          state: { successMessage: "Attribute updated successfully!" },
        });
      } catch (err) {
        console.error(err);
        toast.error("Failed to update attribute.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  // Set Formik values when attribute is fetched
  useEffect(() => {
    if (attribute) {
      formik.setValues({
        name: attribute.name,
        parameters: attribute.values.join(","),
        description: attribute.description,
        statuss: (attribute.status)?"active":"inactive",
      });
    }
  }, [attribute]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Attribute</h2>

          <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            {/* Name Field */}
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="invalid-feedback">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Values <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="parameters"
                                name="parameters"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.parameters}
                              />
                              {formik.touched.parameters && formik.errors.parameters ? (
                                <div className="invalid-feedback">
                                  {formik.errors.parameters}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Description 
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                              />
                              {formik.touched.description && formik.errors.description ? (
                                <div className="invalid-feedback">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>
                            {/* Status Field */}
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                               className="form-control"
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="invalid-feedback">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                       
                      </div>

                      {/* Submit Button */}
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
        </div>
      </div>
    </div>
  );
};

export default SignageEditAttribute;
