// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.combinations{
    width: 100%;
    height: 600px;
    border: 1px solid #cccccc;
}
.attributes{
    width: 100%;
    height: 600px;
    border: 1px solid #cccccc;
}
.attr-head {
    background-color: #405189;
    color: #ffffff;
    height: 30px;
    text-align: center;
    font-weight: 600;
    line-height: 30px;
}
.table-bordered>:not(caption)>*>*{
    border-width: 1px;
}
.w100{
    width: 100px !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/teammember/TeammemberAddVariableProduct.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;AACA;IACI,uBAAuB;AAC3B","sourcesContent":[".combinations{\n    width: 100%;\n    height: 600px;\n    border: 1px solid #cccccc;\n}\n.attributes{\n    width: 100%;\n    height: 600px;\n    border: 1px solid #cccccc;\n}\n.attr-head {\n    background-color: #405189;\n    color: #ffffff;\n    height: 30px;\n    text-align: center;\n    font-weight: 600;\n    line-height: 30px;\n}\n.table-bordered>:not(caption)>*>*{\n    border-width: 1px;\n}\n.w100{\n    width: 100px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
