import { useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "../../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  product_id: string;
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  package_dimensions: Dimensions;
  variants: Variant[];
  image: Image | null;
  status: string;
  is_rotational: boolean;
  linked_mode: string;
  linked_installer: LinkedInstaller | null;
  created_by: {
    id: string;
    name: string;
  };
};

type LinkedInstaller = {
  id: string;
  name: string;
  warehouse_info: {
    id: string | null;
    label: string | null;
    bin_location: string | null;
  };
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      location_id: string;
      location_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

type Image = {
  url: string;
};

interface Installer {
  supplier_id: string;
  name: string;
  email: string;
}

type AgentProduct = {
  product_id: string;
  agent_id: string;
  product: Product;
  created_by: {
    id: string;
    name: string;
  };
};

const SignageRotationalProducts = () => {
  const [agentproducts, setAgentProducts] = useState<AgentProduct[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const [installers, setInstallers] = useState<Installer[]>([]);

  // useEffect(() => {
  //   const fetchInstallers = async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/agent/suppliers`, {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //       });

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch installers data");
  //       }

  //       const data = await response.json();
  //       console.log("Fetched Installers:", data);

  //       if (Array.isArray(data)) {
  //         setInstallers(data);
  //       } else {
  //         console.error("Unexpected installers data format:", data);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching installers data:", error);
  //     }
  //   };

  //   fetchInstallers();
  // }, [token]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          // `${process.env.REACT_APP_API_URL}/signage/${userId}/rotational/products`,
          `${process.env.REACT_APP_API_URL}/rotational-products`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        console.log(data);
        setAgentProducts(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token]);

  // if (products?.length === 0) {
  //   return <div>Loading...</div>;
  // }

  useEffect(() => {
    if (agentproducts != null && agentproducts.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [agentproducts]);

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  // const rotationalProducts = products.filter((product) => product.is_rotational);

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Rotational Products </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Status</th>
                          <th>Product Name</th>
                          <th>Product Category</th>
                          <th>On Hand</th>
                          <th>Reserved</th>
                          <th>Available</th>
                          <th>Condition</th>
                          <th>Warehouse</th>
                          <th>Bin Location</th>
                          <th>Linked Agent</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agentproducts != null &&
                          agentproducts.map((agentproduct, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{agentproduct.product.status}</td>
                              <td>{agentproduct.product.name}</td>
                              <td>{agentproduct.product.category}</td>
                              <td>
                                {agentproduct?.product.variants?.[0]?.inventory?.[0]
                                  ?.on_hand ?? 0}
                              </td>
                              <td>
                                {agentproduct?.product.variants?.[0]?.inventory?.[0]
                                  ?.available ?? 0}
                              </td>
                              <td>
                                {agentproduct?.product.variants?.[0]?.inventory?.[0]
                                  ?.reserved ?? 0}
                              </td>

                              <td>{agentproduct.product.item_condition}</td>
                              <td>
                                {
                                  agentproduct?.product.linked_installer?.warehouse_info
                                    ?.label
                                }
                              </td>
                              <td>
                                {
                                  agentproduct?.product.linked_installer?.warehouse_info
                                    ?.bin_location
                                }
                              </td>
                              <td>{agentproduct.created_by.name}</td>
                              <td>
                                <Link
                                  to={`/signage/editRotationalProduct/${agentproduct.created_by.id}/${agentproduct.product_id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageRotationalProducts;
