import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "./RealtorSuppliers.css";

interface FirmInfo {
  companyID: string;
  companyName: string;
  companyWebsite: string;
  address: Address;
  specialities: string;
  regionsServed: string;
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Supplier {
  id: string;
  companyID: string;
  companyName: string;
  licenseType: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  jobTitle: string;
  email: string;
  phone1: string;
  firmDetails: FirmInfo;
  modulePermissions: string[];
  created_at: string;
}

type Product = {
  product_id: string;
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  package_dimensions: Dimensions;
  // notes: string;
  image: Image | null;
  status: string;
  variants: Variant[];
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Image = {
  url: string;
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      warehouse_id: string;
      warehouse_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

type SupplierProduct = {
  product_id: string;
  signage_company_id: string;
  inventory_type: string;
  product: Product;
}

const RealtorSupplierPage = () => {
  const { supplierId } = useParams<{ supplierId: string }>();
  const [supplier, setSupplier] = useState<Supplier | null>(null);
  const token = localStorage.getItem("sessionToken");
  const [error, setError] = useState<string | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [supplierProducts, setSupplierProducts] = useState<SupplierProduct[]>([]);

  // Fetch the office data based on officeId
  useEffect(() => {
    const fetchSupplierData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${supplierId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch teammember data");
        }
        const data = await response.json();
        if (data && data.firmDetails && data.firmDetails.address) {
          setSupplier(data);

          // setUserModules(data.modulePermissions);
          // console.log(userModules);
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching teammember data:", error);
      }
    };

    if (supplierId) {
      fetchSupplierData();
    }
  }, [supplierId]);

  useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/signage/${supplierId}/inventory/products`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          console.log(data);
          // setProducts(data);
          setSupplierProducts(data);
        } catch (err) {
          setError(
            err instanceof Error ? err.message : "An unknown error occurred"
          );
        } finally {
          setLoading(false);
        }
      };
  
      fetchProducts();
    }, [token]);

  if (!supplier) return <div>Loading Teammember Data...</div>;

  return (
    <div className="supplier-page">
      <div className="row">
        <div className="col-12">
          <div className="page-title-box d-sm-flex align-items-center justify-content-between bg-galaxy-transparent">
            <h4 className="mb-sm-0">Supplier Details</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="#">Home</Link>
                </li>
                <li className="breadcrumb-item active">Supplier Details</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Supplier and Reviews Section */}
      <div className="row">
        <div className="col-xxl-3">
          <div className="card">
            <div className="card-body p-4">
              <div>
                <div className="flex-shrink-0 avatar-md mx-auto">
                  <div className="avatar-title bg-light rounded">
                    <img
                      src="/assets/images/companies/img-2.png"
                      alt="Supplier Logo"
                      height="50"
                    />
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h5 className="mb-1">{supplier.firmDetails.companyName}</h5>
                  <p className="text-muted">
                    {supplier.firmDetails.address.city}
                  </p>
                </div>
                <div className="p-1">
                  <table className="table mb-0 table-borderless">
                    <tbody>
                      {/* Sample data for now; replace with dynamic data if required */}
                      <tr>
                        <th>
                          <span className="fw-medium">Name</span>
                        </th>
                        <td>
                          {supplier.firstName} {supplier.lastName}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Job title / Role</span>
                        </th>
                        <td>{supplier.jobTitle}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Email</span>
                        </th>
                        <td>{supplier.email}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Website</span>
                        </th>
                        <td>
                          <Link to="#" className="link-primary">
                            {supplier.firmDetails.companyWebsite}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Contact No.</span>
                        </th>
                        <td>{supplier.phone1}</td>
                      </tr>
                      <tr>
                        <th>
                          <span className="fw-medium">Location</span>
                        </th>
                        <td>
                          {supplier.firmDetails.address.addressLine1}{" "}
                          {supplier.firmDetails.address.addressLine2}{" "}
                          {supplier.firmDetails.address.city}{" "}
                          {supplier.firmDetails.address.state}{" "}
                          {supplier.firmDetails.address.country}{" "}
                          {supplier.firmDetails.address.zip}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            {/* Customer Reviews Section */}
            <div className="card-body border-top border-top-dashed p-4">
              <h6 className="text-muted text-uppercase fw-semibold mb-4">
                Customer Reviews
              </h6>
              <div>
                <div className="bg-light px-3 py-2 rounded-2 mb-2">
                  <div className="d-flex align-items-center">
                    <div className="flex-grow-1">
                      <div className="fs-16 align-middle text-warning">
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-fill"></i>
                        <i className="ri-star-half-fill"></i>
                      </div>
                    </div>
                    <div className="flex-shrink-0">
                      <h6 className="mb-0">4.5 out of 5</h6>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className="text-muted">
                    Total <span className="fw-medium">5.50k</span> reviews
                  </div>
                </div>
              </div>
            </div>

            {/* Contact Support Form */}
            <div className="card-body p-4 border-top border-top-dashed">
              <h6 className="text-muted text-uppercase fw-semibold mb-4">
                Contact Support
              </h6>
              <form action="#">
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows={4}
                    placeholder="Enter your message..."
                  ></textarea>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn btn-primary">
                    <i className="ri-mail-send-line align-bottom me-1"></i>
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Dynamic Supplier List (replace placeholders if dynamic data is needed) */}
        <div className="col-xxl-9">
          {/* <div className="card">
            <div className="card-header border-0 align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">Revenue</h4>
                  <div>
                    <button type="button" className="btn btn-soft-secondary btn-sm">
                      ALL
                    </button>
                    <button type="button" className="btn btn-soft-secondary btn-sm">
                      1M
                    </button>
                    <button type="button" className="btn btn-soft-secondary btn-sm">
                      6M
                    </button>
                    <button type="button" className="btn btn-soft-primary btn-sm">
                      1Y
                    </button>
                  </div>
                </div>
            <div className="card-header p-0 border-0 bg-light-subtle">
              <div className="row g-0 text-center">
                <div className="col-6 col-sm-3">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value" data-target="7585">
                        0
                      </span>
                    </h5>
                    <p className="text-muted mb-0">Orders</p>
                  </div>
                </div>

                <div className="col-6 col-sm-3">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      $
                      <span className="counter-value" data-target="22.89">
                        0
                      </span>
                      k
                    </h5>
                    <p className="text-muted mb-0">Earnings</p>
                  </div>
                </div>

                <div className="col-6 col-sm-3">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value" data-target="367">
                        0
                      </span>
                    </h5>
                    <p className="text-muted mb-0">Refunds</p>
                  </div>
                </div>

                <div className="col-6 col-sm-3">
                  <div className="p-3 border border-dashed border-start-0 border-end-0">
                    <h5 className="mb-1 text-success">
                      <span className="counter-value" data-target="18.92">
                        0
                      </span>
                      %
                    </h5>
                    <p className="text-muted mb-0">Conversation Ratio</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body p-0 pb-2"></div>
          </div> */}
          <div className="row g-4 mb-3">
            <div className="col-sm-auto">
              <div>
                <h2>Products</h2>
                {/* <a
                  href="apps-ecommerce-add-product.html"
                  className="btn btn-success"
                >
                  <i className="ri-add-line align-bottom me-1"></i> Add New
                </a> */}
              </div>
            </div>
            <div className="col-sm">
              <div className="d-flex justify-content-sm-end">
                {/* <div className="search-box ms-2">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Products..."
                  />
                  <i className="ri-search-line search-icon"></i>
                </div> */}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="table-container">
                                  <table
                                    id="transactions"
                                    className="table table-bordered dt-responsive nowrap table-striped align-middle"
                                    style={{ width: "100%" }}
                                  >
                                    <thead>
                                      <tr>
                                        <th>
                                          <input type="checkbox" />
                                        </th>
                                        <th>S.No.</th>
                                        <th>Image</th>
                                        <th>Product Name</th>
                                        <th>Category</th>
                                        <th>Brand</th>
                                        <th>Qty</th>
                                        <th>Condition</th>
                                        <th>Dimensions</th>
                                        <th>Weight</th>
                                        {/* <th>Actions</th> */}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {supplierProducts != null &&
                                        supplierProducts.map((supplierProduct, index) => (
                                          <tr key={index}>
                                            <td>
                                              <input type="checkbox" />
                                            </td>
                                            <td>{index + 1}</td>
                                            <td>
                                              {supplierProduct?.product?.image?.url ? (
                                                <img
                                                  src={`https://dev.reelty.app/${supplierProduct?.product.image.url}`}
                                                  height={100}
                                                  alt="Product"
                                                />
                                              ) : (
                                                <span>No image</span>
                                              )}
                                            </td>
              
                                            <td>{supplierProduct?.product?.name}</td>
                                            <td>{supplierProduct?.product?.category}</td>
                                            <td>{supplierProduct?.product?.brand}</td>
                                            <td>10</td>
                                            <td>{supplierProduct?.product?.item_condition}</td>
                                            <td>
                                              {supplierProduct?.product?.package_dimensions?.length} x{" "}
                                              {supplierProduct?.product?.package_dimensions?.height} x{" "}
                                              {supplierProduct?.product?.package_dimensions?.width}
                                            </td>
                                            <td>
                                              {supplierProduct?.product?.package_dimensions?.weight}
                                            </td>
                                            {/* <td>
                                              <Link
                                                to={`/signage/editProduct/${product.product_id}`}
                                                className="btn btn-warning waves-effect waves-light me-2"
                                              >
                                                <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                              </Link>
              
                                              <button
                                                onClick={() =>
                                                  window.confirm(
                                                    "Are you sure you want to delete this product?"
                                                  ) &&
                                                  handleDelete(product.product_id)
                                                }
                                                className="btn btn-danger waves-effect waves-light"
                                              >
                                                <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                              </button>
                                            </td> */}
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorSupplierPage;
