import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../api/authApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  // const [responseData, setResponseData] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Clear previous errors

    try {
      const data = await loginUser(email, password);

      if (data.token && data.userType) {
        localStorage.setItem("sessionToken", data.token);
        localStorage.setItem("userType", data.userType);
        localStorage.setItem("userId", data.id);
        localStorage.setItem("userName", data.name);
        localStorage.setItem("licenseType", data.licenseType);

        switch (data.userType) {
          case "super_admin":
            navigate("/superadmin/dashboard");
            break;
          case "signage_firm":
            // if(!data.isProfileUpdated){
            //   navigate("/signage/profile");
            // }
            // else{
            //   navigate("/signage/dashboard");
            // } 
            navigate("/signage/dashboard");
            break;
          case "brokerage_firm":
            // if(!data.isProfileUpdated){
            //   navigate("/realtor/profile");
            // }
            // else{
            //   navigate("/realtor/dashboard");
            // } 
            navigate("/realtor/dashboard");           
            break;
          case "agent":
            // if(!data.isProfileUpdated){
            //   navigate("/agent/profile");
            // }
            // else{
            //   navigate("/agent/dashboard");
            // } 
            navigate("/agent/dashboard");
            break;
          case "installer":
            // if(!data.isProfileUpdated){
            //   navigate("/installer/profile");
            // }
            // else{
            //   navigate("/installer/dashboard");
            // } 
            navigate("/installer/dashboard");
            break;
          case "team_member":
            navigate("/teammember/dashboard");
            break;
          default:
            setError("Unknown user type.");
            break;
        }
      } else {
        setError("Invalid login response.");
        console.log(data.error);
        toast.error(data.error);
      }
    } catch (error) {
      setError("Invalid credentials, please try again.");
      console.error("Login failed:", error);      
    }
  };

  return (
    <div className="login">
      <ToastContainer />
      <div className="auth-page-wrapper pt-5">
        <div className="auth-one-bg-position auth-one-bg" id="auth-particles">
          <div className="bg-overlay"></div>
          <div className="shape"></div>
        </div>

        <div className="auth-page-content">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center mt-sm-5 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img
                        src="assets/images/logo-light.png"
                        alt="Logo"
                        height="20"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">
                    <div className="text-center mt-2">
                      <h5 className="text-primary">Welcome Back!</h5>
                      <p className="text-muted">Sign in to continue.</p>
                    </div>

                    <div className="p-2 mt-4">
                      <form onSubmit={handleLogin}>
                        <div className="mb-3">
                          <label htmlFor="email" className="form-label">
                            Email
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>

                        <div className="mb-3">
                          <div className="float-end">
                            {/* <a
                              href="auth-pass-reset-basic.html"
                              className="text-muted"
                            >
                              Forgot password?
                            </a> */}
                          </div>
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="position-relative auth-pass-inputgroup mb-3">
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control pe-5 password-input"
                              id="password"
                              placeholder="Enter password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                            <button
                              type="button"
                              className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                              id="password-addon"
                              onClick={togglePasswordVisibility}
                            >
                              <i
                                className={
                                  showPassword
                                    ? "ri-eye-off-fill"
                                    : "ri-eye-fill"
                                }
                                align-middle
                              ></i>
                            </button>
                          </div>
                        </div>

                        {/* <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="auth-remember-check"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="auth-remember-check"
                          >
                            Remember me
                          </label>
                        </div> */}

                        <div className="mt-4">
                          <button
                            className="btn btn-success w-100"
                            type="submit"
                          >
                            Signin
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>

                {/* <div className="mt-4 text-center">
                  <p className="mb-0">
                    Don't have an account?{" "}
                    <a
                      href="#"
                      className="fw-semibold text-primary text-decoration-underline"
                    >
                      Signup
                    </a>
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
