import React, { useState, useRef, useEffect } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SignageAddCountry = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("sessionToken");

  const formik = useFormik<{
    name: string;
    code: string;
  }>({
    initialValues: {
      name: "",
      code: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      code: Yup.string().required("Code is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/location/countries`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: values.name,
            code: values.code,
          }),
        });
    
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Failed to create country");
        }
    
        navigate("/signage/countries", {
          state: { successMessage: "Country added successfully!" },
        });
        toast.success("Country added successfully!");
      } catch (error: any) {
        console.error(error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setFormSubmitting(false);
      }
    }        
  });

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Add Country</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            {/* Name Field */}
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="invalid-feedback">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Code <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="code"
                                name="code"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.code}
                              />
                              {formik.touched.code && formik.errors.code ? (
                                <div className="invalid-feedback">
                                  {formik.errors.code}
                                </div>
                              ) : null}
                            </div>                            
                          </div>
                        </div>
                       
                      </div>

                      {/* Submit Button */}
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageAddCountry;
