import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams, useNavigate } from "react-router-dom";

interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface ModulePermission {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  status: boolean;
  volume: number;
  sub_modules: SubModule[];
}

interface PlanPermission {
  id: string;
  planID: string;
  planName: string;
  licenseType: string;
  modules: ModulePermission[];
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  status: boolean;
  volume: number;
  sub_modules: SubModule[];
}

type SubModule = {
  id: string;
  name: string;
  value: string;
  description: string;
  status: boolean;
  volume: number;
  used_volume: number;
};

interface Plan {
  id: string;
  planName: string;
  description: string;
}

const SuperAdminEditPlanPermissions = () => {
  const token = localStorage.getItem("sessionToken");
  const { planPermissionsId } = useParams<{ planPermissionsId: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [planPermissions, setPlanPermissions] = useState<PlanPermission | null>(
    null
  );
  const [availableModules, setAvailableModules] = useState<Module[]>([]);
  const navigate = useNavigate();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [updatedModules, setUpdatedModules] = useState<Module[]>([]);
const [updatedModulesWithSubModules, setUpdatedModulesWithSubModules] =
    useState<Module[]>([]);    

  useEffect(() => {
    const fetchPlanPermissionsData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/plan-permissions/${planPermissionsId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch plan data");

        const data = await response.json();
        setPlanPermissions(data);

        // Fetch modules for the license type
        if (data.licenseType) {
          fetchAvailableModules(data.licenseType);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    const fetchAvailableModules = async (licenseType: string) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/modules/license-type/${licenseType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) throw new Error("Failed to fetch available modules");

        const data = await response.json();
        setAvailableModules(data || []);
        setUpdatedModules(Array.isArray(data) ? data : []);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      }
    };

    if (planPermissionsId) fetchPlanPermissionsData();
  }, [planPermissionsId]);

   // Fetch Plans
    useEffect(() => {
      const fetchPlans = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/plan-profiles`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          setPlans(data.plans);
        } catch (err) {
          setError(
            err instanceof Error ? err.message : "An unknown error occurred"
          );
        } finally {
        }
      };
      fetchPlans();
    }, [token]);

useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/license-types`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedPlan: planPermissions?.planName || "",
      selectedLicenseType: planPermissions?.licenseType || "",
      modules: planPermissions?.modules.length
        ? planPermissions.modules
        : availableModules?.map((module) => ({
            id: module.id,
            name: module.name,
            module_code: module.module_code,
            license_type_code: module.license_type_code,
            description: module.description,
            status: module.status,
            volume: module.volume, 
            sub_modules: module.sub_modules,           
          })),
          agentsPlan: planPermissions?.modules.find((m) => m.name === "Agents")?.sub_modules?.find((sm) => sm.name === "Plan")?.value || "",
          brokeragePlan: planPermissions?.modules.find((m) => m.name === "Brokerage Firms")?.sub_modules?.find((sm) => sm.name === "Plan")?.value || "",
    },
    validationSchema: Yup.object({
      selectedPlan: Yup.string().required("Plan is required"),
      selectedLicenseType: Yup.string().required("License type is required"),
      modules: Yup.array().of(
        Yup.object().shape({
          status: Yup.boolean(),
          volume: Yup.number()
            .required("Volume is required")
            .min(0, "Volume must be greater than or equal to 0"),          
        })
      ),
      // agentsPlan: Yup.string().required("Agents Plan is required"),
      // brokeragePlan: Yup.string().required("Brokerage Plan is required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        
        const selectedPlanData = plans.find(
          (plan) => plan.planName === values.selectedPlan
        );
        const selectedAgentPlanData = plans.find(
          (plan) => plan.planName === values.agentsPlan
        );
        const selectedBrokeragePlanData = plans.find(
          (plan) => plan.planName === values.brokeragePlan
        );

        const updatedModules = values.modules.map((module) => ({
          ...module,
          sub_modules: module.sub_modules?.map((subModule) => {
            if (module.name === "Agents" && subModule.name === "Plan") {
              return { ...subModule, 
                value: selectedAgentPlanData?.planName,
                value_id: selectedAgentPlanData?.id,
                status: true,
              };
            }
            if (module.name === "Brokerage Firms" && subModule.name === "Plan") {
              return { ...subModule, 
                value: selectedBrokeragePlanData?.planName,
                value_id: selectedBrokeragePlanData?.id,
                status: true,
              };
            }
            return subModule;
          }),
        }));

        console.log(updatedModules);

        const requestData = {
          planID: selectedPlanData?.id,
          planName: values.selectedPlan,
          licenseType: values.selectedLicenseType,
          modules: updatedModules,
        };

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/plan-permissions/${planPermissionsId}`,
          {
            method: "PUT",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (!response.ok) throw new Error("Failed to submit permissions");

        navigate("/superadmin/profiles", {
          state: { successMessage: "Permissions updated successfully!" },
        });
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <div className="container mt-5">
      <div className="card">
        <div className="card-body">
          <h2 className="mb-4">Edit Plan Permissions</h2>
          {loading && <p className="text-center">Loading...</p>}
          {error && <p className="text-danger text-center">{error}</p>}
          {!loading && !error && (
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label>Plan Name</label>
                    <input
                      type="text"
                      name="selectedPlan"
                      className="form-control"
                      value={formik.values.selectedPlan}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.selectedPlan && formik.errors.selectedPlan && (
                      <p className="text-danger">{formik.errors.selectedPlan}</p>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group mb-3">
                    <label>License Type</label>
                    <select
                    name="selectedLicenseType"
                    className="form-control mb-3"
                    id="license-select"
                    value={formik.values.selectedLicenseType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    {licenseTypes.map((license) => (
                      <option key={license.id} value={license.code}>
                        {license.name}
                      </option>
                    ))}
                  </select>
                    {formik.touched.selectedLicenseType &&
                      formik.errors.selectedLicenseType && (
                        <p className="text-danger">{formik.errors.selectedLicenseType}</p>
                      )}
                  </div>
                </div>
              </div>
              <h4 className="mt-4">Modules</h4>
              <table className="table table-bordered mt-4">
  <thead>
    <tr>
      <th>Module</th>
      <th>Status</th>
      <th>Volume</th>
      <th>Plan</th>
    </tr>
  </thead>
  <tbody>
    {formik.values.modules?.map((module, index) => (
      <React.Fragment key={module.id}>
        <tr>
          <td>{module.name}</td>
          <td>
            <input
              type="checkbox"
              name={`modules[${index}].status`}
              checked={module.status}
              onChange={formik.handleChange}
            />
          </td>
          <td>
            <input
              type="number"
              name={`modules[${index}].volume`}
              className="form-control"
              value={module.volume}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </td>
          <td>
                        {module.name == "Agents" &&
                          Array.isArray(module.sub_modules) &&
                          module.sub_modules.map((smodule, index) =>
                            smodule.name === "Plan" ? (
                              <div className="form-group" key={index}>
                                {/* <label>{smodule.name}</label> */}
                                <select
                                  name="agentsPlan"
                                  id="agentsPlan"
                                  className="form-control"
                                  value={formik.values.agentsPlan}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                >
                                  <option value="">-Select-</option>
                                  {Array.isArray(plans) &&
                                    plans.map((plan, index) => (
                                      <option value={plan.planName} key={index}>
                                        {plan.planName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            ) : null
                          )}

                        {module.name == "Brokerage Firms" &&
                          Array.isArray(module.sub_modules) &&
                          module.sub_modules.map((smodule, index) =>
                            smodule.name === "Plan" ? (
                              <div className="form-group" key={index}>
                                {/* <label>{smodule.name}</label> */}
                                <select
                                  name="brokeragePlan"
                                  id="brokeragePlan"
                                  className="form-control"
                                  value={formik.values.brokeragePlan}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                >
                                  <option value="">-Select-</option>
                                  {Array.isArray(plans) &&
                                    plans.map((plan, index) => (
                                      <option value={plan.planName} key={index}>
                                        {plan.planName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            ) : null
                          )}
                      </td>
        </tr>        
      </React.Fragment>
    ))}
  </tbody>
</table>

              <div className="text-center">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminEditPlanPermissions;
