// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active {
    height: 70px;
    border: 1px solid #cccccc;
    width: 10% !important;
    text-align: center;    
    margin: 5px;
}
.swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active img{
    height: 60px;
}`, "",{"version":3,"sources":["webpack://./src/pages/signage/SignageViewProduct.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,qBAAqB;IACrB,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,YAAY;AAChB","sourcesContent":[".swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active {\n    height: 70px;\n    border: 1px solid #cccccc;\n    width: 10% !important;\n    text-align: center;    \n    margin: 5px;\n}\n.swiper-slide.swiper-slide-visible.swiper-slide-fully-visible.swiper-slide-active img{\n    height: 60px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
