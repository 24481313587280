import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type FileWithPreview = {
  file: File;
  preview: string;
};

interface License {
  id: string;
  firstName: string;
  lastName: string;
  statuss: string;
  licenseType: string;
  offices: string[] | null;
}

type Office = {
  id: string;
  officeName: string;
  officeType: string;
  officeAddress: OfficeAddress;
};

type OfficeAddress = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

type FormValues = {
  agentId: string;
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  length: number;
  height: number;
  width: number;
  weight: number;
  threshold_qty: number;
  installerId: string;
  installer_notes: string;
  image: File | null;
  imageUrl: string | null;
  statuss: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Product = {
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
  };
  threshold_qty: number;
  image: { url: string };
  status: string;
  is_rotational: boolean;
  linked_mode: string;
  linked_installer: {
    id: string;
    name: string;
  };
  installer_notes: string;
};

type AgentProduct = {
  product_id: string;
  agent_id: string;
  product: Product;
};

type Agent = {
  id: string;
  firstName: string;
};

interface Installer {
  supplier_id: string;
  name: string;
  email: string;
}

const RealtorEditAgentProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const { agentId } = useParams<{ agentId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [preview, setPreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [agent, setAgent] = useState<Agent | null>(null);
  const [product, setProduct] = useState<Product>();
  const [agentproduct, setAgentProduct] = useState<AgentProduct | null>(null);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [installers, setInstallers] = useState<Installer[]>([]);

  useEffect(() => {
        const fetchAgents = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users-search?userType=agent`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            });
    
            if (!response.ok) {
              throw new Error("Failed to fetch agents");
            }
    
            const data: Agent[] = await response.json();
            console.log(data);
            setAgents(data);
          } catch (err) {
            setError(
              err instanceof Error ? err.message : "An unknown error occurred"
            );
          } finally {
            setLoading(false);
          }
        };
    
        fetchAgents();
      }, [token]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }

        const data = await response.json();
        console.log("Fetched Product Data:", data);

        if (data) {
          setAgentProduct(data);
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching product data:", error);
        setError("Failed to fetch product data.");
      } finally {
        setLoading(false);
      }
    };

    if (!product) {
      fetchProduct();
    }
  }, [productId, product, token]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/brands`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "products");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${process.env.REACT_APP_API_URL}/images/upload`, true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        console.log("xhr:", xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log("upload response:", response);

          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  useEffect(() => {
      const fetchInstallers = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/agent/suppliers`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          });
    
          if (!response.ok) {
            throw new Error("Failed to fetch installers data");
          }
    
          const data = await response.json();
          console.log("Fetched Installers:", data);
    
          if (Array.isArray(data)) {
            setInstallers(data);
          } else {
            console.error("Unexpected installers data format:", data);
          }
        } catch (error) {
          console.error("Error fetching installers data:", error);
        }
      };
    
      fetchInstallers();
    }, [token]);

  function getInstallerById(installerId: string) {
    console.log("Looking up installer for ID:", installerId);
    return installers.find((installer) => installer.supplier_id === installerId) || null;
  }

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      agentId: agentproduct?.agent_id || "",
      name: agentproduct?.product?.name || "",
      description: agentproduct?.product?.description || "",
      brand: agentproduct?.product?.brand || "",
      category: agentproduct?.product?.category || "",
      item_condition: agentproduct?.product?.item_condition || "",
      purchase_price: agentproduct?.product?.purchase_price || 0,
      retail_price: agentproduct?.product?.retail_price || 0,
      length: agentproduct?.product?.package_dimensions?.length || 0,
      height: agentproduct?.product?.package_dimensions?.height || 0,
      width: agentproduct?.product?.package_dimensions?.width || 0,
      weight: agentproduct?.product?.package_dimensions?.weight || 0,
      threshold_qty: agentproduct?.product?.threshold_qty || 0,
      installerId: product?.linked_installer?.id || "",
      installer_notes: product?.installer_notes || "",
      image: null,
      imageUrl: agentproduct?.product?.image?.url || "", // Changed from null to empty string
      statuss: agentproduct?.product?.status || "active",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
      // image: Yup.mixed().test(
      //   "fileType",
      //   "Unsupported file format",
      //   (value) => {
      //     if (!value) return true; // Image is optional
      //     return value instanceof File
      //       ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
      //       : false;
      //   }
      // ),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      const agentId = values.agentId;

      var linkedMode = 'standalone';
      var selectedInstallerId = values.installerId || null;
      var selectedInstallerName = '';
      var linkedInstallerObj = null;
      if(values.installerId!=='')
      {
        const selectedInstaller = getInstallerById(values.installerId);
        linkedInstallerObj = {
          id: selectedInstaller?.supplier_id || null,
          name: selectedInstaller?.name || ''
        }
        // selectedInstallerId = selectedInstaller?.supplier_id || null;
        // selectedInstallerName = selectedInstaller?.name || '';
        linkedMode = 'linked';
      }
      else{
        linkedMode = 'standalone';
      }

      try {
        let imageUrl = values.imageUrl;

        // Upload new image if provided
        if (values.image instanceof File) {
          imageUrl = await uploadFile(values.image);
        }

        // Submit product details
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              // agent_id: agentId,
              // inventory_type: "agent",
              // product: {
                name: values.name,
                description: values.description,
                brand: values.brand,
                category: values.category,
                item_condition: values.item_condition,
                purchase_price: Number(values.purchase_price),
                retail_price: Number(values.retail_price),
                package_dimensions: {
                  length: Number(values.length),
                  height: Number(values.height),
                  width: Number(values.width),
                  weight: Number(values.weight),
                },
                threshold_qty: Number(values.threshold_qty),
                image: { url: imageUrl },
                status: values.statuss,
                is_rotational: false,
                linked_mode: linkedMode, 
                linked_installer: {
                  id: selectedInstallerId,
                  name: selectedInstallerName,
                }, 
                installer_notes: values.installer_notes,
              // }            
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        const result = await response.json();
        toast.success("Product updated successfully!");
        navigate(`/realtor/agentInventory`, {
          state: { successMessage: "Product updated successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update Product.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Edit Agent Product </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-1 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                          <div className="col-md-4 mb-3">
                              <label className="form-label">Agent </label>
                              <select
                                className="form-control"
                                id="agentId"
                                name="agentId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.agentId}
                              >
                                <option value="">- Select -</option>
                                {agents !== null &&
                                  agents.map((agent) => (
                                    <option value={agent.id}>
                                      {agent.firstName}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.agentId && formik.errors.agentId ? (
                                <div className="text-danger">
                                  {formik.errors.agentId}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Brand</label>
                              <select
                                className="form-control"
                                id="brand"
                                name="brand"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.brand}
                              >
                                <option value="">- Select -</option>
                                {brands != null &&
                                  brands.map((brand) => (
                                    <option key={brand.id} value={brand.name}>
                                      {brand.name}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.brand && formik.errors.brand ? (
                                <div className="text-danger">
                                  {formik.errors.brand}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Category</label>
                              <select
                                className="form-control"
                                id="category"
                                name="category"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category}
                              >
                                <option value="">- Select -</option>
                                {categories !== null &&
                                  categories.map((category) => (
                                    <option value={category.name}>
                                      {category.name}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.category &&
                              formik.errors.category ? (
                                <div className="text-danger">
                                  {formik.errors.category}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Item Condition
                              </label>
                              <select
                                className="form-control"
                                id="item_condition"
                                name="item_condition"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.item_condition}
                              >
                                <option value="">- Select -</option>
                                <option value="new">New</option>
                                <option value="used">Used</option>
                                <option value="damaged">Damaged</option>
                              </select>
                              {formik.touched.item_condition &&
                              formik.errors.item_condition ? (
                                <div className="text-danger">
                                  {formik.errors.item_condition}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Purchase Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="purchase_price"
                                name="purchase_price"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.purchase_price}
                              />
                              {formik.touched.purchase_price &&
                              formik.errors.purchase_price ? (
                                <div className="text-danger">
                                  {formik.errors.purchase_price}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Retail Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="retail_price"
                                name="retail_price"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.retail_price}
                              />
                              {formik.touched.retail_price &&
                              formik.errors.retail_price ? (
                                <div className="text-danger">
                                  {formik.errors.retail_price}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Threshold Quantity{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="threshold_qty"
                                name="threshold_qty"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.threshold_qty}
                              />
                              {formik.touched.threshold_qty &&
                              formik.errors.threshold_qty ? (
                                <div className="text-danger">
                                  {formik.errors.threshold_qty}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="text-danger">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                              ></textarea>
                              {formik.touched.description &&
                              formik.errors.description ? (
                                <div className="text-danger">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Dimensions/Measurements (Inches)
                              </label>
                              <div className="row">
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="length"
                                    name="length"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.length}
                                    placeholder="Length"
                                  />
                                  {formik.touched.length &&
                                  formik.errors.length ? (
                                    <div className="text-danger">
                                      {formik.errors.length}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="height"
                                    name="height"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.height}
                                    placeholder="Height"
                                  />
                                  {formik.touched.height &&
                                  formik.errors.height ? (
                                    <div className="text-danger">
                                      {formik.errors.height}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="width"
                                    name="width"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.width}
                                    placeholder="Width"
                                  />
                                  {formik.touched.width &&
                                  formik.errors.width ? (
                                    <div className="text-danger">
                                      {formik.errors.width}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Weight(in gms)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="weight"
                                name="weight"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weight}
                              />
                              {formik.touched.weight && formik.errors.weight ? (
                                <div className="text-danger">
                                  {formik.errors.weight}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Image</label>
                              <input
                                type="file"
                                className="form-control"
                                id="image"
                                name="image"
                                accept="image/*"
                                onChange={(event) => {
                                  const file = event.currentTarget.files?.[0];
                                  if (file) {
                                    formik.setFieldValue("image", file);
                                    setPreview(URL.createObjectURL(file)); // Optional: show preview
                                  } else {
                                    formik.setFieldValue("image", null);
                                    setPreview(null);
                                  }
                                }}
                                onBlur={formik.handleBlur}
                              />
                              {preview ? (
                                <img
                                  src={preview}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "100px",
                                    marginTop: "10px",
                                  }}
                                />
                              ) : product?.image?.url ? (
                                <img
                                  src={`https://dev.reelty.app/${product.image.url}`}
                                  alt="Current Product"
                                  style={{
                                    maxWidth: "100px",
                                    marginTop: "10px",
                                  }}
                                />
                              ) : null}
                              {formik.touched.image && formik.errors.image ? (
                                <div className="text-danger">
                                  {formik.errors.image}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Installer</label>
                              <select
                                name="installerId"
                                id="installerId"
                                className="form-control"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.installerId}
                              >
                                <option value="">-Select-</option>
                                {installers &&
                                  installers.map((installer) => (
                                    <option
                                      key={installer?.supplier_id}
                                      value={installer?.supplier_id}
                                    >
                                      {installer?.name}{" "}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.installerId &&
                              formik.errors.installerId ? (
                                <div className="text-danger">
                                  {formik.errors.installerId}
                                </div>
                              ) : null}
                            </div>
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Notes for the Installer{" "}
                              </label>
                              <textarea
                                className="form-control"
                                id="installer_notes"
                                name="installer_notes"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.installer_notes}
                              ></textarea>
                              {formik.touched.installer_notes && formik.errors.installer_notes ? (
                                <div className="text-danger">
                                  {formik.errors.installer_notes}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Save Inventory Item
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorEditAgentProduct;
