import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";

type Office = {
  id: string;
  officeName: string;
  officeType: string;
};

type Teammember = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone1: string;
  phone2: string;
  email: string;
  offices: Office[];
  // permissions: string;
  // password: string;
  photo?: string;
  status: string;
  licenseType: string;
};

const RealtorTeammembers = () => {
  const [teammembers, setTeammembers] = useState<Teammember[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null);
  const token = localStorage.getItem("sessionToken");

  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.successMessage && !toastShown) {
      toast.success(location.state.successMessage);
      setToastShown(true);
      location.state.successMessage = null;
    }
  }, [location.state, toastShown]);
  
  useEffect(() => {
    const fetchTeammembers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/team-members`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) throw new Error("Failed to fetch team members");
        const data = await response.json();
        console.log(data);
        setTeammembers(data); 
        console.log(teammembers); // Ensure `data` is an array or fallback to empty
      } catch (err) {
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchTeammembers();
  }, [token]);

  const handleDelete = async (teammemberId: string) => {
    setDeleting(teammemberId);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${teammemberId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) throw new Error("Failed to delete the team member");

      toast.success("Team member deleted successfully!");
      setTeammembers((prev) => prev.filter((teammember) => teammember.id !== teammemberId));
      setDeleting(null);
      navigate(0);
    } catch (err) {
      setError(err instanceof Error ? err.message : "An unknown error occurred while deleting.");
    } finally {
      setDeleting(null);
    }
  };

  useEffect(() => {
    if(teammembers!=null && teammembers.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [teammembers]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Team Members</h2>
          <div className="text-end">
            <Link to="/realtor/add-teammember" className="btn btn-primary">
              Add Team Member
            </Link>
          </div>
          {teammembers === null ? (
            <div>No team members found.</div>
          ) : (
            <div className="table-container">
              <table className="table table-bordered table-striped align-middle" style={{ width: "100%" }} id='transactions'>
                <thead>
                  <tr>
                    <th>Team Member ID</th>
                    <th>Team Member Name</th>
                    <th>Email</th>
                    <th>Role/Position</th>
                    <th>Associated Offices</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(teammembers) && teammembers.map((teammember) => (
                    <tr key={teammember.id}>
                      <td>
                        <Link to={`/realtor/view-teammember/${teammember.id}`}>{teammember.id}</Link>
                      </td>
                      <td>
                        <Link to={`/realtor/view-teammember/${teammember.id}`}>
                          {teammember.firstName} {teammember.lastName}
                        </Link>
                      </td>
                      <td>{teammember.email}</td>
                      <td>{teammember.jobTitle}</td>
                      <td>
                        {teammember.offices!==null && teammember.offices.map((office) => (
                          <Link to={`/realtor/view-office/${office.id}`} key={office.id} className="btn waves-effect waves-light">
                            {office.officeName}
                          </Link>
                        ))}
                      </td>
                      <td>{teammember.status}</td>
                      <td>
                        <Link to={`/realtor/view-teammember/${teammember.id}`} className="btn btn-secondary me-2 mb-2">
                          <i className="ri-eye-fill align-bottom"></i>
                        </Link>
                        <Link to={`/realtor/edit-teammember/${teammember.id}`} className="btn btn-warning me-2 mb-2">
                          <i className="ri-pencil-fill align-bottom"></i>
                        </Link>
                        <button
                          onClick={() =>
                            window.confirm("Are you sure you want to delete this team member?") &&
                            handleDelete(teammember.id)
                          }
                          className="btn btn-danger me-2"
                          disabled={deleting === teammember.id}
                        >
                          <i className="ri-delete-bin-fill align-bottom"></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RealtorTeammembers;
