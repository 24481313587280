import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useParams } from "react-router-dom";

type City = {
  id:string;
  stateId: string;
  stateName: string;
  name: string;
  code: string;
};

const SignageCities = () => {
    const { stateId } = useParams<{ stateId: string }>();
  const [cities, setCities] = useState<City[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");

  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCities = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/location/cities/state/${stateId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          if (Array.isArray(data)) {
            setCities(data);
          } else if (data.cities) {
            setCities(data.cities); // Adjust based on API structure
          } else {
            throw new Error("Invalid data format");
          }
        } catch (err) {
          setError(
            err instanceof Error ? err.message : "An unknown error occurred"
          );
          setCities([]); // Fallback to an empty array
        } finally {
          setLoading(false);
        }
      };
      

    fetchCities();
  }, [token]);

  // if (cities?.length === 0) {
  //   return <div>Loading...</div>;
  // }

  useEffect(() => {
    if(cities!=null && cities.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [cities]);

  const handleDelete = async (cityId: string) => {

    setDeleting(cityId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/location/cities/${cityId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the city.");
      }

      toast.success("State deleted successfully!");
      // Update the city by removing the deleted license
      setCities((prev) =>
        prev.filter((city) => city.id !== cityId)
      );
      setDeleting(null); // Clear the deleting city
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the city.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting city
    }
  };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Cities </h2>
          <div className="text-end">
            <a
              href={`/signage/addCity/${stateId}`}
              className="btn btn-primary float-right"
            >
              Add City
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th> 
                          <th>City Name</th>                         
                          <th>Code</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cities!= null &&
                          cities.map((city, index) => (
                            <tr key={index}>                              
                              <td>{index + 1}</td>  
                              <td>{city.name}</td>
                              <td>{city.code}</td>
                              <td>
                                <Link
                                  to={`/signage/editCity/${city.id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <button
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this city?"
                                    ) && handleDelete(city.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageCities;
