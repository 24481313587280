import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type TaxRate = {
  id: string;
  name: string;
  taxRate: number;
  statuss: string;
};

const SignageEditTaxRate = () => {
  const { taxId } = useParams<{ taxId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const [taxObj, setTaxObj] = useState<TaxRate | null>(null);

  const navigate = useNavigate();

  // Fetch tax rate data
  useEffect(() => {
    const fetchTaxRate = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/taxes/${taxId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch tax data");
        }
        const data = await response.json();
        setTaxObj({
          id: data.id || "",
          name: data.name || "",
          taxRate: data.taxRate || 0,
          statuss: data.status ? "Active" : "Inactive",
        });
      } catch (error) {
        setError("Error fetching tax data. Please try again later.");
        console.error("Error fetching tax data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (taxId) {
      fetchTaxRate();
    }
  }, [taxId, token]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: taxObj?.name || "",
      taxRate: taxObj?.taxRate || 0,
      statuss: taxObj?.statuss || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      taxRate: Yup.number()
        .typeError("Tax Rate must be a number")
        .positive("Tax Rate must be a positive number")
        .required("Tax Rate is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/taxes/${taxId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              taxRate: values.taxRate,
              status: values.statuss === "Active",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update data");
        }

        toast.success("Tax Rate updated successfully!");
        navigate("/signage/taxRates", {
          state: { successMessage: "Tax Rate updated successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update Tax Rate.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Tax Rate</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name && (
                            <div className="text-danger">
                              {formik.errors.name}
                            </div>
                          )}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Tax Rate <span className="text-danger">*</span>
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="taxRate"
                            name="taxRate"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.taxRate}
                          />
                          {formik.touched.taxRate && formik.errors.taxRate && (
                            <div className="text-danger">
                              {formik.errors.taxRate}
                            </div>
                          )}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                          >
                            <option value="">- Select -</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                          {formik.touched.statuss &&
                            formik.errors.statuss && (
                              <div className="text-danger">
                                {formik.errors.statuss}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageEditTaxRate;
