import React, { useState, useRef, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { sub } from "date-fns";

const libraries: Array<"places"> = ["places"];
const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  status: boolean;
  volume: number;
  sub_modules: SubModule[];
}

type SubModule = {
  id: string;
  name: string;
  value: string;
  description: string;
  status: boolean;
  volume: number;
  used_volume: number;
};

interface Plan {
  id: string;
  planName: string;
  description: string;
}

const SuperAdminAddLicense = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [loading, setLoading] = useState(true);
  const [licenseType, setLicenseType] = useState<string>("");
  const [modules, setModules] = useState<Module[]>([]);
  const [updatedModules, setUpdatedModules] = useState<Module[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  // Fetch Plans
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/plan-profiles`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setPlans(data.plans);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
      }
    };
    fetchPlans();
  }, [token]);

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/license-types`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, [token]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [address, setAddress] = useState("");
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const formik = useFormik({
    initialValues: {
      companyName: "",
      jobTitle: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
      // status: "",
      licenseValidity: "",
      billingCycle: "",
      billingCyclePrice: "",
      paymentMethod: "",
      licenseType: "",
      statuss: "",
      agentsPlan: "",
      brokeragePlan: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .min(2, "Company name must be at least 2 characters long")
        .max(50, "Company name cannot be longer than 50 characters"),
      jobTitle: Yup.string()
        .min(2, "Office type must be at least 2 characters long")
        .max(50, "Office type cannot be longer than 50 characters"),
      firstName: Yup.string()
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      streetAddress1: Yup.string()
        .min(2, "Street address must be at least 8 characters long")
        .max(50, "Street address must be max 50 characters long")
        .required("Street Address 1 is required"),
      streetAddress2: Yup.string().max(
        50,
        "Street address must be max 50 characters long"
      ),
      city: Yup.string()
        .min(2, "City must be at least 2 characters long")
        .max(50, "City cannot be longer than 50 characters")
        .required("City is required"),
      state: Yup.string()
        .min(2, "State must be at least 2 characters long")
        .max(50, "State cannot be longer than 50 characters")
        .required("State is required"),
      zipcode: Yup.string().required("Zip code is required"),
      country: Yup.string()
        .min(2, "Country must be at least 2 characters long")
        .max(50, "Country cannot be longer than 50 characters"),
      statuss: Yup.string().required("Status is required"),
      licenseValidity: Yup.string().required("Validity Duration is required"),
      billingCycle: Yup.string().required("Billing cycle is required"),
      paymentMethod: Yup.string().required("Payment method is required"),
      licenseType: Yup.string().required("License type is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values); // For debugging
      setFormSubmitting(true);
      try {
        if (values.licenseType == "SIGNAGE_FIRM") {

          const selectedAgentPlanData = plans.find(
            (plan) => plan.planName === values.agentsPlan
          );
          const selectedBrokeragePlanData = plans.find(
            (plan) => plan.planName === values.brokeragePlan
          );

          const updatedModulesWithSubModules = modules.map((module) => ({
            ...module,
            sub_modules: module.sub_modules?.map((smodule) => {
              if (module.name === "Agents") {
                return {
                  id: "",
                  name: "Plan",
                  value: selectedAgentPlanData?.planName || "",
                  value_id: selectedAgentPlanData?.id || "",
                  description: "Plan description",
                  status: true,
                  volume: 0,
                  used_volume: 0,
                };
              } else if (module.name === "Brokerage Firms" && smodule.name === "Plan") {
                return {
                  id: "",
                  name: "Plan",
                  value: selectedBrokeragePlanData?.planName || "",
                  value_id: selectedBrokeragePlanData?.id || "",
                  description: "Plan description",
                  status: true,
                  volume: 0,
                  used_volume: 0,
                };
              }
              return smodule; // Return the original submodule if no conditions match
            }) || [], // Ensure fallback to an empty array if `sub_modules` is undefined
          }));
          

          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/create`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                email: values.email,
                password: "password123",
                userType: "signage_firm",
                firstName: values.firstName,
                lastName: values.lastName,
                phone1: values.phone,
                jobTitle: values.jobTitle,
                firmDetails: {
                  companyName: values.companyName || "",
                  // companyWebsite: "https://example.com",
                  address: {
                    addressLine1: values.streetAddress1,
                    addressLine2: values.streetAddress2,
                    city: values.city,
                    state: values.state,
                    country: values.country || "",
                    zip: values.zipcode,
                    // cityCode: "212",
                    // stateCode: "NY",
                    // countryCode: "US",
                    // latitude: "40.7128",
                    // longitude: "-74.0060",
                    phone: values.phone,
                  },
                },
                licenseType: values.licenseType,
                licenseValidity: values.licenseValidity,
                billingCycle: values.billingCycle,
                billingCyclePrice: Number(values.billingCyclePrice),
                paymentMethod: values.paymentMethod,
                status: values.statuss,
                modulePermissions: updatedModulesWithSubModules,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to save data");
          }

          const result = await response.json();
          toast.success("License added successfully!");
          console.log("Form submission successful:", result);
          navigate("/superadmin/license-registrations", {
            state: { successMessage: "License added successfully!" },
          });
        } else if (values.licenseType == "BROKERAGE_FIRM") {

          const selectedAgentPlanData = plans.find(
            (plan) => plan.planName === values.agentsPlan
          );

          const updatedModulesWithSubModules = modules.map((module) => ({
            ...module,
            sub_modules: module.sub_modules?.map((smodule) => {
              if (smodule.name === "Agents Plan") {
                return {
                  id: "",
                  name: "Plan",
                  value: selectedAgentPlanData?.planName || "",
                  value_id: selectedAgentPlanData?.id || "",
                  description: "Plan description",
                  status: true,
                  volume: 0,
                  used_volume: 0,
                };
              } 
              return smodule; // Return the original submodule if no conditions match
            }) || [], // Ensure fallback to an empty array if `sub_modules` is undefined
          }));
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/create`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                email: values.email,
                password: "password123",
                userType: "brokerage_firm",
                firstName: values.firstName,
                lastName: values.lastName,
                phone1: values.phone,
                jobTitle: values.jobTitle,
                firmDetails: {
                  // companyID: "12345",
                  companyName: values.companyName,
                  companyWebsite: "https://example.com",
                  address: {
                    addressLine1: values.streetAddress1,
                    addressLine2: values.streetAddress2,
                    city: values.city,
                    state: values.state,
                    country: values.country,
                    zip: values.zipcode,
                    // cityCode: "212",
                    // stateCode: "NY",
                    // countryCode: "US",
                    // latitude: "40.7128",
                    // longitude: "-74.0060",
                    phone: values.phone,
                  },
                },
                licenseType: values.licenseType,
                licenseValidity: values.licenseValidity,
                billingCycle: values.billingCycle,
                billingCyclePrice: Number(values.billingCyclePrice),
                paymentMethod: values.paymentMethod,
                status: values.statuss,
                modulePermissions: updatedModulesWithSubModules,
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to save data");
          }

          const result = await response.json();
          // toast.success("License added successfully!");
          console.log("Form submission successful:", result);
          navigate("/superadmin/license-registrations", {
            state: { successMessage: "License added successfully!" },
          });
        } else if (values.licenseType == "AGENT") {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/create`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                email: values.email,
                password: "password123",
                userType: "agent",
                firstName: values.firstName,
                lastName: values.lastName,
                phone1: values.phone,
                jobTitle: values.jobTitle,
                firmDetails: {
                  companyName: values.companyName || "",
                  // companyWebsite: "https://example.com",
                  address: {
                    addressLine1: values.streetAddress1,
                    addressLine2: values.streetAddress2,
                    city: values.city,
                    state: values.state,
                    country: values.country || "",
                    zip: values.zipcode,
                    // cityCode: "212",
                    // stateCode: "NY",
                    // countryCode: "US",
                    // latitude: "40.7128",
                    // longitude: "-74.0060",
                    phone: values.phone,
                  },
                },
                licenseType: values.licenseType,
                licenseValidity: values.licenseValidity,
                billingCycle: values.billingCycle,
                billingCyclePrice: Number(values.billingCyclePrice),
                paymentMethod: values.paymentMethod,
                status: "inactive",
                // modulePermissions: modules.map((module) => ({
                //   _id: module.id,
                //   name: module.name,
                //   module_code: module.module_code,
                //   description: module.description,
                //   status: module.status,
                //   volume: module.volume,
                // })) || [],setModules
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to save data");
          }

          const result = await response.json();
          toast.success("License added successfully!");
          console.log("Form submission successful:", result);
          navigate("/superadmin/license-registrations", {
            state: { successMessage: "License added successfully!" },
          });
        } else if (values.licenseType == "INSTALLER") {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/user/create`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify({
                email: values.email,
                password: "password123",
                userType: "installer",
                firstName: values.firstName,
                lastName: values.lastName,
                phone1: values.phone,
                jobTitle: values.jobTitle,
                firmDetails: {
                  companyName: values.companyName || "",
                  // companyWebsite: "https://example.com",
                  address: {
                    addressLine1: values.streetAddress1,
                    addressLine2: values.streetAddress2,
                    city: values.city,
                    state: values.state,
                    country: values.country || "",
                    zip: values.zipcode,
                    // cityCode: "212",
                    // stateCode: "NY",
                    // countryCode: "US",
                    // latitude: "40.7128",
                    // longitude: "-74.0060",
                    phone: values.phone,
                  },
                },
                licenseType: values.licenseType,
                licenseValidity: values.licenseValidity,
                billingCycle: values.billingCycle,
                billingCyclePrice: Number(values.billingCyclePrice),
                paymentMethod: values.paymentMethod,
                status: values.statuss,
                // modulePermissions: modules.map((module) => ({
                //   _id: module.id,
                //   name: module.name,
                //   module_code: module.module_code,
                //   description: module.description,
                //   status: module.status,
                //   volume: module.volume,
                // })) || [],
              }),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to save data");
          }

          const result = await response.json();
          toast.success("License added successfully!");
          console.log("Form submission successful:", result);
          navigate("/superadmin/license-registrations", {
            state: { successMessage: "License added successfully!" },
          });
        }
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add License.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchModules = async () => {
      if (!formik.values.licenseType) return;

      setLoadingModules(true);
      setError("");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/modules/license-type/${formik.values.licenseType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch modules");
        }
        const data = await response.json();
        setModules(data || []);
        setUpdatedModules(Array.isArray(data) ? data : []);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoadingModules(false);
      }
    };

    fetchModules();
  }, [formik.values.licenseType]);

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const addressComponents = place.address_components;

      if (addressComponents) {
        const getComponent = (type: string) => {
          const component = addressComponents.find((c) =>
            c.types.includes(type)
          );
          return component ? component.long_name : "";
        };

        const streetNumber = getComponent("street_number");
        const route = getComponent("route");
        const city =
          getComponent("locality") ||
          getComponent("administrative_area_level_2");
        const state = getComponent("administrative_area_level_1");
        const postalCode = getComponent("postal_code");
        const country = getComponent("country");

        const street = `${streetNumber} ${route}`.trim();
        formik.setFieldValue("streetAddress1", street);
        formik.setFieldValue("city", city);
        formik.setFieldValue("state", state);
        formik.setFieldValue("zipcode", postalCode);
        formik.setFieldValue("country", country);
      }
    }
  };

  // Function to handle checkbox changes for module status
  const handleModuleStatusChange = (index: number, checked: boolean) => {
    const updatedModules = [...modules];
    updatedModules[index].status = checked;
    setModules(updatedModules); // Update the modules state
  };

  // Function to handle volume input changes for module volume
  const handleModuleVolumeChange = (index: number, value: string) => {
    const updatedModules = [...modules];
    updatedModules[index].volume = parseInt(value, 10); // Update volume value
    setModules(updatedModules); // Update the modules state
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Add License </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-2 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-0">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Company Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            name="companyName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyName}
                          />
                          {formik.touched.companyName &&
                          formik.errors.companyName ? (
                            <div className="text-danger">
                              {formik.errors.companyName}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        {/* <div className="col-md-6 mb-3">
                          <label className="form-label">Role</label>
                          <input
                            type="text"
                            className="form-control"
                            id="jobTitle"
                            name="jobTitle"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                          />
                          {formik.touched.jobTitle && formik.errors.jobTitle ? (
                            <div className="text-danger">
                              {formik.errors.jobTitle}
                            </div>
                          ) : null}
                        </div> */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Job Title/Role </label>
                          <select
                            className={`form-control ${
                              formik.touched.jobTitle && formik.errors.jobTitle
                                ? "is-invalid"
                                : ""
                            }`}
                            id="jobTitle"
                            name="jobTitle"
                            value={formik.values.jobTitle}
                            onChange={formik.handleChange}
                          >
                            <option value="">- Select -</option>
                            <option value="Owner">Owner</option>
                            <option value="Manager">Manager</option>
                          </select>
                          {formik.touched.jobTitle && formik.errors.jobTitle ? (
                            <div className="invalid-feedback">
                              {formik.errors.jobTitle}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            required
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Street Address 1{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (autocompleteRef.current = autocomplete)
                            }
                            onPlaceChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              className="form-control"
                              id="streetAddress1"
                              name="streetAddress1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress1}
                              required
                            />
                          </Autocomplete>
                          {formik.touched.streetAddress1 &&
                          formik.errors.streetAddress1 ? (
                            <div className="text-danger">
                              {formik.errors.streetAddress1}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Street Address 2{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="streetAddress2"
                            name="streetAddress2"
                            placeholder="Enter Address line 2"
                          />
                          <div className="invalid-feedback">
                            Please enter Address line 2
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            City <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            name="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                            required
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="text-danger">
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            State <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="state"
                            name="state"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                            required
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className="text-danger">
                              {formik.errors.state}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Zip code <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="zipcode"
                            name="zipcode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.zipcode}
                            required
                          />
                          {formik.touched.zipcode && formik.errors.zipcode ? (
                            <div className="text-danger">
                              {formik.errors.zipcode}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">Country</label>
                          <input
                            type="text"
                            className="form-control"
                            id="country"
                            name="country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.country}
                          />
                          {formik.touched.country && formik.errors.country ? (
                            <div className="text-danger">
                              {formik.errors.country}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                            required
                          >
                            <option value="">- Select -</option>
                            <option value="active">Active </option>
                            <option value="inactive">Inactive </option>
                            <option value="expired">Expired </option>
                            <option value="pending">Pending </option>
                            <option value="suspended">Suspended </option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a Status
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Billing cycle </label>
                          <select
                            className="form-control"
                            id="billingCycle"
                            name="billingCycle"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingCycle}
                          >
                            <option value="">- Select -</option>
                            <option value="month-month">Month-Month</option>
                            <option value="12 months">12 months</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a Billing cycle
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            License validity duration{" "}
                          </label>
                          <select
                            className="form-control"
                            id="licenseValidity"
                            name="licenseValidity"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.licenseValidity}
                          >
                            <option value="">- Select -</option>
                            <option value="1 month">1 month</option>
                            <option value="3 months">3 months</option>
                            <option value="6 months">6 months</option>
                            <option value="12 months">12 months</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a License validity duration
                          </div>
                        </div>

                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Billing Cycle Price </label>
                            <input
                              type="text"
                              name="billingCyclePrice"
                              id="billingCyclePrice"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.billingCyclePrice}
                            />
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Payment method </label>
                          <select
                            className="form-control"
                            id="paymentMethod"
                            name="paymentMethod"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.paymentMethod}
                          >
                            <option value="">- Select -</option>
                            <option value="Card">Card</option>
                            <option value="Bank transfer">Bank transfer</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a Payment method
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  License Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="licenseType"
                                  id="licenseType"
                                  className="form-control mb-3"
                                  value={formik.values.licenseType}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  // onChange={(e) =>
                                  //   setLicenseType(e.target.value)
                                  // }
                                  required
                                >
                                  <option value="">Select License Type</option>
                                  {licenseTypes.map((license) => (
                                    <option
                                      key={license.id}
                                      value={license.code}
                                    >
                                      {license.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="permissions-list">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Module</th>
                                      <th>Yes/No</th>
                                      <th>Volume</th>
                                      <th> </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {modules.map((module, index) => (
                                      <tr key={index}>
                                        <td>{module.name}</td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            checked={module.status}
                                            onChange={(e) =>
                                              handleModuleStatusChange(
                                                index,
                                                e.target.checked
                                              )
                                            } // Handle checkbox changes
                                          />
                                        </td>
                                        <td>
                                          <input
                                            type="number"
                                            value={module.volume}
                                            onChange={(e) =>
                                              handleModuleVolumeChange(
                                                index,
                                                e.target.value
                                              )
                                            } // Handle volume changes
                                            style={{ width: "100px" }}
                                          />
                                        </td>
                                        <td>
                                          {/* {Array.isArray(module.sub_modules) && module.sub_modules.map((smodule, index)=>( */}
                                          {/* <table>
                                        {Array.isArray(module.sub_modules)?
                                          <select name="plan" id="plan" className="form-control">
                                            <option value="">-Select-</option>
                                            {Array.isArray(plans) && plans.map((plan,index)=>(
                                              <option value={plan.planName}>{plan.planName}</option>
                                            ))}                                            
                                          </select>
                                          :""
                                          }                                          
                                      </table> */}

                                          {module.name == "Agents" &&
                                            Array.isArray(module.sub_modules) &&
                                            module.sub_modules.map(
                                              (smodule, index) =>
                                                smodule.name === "Plan" ? (
                                                  <div
                                                    className="form-group"
                                                    key={index}
                                                  >
                                                    <label>
                                                      {smodule.name}
                                                    </label>
                                                    <select
                                                      name="agentsPlan"
                                                      id="agentsPlan"
                                                      className="form-control"
                                                      value={
                                                        formik.values.agentsPlan
                                                      }
                                                      onBlur={formik.handleBlur}
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                    >
                                                      <option value="">
                                                        -Select-
                                                      </option>
                                                      {Array.isArray(plans) &&
                                                        plans.map(
                                                          (plan, index) => (
                                                            <option
                                                              value={
                                                                plan.planName
                                                              }
                                                              key={index}
                                                            >
                                                              {plan.planName}
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                  </div>
                                                ) : null
                                            )}

                                          {module.name == "Brokerage Firms" &&
                                            Array.isArray(module.sub_modules) &&
                                            module.sub_modules.map(
                                              (smodule, index) =>
                                                smodule.name === "Plan" ? (
                                                  <div
                                                    className="form-group"
                                                    key={index}
                                                  >
                                                    <label>
                                                      {smodule.name}
                                                    </label>
                                                    <select
                                                      name="brokeragePlan"
                                                      id="brokeragePlan"
                                                      className="form-control"
                                                      value={
                                                        formik.values
                                                          .brokeragePlan
                                                      }
                                                      onBlur={formik.handleBlur}
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                    >
                                                      <option value="">
                                                        -Select-
                                                      </option>
                                                      {Array.isArray(plans) &&
                                                        plans.map(
                                                          (plan, index) => (
                                                            <option
                                                              value={
                                                                plan.planName
                                                              }
                                                              key={index}
                                                            >
                                                              {plan.planName}
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                  </div>
                                                ) : null
                                            )}
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          // disabled={formSubmitting}
                        >
                          {formSubmitting ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddLicense;
