import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type CountyZipList = {
  county: string;
  zipList: string[];
};

type GeoLocation = {
  id: string;
  country: string;
  countryCode: string;
  state: string;
  stateCode: string;
  countyZipList: CountyZipList;  
};

const SignageGeoLocations = () => {
  const [geolocations, setGeoLocations] = useState<GeoLocation[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");

  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
//   useEffect(() => {
//     if (location.state?.successMessage && !toastShown) {
//       toast.success(location.state.successMessage);
//       setToastShown(true);
//       location.state.successMessage = null;
//     }
//   }, [location.state, toastShown]);

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US"); // Outputs in MM/DD/YYYY format
  }

  useEffect(() => {
    const fetchGeoLocations = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/geo-locations`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setGeoLocations(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchGeoLocations();
  }, [token]);

  useEffect(() => {
    if(geolocations!=null && geolocations.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [geolocations]);

  if (geolocations?.length === 0) {
    return <div>Loading...</div>;
  }

  const handleDelete = async (geolocationId: string) => {
    // const confirmation = window.confirm(
    //   "Are you sure you want to delete this license?"
    // );
    // if (!confirmation) return;

    setDeleting(geolocationId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/geo-locations/${geolocationId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the geolocation.");
      }

      toast.success("GeoLocation deleted successfully!");
      // Update the state by removing the deleted license
      setGeoLocations((prev) =>
        prev.filter((geolocation) => geolocation.id !== geolocationId)
      );
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the geolocation.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Geo Locations </h2>
          <div className="text-end">
            <a
              href="/signage/addGeoLocation"
              className="btn btn-primary float-right"
            >
              Add Geo Location
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Country</th>
                          <th>State</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {geolocations != null &&
                          geolocations.map((geolocation, index) => (
                            <tr key={index}>                              
                              <td>{index + 1}</td>
                              <td>{geolocation.country}</td>
                              <td>{geolocation.state}</td>
                              <td>
                                <Link
                                  to={`/signage/viewGeolocation/${geolocation.id}`}
                                  className="btn btn-secondary waves-effect waves-light me-2"
                                >
                                  <i className="ri-eye-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <Link
                                  to={`/signage/editGeolocation/${geolocation.id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <button
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this geolocation?"
                                    ) && handleDelete(geolocation.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light me-2"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageGeoLocations;
