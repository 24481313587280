import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type FormValues = {
  name: string;
  description: string;
  sku: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  length: number;
  height: number;
  width: number;
  weight: number;
  qty: number;
  threshold_qty: number;
  installerId: string;
  notes: string;
  image: File | null;
  imageUrl: string | null;
  statuss: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  product_id: string;
  name: string;
  description: string;
  sku: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  qty: number;
  threshold_qty: number;
  package_dimensions: Dimensions;
  notes: string;
  image: Image | null;
  status: string;
  linked_mode: string;
  linked_installer: {
    id: string;
    name: string;
  };
  variants: Variant[];
};

type Image = {
  url: string;
};

interface Installer {
  supplier_id: string;
  name: string;
  email: string;
}

interface FirmDetails {
  companyID: string;
  companyName: string;
}

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
  length: number;
  height: number;
  width: number;
  weight: number;
  weight_type: string;
  },
  inventory: [
  {
    warehouse_id: string;
    warehouse_name: string;
    on_hand: number;
    available: number;
    reserved: number;
  },
  ],
};

const AgentViewProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [preview, setPreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [product, setProduct] = useState<Product>();
  const [installers, setInstallers] = useState<Installer[]>([]);

  // Fetch product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/agent/${userId}/inventory/${productId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch category data");
        }
        const data = await response.json();
        console.log(data);
        setProduct(data.product);
      } catch (err) {
        console.error("Error fetching category data:", err);
        toast.error("Error fetching category data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (productId) fetchProduct();
  }, [productId, token]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/brands`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  useEffect(() => {
    const fetchInstallers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/agent/suppliers`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error("Failed to fetch installers data");
        }
  
        const data = await response.json();
        console.log("Fetched Installers:", data);
  
        if (Array.isArray(data)) {
          setInstallers(data);
        } else {
          console.error("Unexpected installers data format:", data);
        }
      } catch (error) {
        console.error("Error fetching installers data:", error);
      }
    };
  
    fetchInstallers();
  }, [token]);
  

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "products");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${process.env.REACT_APP_API_URL}/images/upload`, true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        console.log("xhr:", xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log("upload response:", response);

          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  function getInstallerById(installerId: string) {
    console.log("Looking up installer for ID:", installerId);
    return installers.find((installer) => installer.supplier_id === installerId) || null;
  }

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: product?.name || "",
      description: product?.description || "",
      sku: product?.sku || "",
      brand: product?.brand || "",
      category: product?.category || "",
      item_condition: product?.item_condition || "",
      purchase_price: product?.purchase_price || 0,
      retail_price: product?.retail_price || 0,
      length: product?.package_dimensions?.length || 0,
      height: product?.package_dimensions?.height || 0,
      width: product?.package_dimensions?.width || 0,
      weight: product?.package_dimensions?.weight || 0,
      qty: product?.variants?.[0]?.inventory?.[0]?.on_hand || 0,
      threshold_qty: product?.threshold_qty || 0,
      installerId: product?.linked_installer?.id || "",
      notes: product?.notes || "",
      image: null, // Initialize as null for new uploads
      imageUrl: product?.image?.url || null, // Use this for prefilled image URL
      statuss: product?.status || "active",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
      image: Yup.mixed()
        .nullable()
        .test("fileType", "Unsupported file format", (value) =>
          value instanceof File
            ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
            : true
        ),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      // console.log("Selected Installer ID:", values.installerId);
      // const selectedInstallerId = values.installerId;
      // const selectedInstaller = getInstallerById(selectedInstallerId);
      // console.log("Selected Installer Object:", selectedInstaller);

      var linkedMode = 'standalone';
      var selectedInstallerId = values.installerId || null;
      var selectedInstallerName = '';
      var linkedInstallerObj = null;
      if(values.installerId!=='')
      {
        const selectedInstaller = getInstallerById(values.installerId);
        linkedInstallerObj = {
          id: selectedInstaller?.supplier_id || null,
          name: selectedInstaller?.name || ''
        }
        // selectedInstallerId = selectedInstaller?.supplier_id || null;
        // selectedInstallerName = selectedInstaller?.name || '';
        linkedMode = 'linked';
      }
      else{
        linkedMode = 'standalone';
      }

      try {
        let imageUrl = values.imageUrl;

        // Upload new image if provided
        if (values.image instanceof File) {
          imageUrl = await uploadFile(values.image);
        }

        // 2. Submit product details
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/agent/${userId}/inventory/${productId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              sku: values.sku,
              description: values.description,
              product_type: "simple",              
              brand: values.brand,
              category: values.category,
              item_condition: values.item_condition,
              purchase_price: Number(values.purchase_price),
              retail_price: Number(values.retail_price),
              package_dimensions: {
                length: Number(values.length),
                height: Number(values.height),
                width: Number(values.width),
                weight: Number(values.weight),
                weight_type: "g",
              },
              min_qty: 1,
              status: values.statuss,
              linked_mode: linkedMode,
              threshold_qty: Number(values.threshold_qty),
              image: { url: imageUrl },
              linked_installer: linkedInstallerObj,
              variants:[
                {
                  variant_name: product?.name,
                  sku: product?.sku,
                  attributes: {},
                  purchase_price: Number(product?.purchase_price),
                  retail_price: Number(product?.retail_price),
                  package_dimensions: {
                  length: Number(values.length),
                  height: Number(values.height),
                  width: Number(values.width),
                  weight: Number(values.weight),
                  weight_type: "g",
                  },
                  inventory: [
                  {
                    warehouse_id: "",
                    warehouse_name: "",
                    on_hand: Number(values.qty),
                    available: Number(values.qty),
                    reserved: 0,
                  },
                  ],
                },
              ],
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        const result = await response.json();
        navigate("/agent/inventory", {
          state: { successMessage: "Product added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Product.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Product Details </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-1 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-1">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="mb-3">
                            <div className="mb-3">
                              <img
                                src={`https://dev.reelty.app/${product?.image?.url || ""}`}
                                alt="Product"
                                className="img-fluid"
                                style={{ maxHeight: "200px" }}
                              />
                            </div>                            
                          </div>
                          </div>
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-9 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={product?.name}
                                readOnly
                              />
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">Brand</label>
                              <input
                                className="form-control"
                                id="brand"
                                name="brand"                                
                                value={product?.brand}
                                readOnly
                              />
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">Category</label>
                              <input type="text" className="form-control" 
                              value={product?.category} readOnly />
                                
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Item Condition
                              </label>
                              <input type="text"
                                className="form-control"
                                id="item_condition"
                                name="item_condition"
                                value={product?.item_condition}
                                readOnly
                              />
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Purchase Price{" "}
                                
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="purchase_price"
                                name="purchase_price"                                
                                value={product?.purchase_price}
                                readOnly
                              />                              
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Retail Price{" "}
                                
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="retail_price"
                                name="retail_price"                                
                                value={product?.retail_price}
                                readOnly
                              />
                             
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Quantity{" "}
                                
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="qty"
                                name="qty"
                                
                                value={product?.qty}
                                readOnly
                              />
                              
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Threshold Quantity{" "}
                                
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="threshold_qty"
                                name="threshold_qty"
                                
                                value={product?.threshold_qty}
                                readOnly
                              />
                             
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Status 
                              </label>
                              <input
                                className="form-control"
                                id="statuss"
                                name="statuss"
                                
                                value={product?.status}
                                readOnly
                              />
                              
                            </div>
                           
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description/Notes{" "}
                                
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                
                                value={product?.description}
                                rows={4}
                                readOnly
                              ></textarea>
                              
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Dimensions/Measurements (Inches)
                              </label>
                              <div className="row">
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="length"
                                    name="length"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={product?.package_dimensions?.length}
                                    placeholder="Length"
                                    readOnly
                                  />
                                  
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="height"
                                    name="height"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={product?.package_dimensions?.height}
                                    placeholder="Height"
                                    readOnly
                                  />
                                 
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="width"
                                    name="width"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={product?.package_dimensions?.width}
                                    placeholder="Width"
                                    readOnly
                                  />
                                 
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Weight(in gms)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="weight"
                                name="weight"
                                
                                value={product?.package_dimensions?.weight}
                                readOnly
                              />
                             
                            </div>
                            

                            <div className="col-md-4 mb-3">
                              <label className="form-label">Installer</label>
                              <input
                                name="installerId"
                                id="installerId"
                                className="form-control"
                                
                                value={product?.linked_installer?.name || ""}
                                readOnly
                              />
                             
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Notes for the Installer{" "}
                              </label>
                              <textarea
                                className="form-control"
                                id="notes"
                                name="notes"
                                
                                value={product?.notes}
                                readOnly
                              ></textarea>
                              
                            </div>
                            
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentViewProduct;
