import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Thumbs } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useParams } from "react-router-dom";
import "./SignageViewProduct.css";

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      warehouse_id: string;
      warehouse_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

type Product = {
  product_id: string;
  name: string;
  product_type: string;
  sku: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: Dimensions;
  threshold_qty: number;
  variants: Variant[];
  condition_note: string;
  image: Image | null;
  status: string;
  is_rotational: boolean;
  tax_rule: string;
};

type Image = {
  url: string;
};

type ProductObj = {
  product_id: string;
  signage_company_id: string;
  product: Product;
  created_by: {
    id: string;
    name: string;
  };
};

type TaxGroup = {
  id: string;
  name: string;
  status: string;
};

type TaxRule = {
  id: string;
  country: string;
  state: string;
  stateCode: string;
  county: string;
  countyCode: string;
  zipCodes: string[];
  taxRate: number;
  comments: string;
  status: string;
};

const SignageViewProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [product, setProduct] = useState<Product>();
  const [productobj, setProductObj] = useState<ProductObj>();
  const [productImages, setProductImages] = useState<string[]>([]);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  // Fetch product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/signage/${userId}/inventory/${productId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }

        const data: ProductObj = await response.json();
        console.log("Fetched Product Data:", data);
        // setProduct(data);
        setProductObj(data);
        setProductImages(data.product.image ? [data.product.image.url] : []);
      } catch (err) {
        console.error("Error fetching product data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (productId) fetchProduct();
  }, [productId, token]);

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Product Details </h2>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row gx-lg-5">
                <div className="col-xl-4 col-md-8 mx-auto">
                  {/* <div className="product-img-slider sticky-side-div">
                     <div className="swiper product-thumbnail-slider p-2 rounded bg-light">
                       <div className="swiper-wrapper">
                         <div className="swiper-slide">
                           <img
                             src={`https://dev.reelty.app/${
                               productobj?.product?.image?.url || ""
                             }`}
                             alt=""
                             className="img-fluid d-block"
                           />
                         </div>
                         <div className="swiper-slide">
                           <img
                             src={`https://dev.reelty.app/${
                               productobj?.product?.image?.url || ""
                             }`}
                             alt=""
                             className="img-fluid d-block"
                           />
                         </div>
                         <div className="swiper-slide">
                           <img
                             src={`https://dev.reelty.app/${
                               productobj?.product?.image?.url || ""
                             }`}
                             alt=""
                             className="img-fluid d-block"
                           />
                         </div>
                         <div className="swiper-slide">
                           <img
                             src={`https://dev.reelty.app/${
                               productobj?.product?.image?.url || ""
                             }`}
                             alt=""
                             className="img-fluid d-block"
                           />
                         </div>
                       </div>
                       <div className="swiper-button-next material-shadow"></div>
                       <div className="swiper-button-prev material-shadow"></div>
                     </div>
                     <div className="swiper product-nav-slider mt-2">
                       <div className="swiper-wrapper">
                         <div className="swiper-slide">
                           <div className="nav-slide-item">
                             <img
                               src={`https://dev.reelty.app/${
                                 productobj?.product?.image?.url || ""
                               }`}
                               alt=""
                               className="img-fluid d-block"
                             />
                           </div>
                         </div>
                         <div className="swiper-slide">
                           <div className="nav-slide-item">
                             <img
                               src={`https://dev.reelty.app/${
                                 productobj?.product?.image?.url || ""
                               }`}
                               alt=""
                               className="img-fluid d-block"
                             />
                           </div>
                         </div>
                         <div className="swiper-slide">
                           <div className="nav-slide-item">
                             <img
                               src={`https://dev.reelty.app/${
                                 productobj?.product?.image?.url || ""
                               }`}
                               alt=""
                               className="img-fluid d-block"
                             />
                           </div>
                         </div>
                         <div className="swiper-slide">
                           <div className="nav-slide-item">
                             <img
                               src={`https://dev.reelty.app/${
                                 productobj?.product?.image?.url || ""
                               }`}
                               alt=""
                               className="img-fluid d-block"
                             />
                           </div>
                         </div>
                       </div>
                     </div>
                   </div> */}
                  <div className="product-slider-container">
                    <Swiper
                      modules={[Navigation, Thumbs]}
                      navigation
                      thumbs={{ swiper: thumbsSwiper }}
                      spaceBetween={10}
                      slidesPerView={1}
                    >
                      {productImages.map((img, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={`https://dev.reelty.app/${img}`}
                            alt={`Slide ${index + 1}`}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>

                    {/* Thumbnail Slider */}
                    <Swiper
                      modules={[Thumbs]}
                      // onSwiper={setThumbsSwiper}
                      spaceBetween={10}
                      slidesPerView={4}
                      watchSlidesProgress
                    >
                      {productImages.map((img, index) => (
                        <SwiperSlide key={index}>
                          <img
                            src={`https://dev.reelty.app/${img}`}
                            alt={`Thumbnail ${index + 1}`}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>
                </div>

                <div className="col-xl-8">
                  <div className="mt-xl-0 mt-5">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <h4>{productobj?.product?.name}</h4>
                        {/* <div className="hstack gap-3 flex-wrap">
                           <div>
                             <a href="#" className="text-primary d-block">
                               Tommy Hilfiger
                             </a>
                           </div>
                           <div className="vr"></div>
                           <div className="text-muted">
                             Seller :{" "}
                             <span className="text-body fw-medium">
                               Zoetic Fashion
                             </span>
                           </div>
                           <div className="vr"></div>
                           <div className="text-muted">
                             Published :{" "}
                             <span className="text-body fw-medium">
                               26 Mar, 2021
                             </span>
                           </div>
                         </div> */}
                      </div>
                      {/* <div className="flex-shrink-0">
                         <div>
                           <a
                             href="apps-ecommerce-add-product.html"
                             className="btn btn-light"
                             data-bs-toggle="tooltip"
                             data-bs-placement="top"
                             title="Edit"
                           >
                             <i className="ri-pencil-fill align-bottom"></i>
                           </a>
                         </div>
                       </div> */}
                    </div>

                    {/* <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                       <div className="text-muted fs-16">
                         <span className="mdi mdi-star text-warning"></span>
                         <span className="mdi mdi-star text-warning"></span>
                         <span className="mdi mdi-star text-warning"></span>
                         <span className="mdi mdi-star text-warning"></span>
                         <span className="mdi mdi-star text-warning"></span>
                       </div>
                       <div className="text-muted">
                         ( 5.50k Customer Review )
                       </div>
                     </div> */}

                    <div className="row mt-4">
                      <div className="col-lg-3 col-sm-6">
                        <div className="p-2 border border-dashed rounded">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                              <div className="avatar-title rounded bg-transparent text-success fs-24">
                                <i className="ri-money-dollar-circle-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-1">Price :</p>
                              <h5 className="mb-0">
                                ${productobj?.product?.retail_price}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6">
                        <div className="p-2 border border-dashed rounded">
                          <div className="d-flex align-items-center">
                            <div className="avatar-sm me-2">
                              <div className="avatar-title rounded bg-transparent text-success fs-24">
                                <i className="ri-file-copy-2-fill"></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <p className="text-muted mb-1">SKU :</p>
                              <h5 className="mb-0">
                                {productobj?.product?.sku}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-3 col-sm-6">
                         <div className="p-2 border border-dashed rounded">
                           <div className="d-flex align-items-center">
                             <div className="avatar-sm me-2">
                               <div className="avatar-title rounded bg-transparent text-success fs-24">
                                 <i className="ri-stack-fill"></i>
                               </div>
                             </div>
                             <div className="flex-grow-1">
                               <p className="text-muted mb-1">
                                 Available Qty :
                               </p>
                               <h5 className="mb-0">1,230</h5>
                             </div>
                           </div>
                         </div>
                       </div> */}

                      {/* <div className="col-lg-3 col-sm-6">
                         <div className="p-2 border border-dashed rounded">
                           <div className="d-flex align-items-center">
                             <div className="avatar-sm me-2">
                               <div className="avatar-title rounded bg-transparent text-success fs-24">
                                 <i className="ri-inbox-archive-fill"></i>
                               </div>
                             </div>
                             <div className="flex-grow-1">
                               <p className="text-muted mb-1">Total Revenue :</p>
                               <h5 className="mb-0">$60,645</h5>
                             </div>
                           </div>
                         </div>
                       </div> */}
                    </div>

                    <div className="row mt-5">
                      <h5>Available Combinations:</h5>
                      {productobj?.product?.variants.map((variant, index) => (
                        <div className="col-lg-3 col-sm-6" key={index}>
                          <div className="p-2 border border-dashed rounded">
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                {Object.entries(variant?.attributes || {}).map(
                                  ([key, value], index) => (
                                    <div className="col-xl-6" key={index}>
                                      <div className="mt-1">
                                        {/* <h5 className="fs-14">{key} :</h5> */}
                                        <div className="d-flex flex-wrap gap-2">
                                          <div
                                            data-bs-toggle="tooltip"
                                            data-bs-trigger="hover"
                                            data-bs-placement="top"
                                            title="Out of Stock"
                                          >
                                            {/* <input
                                              type="radio"
                                              className="btn-check"
                                              name="productsize-radio"
                                              id="productsize-radio1"
                                              disabled
                                            /> */}
                                            {value}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="mt-4 text-muted">
                      <h5 className="fs-14">Description :</h5>
                      <p>{productobj?.product?.description}</p>
                    </div>

                    {/* <div className="row">
                       <div className="col-sm-6">
                         <div className="mt-3">
                           <h5 className="fs-14">Features :</h5>
                           <ul className="list-unstyled">
                             <li className="py-1">
                               <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                               Full Sleeve
                             </li>
                             <li className="py-1">
                               <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                               Cotton
                             </li>
                             <li className="py-1">
                               <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                               All Sizes available
                             </li>
                             <li className="py-1">
                               <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                               4 Different Color
                             </li>
                           </ul>
                         </div>
                       </div>
                       <div className="col-sm-6">
                         <div className="mt-3">
                           <h5 className="fs-14">Services :</h5>
                           <ul className="list-unstyled product-desc-list">
                             <li className="py-1">10 Days Replacement</li>
                             <li className="py-1">Cash on Delivery available</li>
                           </ul>
                         </div>
                       </div>
                     </div> */}

                    <div className="product-content mt-5">
                      {/* <h5 className="fs-14 mb-3">Product Description :</h5> */}
                      <nav>
                        <ul
                          className="nav nav-tabs nav-tabs-custom nav-success"
                          id="nav-tab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link active"
                              id="nav-speci-tab"
                              data-bs-toggle="tab"
                              href="#nav-speci"
                              role="tab"
                              aria-controls="nav-speci"
                              aria-selected="true"
                            >
                              Specification
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="nav-detail-tab"
                              data-bs-toggle="tab"
                              href="#nav-detail"
                              role="tab"
                              aria-controls="nav-detail"
                              aria-selected="false"
                            >
                              Details
                            </a>
                          </li>
                        </ul>
                      </nav>
                      <div
                        className="tab-content border border-top-0 p-4"
                        id="nav-tabContent"
                      >
                        <div
                          className="tab-pane fade show active"
                          id="nav-speci"
                          role="tabpanel"
                          aria-labelledby="nav-speci-tab"
                        >
                          <div className="table-responsive">
                            <table className="table mb-0">
                              <tbody>
                                <tr>
                                  <th scope="row" style={{ width: "200px" }}>
                                    Category
                                  </th>
                                  <td>{productobj?.product?.category}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Brand</th>
                                  <td>{productobj?.product?.brand}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Dimensions</th>
                                  <td>
                                    {
                                      productobj?.product?.package_dimensions
                                        ?.length
                                    }{" "}
                                    x{" "}
                                    {
                                      productobj?.product?.package_dimensions
                                        ?.width
                                    }{" "}
                                    x{" "}
                                    {
                                      productobj?.product?.package_dimensions
                                        ?.height
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Weight</th>
                                  <td>
                                    {
                                      productobj?.product?.package_dimensions
                                        ?.weight
                                    }{" "}
                                    Gram
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Item Condition</th>
                                  <td>{productobj?.product?.item_condition}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="nav-detail"
                          role="tabpanel"
                          aria-labelledby="nav-detail-tab"
                        >
                          <div>
                            <h5 className="font-size-16 mb-3">
                              {productobj?.product?.name}
                            </h5>
                            <p>{productobj?.product?.description}</p>
                            {/* <div>
                               <p className="mb-2">
                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                 Machine Wash
                               </p>
                               <p className="mb-2">
                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                 Fit Type: Regular
                               </p>
                               <p className="mb-2">
                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                 100% Cotton
                               </p>
                               <p className="mb-0">
                                 <i className="mdi mdi-circle-medium me-1 text-muted align-middle"></i>{" "}
                                 Long sleeve
                               </p>
                             </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="mt-0 card-bg-fill">
            <div className="pt-1">
              <div className="pt-1 mt-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageViewProduct;
