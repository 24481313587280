import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Warehouse = {
  id: string;
  label: string;
  status: string;
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      warehouse_id: string;
      warehouse_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

type Product = {
  product_id: string;
  name: string;
  product_type: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  package_dimensions: Dimensions;
  variants: Variant[];
  condition_note: string;
  status: string;
  is_rotational: boolean;
  linked_mode: string;
  linked_installer: LinkedInstaller;
};

type LinkedInstaller = {
  id: string;
  name: string;
  requested_quantity: number;
  warehouse_info: {
    id: string;
    label: string;
    bin_location: string;
  }
};

type Agent = {
  id: string;
  name: string;
};

const SignageEditIncomingProduct = () => {
    const { productId } = useParams<{ productId: string }>();
    const { agentId } = useParams<{ agentId: string }>();
    const [agent, setAgent] = useState<Agent | null>(null);
    const [product, setProduct] = useState<Product | null>(null);
    const [categories, setCategories] = useState<Category[]>([]);
    const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>("");
    const [formSubmitting, setFormSubmitting] = useState(false);
    const token = localStorage.getItem("sessionToken");
    const userId = localStorage.getItem("userId");
    const userName = localStorage.getItem("userName");
    const navigate = useNavigate();
    const [selectedWarehouseName, setSelectedWarehouseName] = useState<string | null>(null);

  // Fetch product data
  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }
        const data = await response.json();
        setProduct(data.product);
        setAgent(data.created_by);
      } catch (err) {
        console.error("Error fetching product data:", err);
        toast.error("Error fetching product data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (productId) fetchProduct();
  }, [productId, token, userId]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        console.error("Error fetching categories:", err);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/warehouses`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setWarehouses(data);
      } catch (err) {
        console.error("Error fetching warehouses:", err);
      }
    };

    fetchWarehouses();
  }, [token]);

  // Formik for form handling
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      statuss: product?.status || "active",
      installer_status: product?.linked_mode,
      brokerage_office: "Main Office",
      name: product?.name || "",
      category: product?.category || "",
      description: product?.description || "",
      qty: product?.linked_installer?.requested_quantity,
      item_condition: product?.item_condition || "",
      dimensions: product
    ? `${product.package_dimensions?.length || 0}x${product.package_dimensions?.height || 0}x${product.package_dimensions?.width || 0}`
    : "",
      weight: product?.package_dimensions?.weight?.toString() || "",
      linked_agent: agent?.name,      
      installer_notes: "",
      linked_installer: product?.linked_installer?.name || "",
      warehouseId: product?.linked_installer?.warehouse_info?.id || "",
      bin_location: product?.linked_installer?.warehouse_info?.bin_location || "",
      is_rotational: product?.is_rotational ? "yes" : "no",  
      action: "",    
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);

      let actionvalue = values.action;

      if(actionvalue=='accept')
      {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                product: {
                  // name: values.name,
                  // sku: values.sku,
                  // description: values.description,
                  // product_type: "simple",              
                  // brand: values.brand,
                  // category: values.category,
                  // item_condition: values.item_condition,
                  // purchase_price: Number(values.purchase_price),
                  // retail_price: Number(values.retail_price),
                  // package_dimensions: {
                  //   length: Number(values.length),
                  //   height: Number(values.height),
                  //   width: Number(values.width),
                  //   weight: Number(values.weight),
                  //   weight_type: "g",
                  // },
                  // min_qty: 1,
                  // status: values.statuss,
                  linked_mode: "linked", 
                  is_rotational: (values.is_rotational==='yes')?true:false,
                  // threshold_qty: Number(values.threshold_qty),
                  // image: { url: imageUrl },
                  linked_installer: {
                    id: product?.linked_installer?.id || "",
                    name: product?.linked_installer?.name || "",
                    requested_quantity: product?.linked_installer?.requested_quantity,
                    warehouse_info: {
                      id: values.warehouseId,
                      label: selectedWarehouseName,
                      bin_location: values.bin_location,
                      linked_product_quantity: values.qty,
                    }, 
                  },
                  variants:[
                    {
                      // variant_name: product?.name,
                      // sku: product?.sku,
                      // attributes: {},
                      // purchase_price: Number(product?.purchase_price),
                      // retail_price: Number(product?.retail_price),
                      // package_dimensions: {
                      // length: Number(values.length),
                      // height: Number(values.height),
                      // width: Number(values.width),
                      // weight: Number(values.weight),
                      // weight_type: "g",
                      // },
                      inventory: [
                      {
                        warehouse_id: values.warehouseId,
                        warehouse_name: selectedWarehouseName,
                        // on_hand: Number(values.qty),
                        // available: Number(values.qty),
                        // reserved: 0,
                      },
                      ],
                    },
                  ],
            }}),
            }
          );

          if (!response.ok) {
            throw new Error("Failed to update product");
          }
  
          toast.success("Product updated successfully!");
          navigate("/signage/incomingProducts", {
            state: { successMessage: "Product updated successfully!" },
          });
        } catch (err) {
          console.error(err);
          toast.error("Failed to update product.");
        } finally {
          setFormSubmitting(false);
        }
      }
      else{
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                  linked_mode: "rejected", 
                  linked_installer: null,
                }),
            }
          );

          const response2 = await fetch(
            `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}/linked-quantity`,
            {
              method: "PUT",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                linked_product_quantity: 0,                  
              }),
            }
          );
  
          if (!response.ok) {
            throw new Error("Failed to update product");
          }
  
          toast.success("Product updated successfully!");
          navigate("/signage/incomingProducts", {
            state: { successMessage: "Product updated successfully!" },
          });
        } catch (err) {
          console.error(err);
          toast.error("Failed to update product.");
        } finally {
          setFormSubmitting(false);
        }
      }

      
    },
  });

  if (loading) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Incoming Product</h2>

          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-3 mb-3">
                <label className="form-label">
                  Status 
                </label>
                <input
                  className="form-control"
                  id="statuss"
                  name="statuss"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.statuss}
                  readOnly
                />
                {formik.touched.statuss && formik.errors.statuss ? (
                  <div className="invalid-feedback">
                    {formik.errors.statuss}
                  </div>
                ) : null}
              </div>
              <div className="col-md-3 mb-3">
                <label className="form-label">
                  Installer Status
                </label>
                <input
                  className="form-control"
                  id="installer_status"
                  name="installer_status"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.installer_status}
                  readOnly
                />                  
                {formik.touched.installer_status && formik.errors.installer_status ? (
                  <div className="invalid-feedback">
                    {formik.errors.installer_status}
                  </div>
                ) : null}
              </div>
              {/* <div className="col-md-6 mb-3">
                <label className="form-label">
                  Brokerage Office
                </label>
                <input
                  className="form-control"
                  id="brokerage_office"
                  name="brokerage_office"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.brokerage_office}
                  readOnly
                />                  
                {formik.touched.brokerage_office && formik.errors.brokerage_office ? (
                  <div className="invalid-feedback">
                    {formik.errors.brokerage_office}
                  </div>
                ) : null}
              </div>               */}
                  <div className="col-md-6 mb-3">
                    <label className="form-label">
                      Product Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      readOnly
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="invalid-feedback">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <label className="form-label">Product Category</label>
                    <select
                      className="form-control"
                      id="category"
                      name="category"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.category}
                    >
                      <option value="">- Select -</option>
                      {categories !== null &&
                        categories.map((category) => (
                          <option value={category.name}>{category.name}</option>
                        ))}
                    </select>
                    {formik.touched.category && formik.errors.category ? (
                      <div className="text-danger">
                        {formik.errors.category}
                      </div>
                    ) : null}
                  </div> */}
                  {/* <div className="col-md-12 mb-3">
                    <label className="form-label">
                      Product Description
                     
                    </label>
                    <textarea
                      className="form-control"
                      id="description"
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      rows={4}
                    ></textarea>
                    {formik.touched.description && formik.errors.description ? (
                      <div className="text-danger">
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div> */}

                  
                  <div className="col-md-3 mb-3">
                    <label className="form-label">Item Condition</label>
                    <select
                      className="form-control"
                      id="item_condition"
                      name="item_condition"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.item_condition}
                    >
                      <option value="">- Select -</option>
                      <option value="new">New</option>
                      <option value="used">Used</option>
                      <option value="damaged">Damaged</option>
                    </select>
                    {formik.touched.item_condition &&
                    formik.errors.item_condition ? (
                      <div className="text-danger">
                        {formik.errors.item_condition}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-3 mb-3">
                    <label className="form-label">Dimensions</label>
                    <input
                      type="number"
                      className="form-control"
                      id="dimensions"
                      name="dimensions"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dimensions}
                    />
                    {formik.touched.dimensions &&
                    formik.errors.dimensions ? (
                      <div className="text-danger">
                        {formik.errors.dimensions}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-3 mb-3">
                    <label className="form-label">Weight</label>
                    <input
                      type="number"
                      className="form-control"
                      id="weight"
                      name="weight"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.weight}
                    />
                    {formik.touched.weight &&
                    formik.errors.weight ? (
                      <div className="text-danger">
                        {formik.errors.weight}
                      </div>
                    ) : null}
                  </div> */}
                  <div className="col-md-3 mb-3">
                    <label className="form-label">Linked Agent Detail</label>
                    <input
                      type="text"
                      className="form-control"
                      id="linked_agent"
                      name="linked_agent"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.linked_agent}
                      readOnly
                    />
                    {formik.touched.linked_agent &&
                    formik.errors.linked_agent ? (
                      <div className="text-danger">
                        {formik.errors.linked_agent}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-3 mb-3">
                          <label className="form-label">
                            Linked Installer Detail
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="linked_installer"
                            name="linked_installer"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.linked_installer}
                          />
                          {formik.touched.linked_installer &&
                          formik.errors.linked_installer ? (
                            <div className="text-danger">
                              {formik.errors.linked_installer}
                            </div>
                          ) : null}
                        </div>                  
                        
                        {/* <div className="col-md-12 mb-3">
                          <label className="form-label">Notes for the Installer </label>
                          <textarea
                            className="form-control"
                            id="installer_notes"
                            name="installer_notes"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.installer_notes}
                            rows={3}
                          ></textarea>
                          {formik.touched.installer_notes &&
                          formik.errors.installer_notes ? (
                            <div className="text-danger">
                              {formik.errors.installer_notes}
                            </div>
                          ) : null}
                        </div> */}
                        
                        <div className="col-md-3">
                          <label htmlFor="warehouseId">Select Warehouse</label>
                          <select
                            name="warehouseId"
                            id="warehouseId"
                            className="form-control"
                            value={formik.values.warehouseId}
                            onChange={(e) => {
                              const selectedId = e.target.value;
                              formik.setFieldValue("warehouseId", selectedId);

                              // Find the selected warehouse name
                              const selectedWarehouse = warehouses.find(
                                (w) => w.id === selectedId
                              );
                              setSelectedWarehouseName(
                                selectedWarehouse
                                  ? selectedWarehouse.label
                                  : null
                              );
                            }}
                            onBlur={formik.handleBlur}
                          >
                            <option value="">- Select -</option>
                            {warehouses !== null &&
                              warehouses.map((warehouse) => (
                                <option key={warehouse.id} value={warehouse.id}>
                                  {warehouse.label}
                                </option>
                              ))}
                          </select>
                          {formik.touched.warehouseId &&
                          formik.errors.warehouseId ? (
                            <div className="text-danger">
                              {formik.errors.warehouseId}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-3 mb-3">
                          <label htmlFor="on_hand">Bin Location</label>
                          <input
                            type="text"
                            name="bin_location"
                            id="bin_location"
                            className="form-control"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.bin_location}
                          />
                        </div>
                        <div className="col-md-3 mb-3">
                          <label className="form-label">
                            Rotational Product
                          </label>
                          <select
                            className="form-control"
                            id="is_rotational"
                            name="is_rotational"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.is_rotational}
                          >
                            <option value="">- Select -</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                          {formik.touched.is_rotational &&
                          formik.errors.is_rotational ? (
                            <div className="text-danger">
                              {formik.errors.is_rotational}
                            </div>
                          ) : null}
                        </div>
                        {/* <div className="col-md-3 mb-3">
                          <label className="form-label">
                            Rotational Type
                          </label>
                          <select
                            className="form-control"
                            id="rotational_type"
                            name="rotational_type"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.rotational_type}
                          >
                            <option value="">- Select -</option>
                            <option value="Rotational Type 1">Rotational Type 1</option>
                            <option value="Rotational Type 2">Rotational Type 2</option>
                          </select>
                          {formik.touched.rotational_type &&
                          formik.errors.rotational_type ? (
                            <div className="text-danger">
                              {formik.errors.rotational_type}
                            </div>
                          ) : null}
                        </div> */}
                        <div className="col-md-3 mb-3">
                    <label className="form-label">Quantity </label>
                    <input
                      type="number"
                      className="form-control"
                      id="qty"
                      name="qty"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.qty}
                    />
                    {formik.touched.qty &&
                    formik.errors.qty ? (
                      <div className="text-danger">
                        {formik.errors.qty}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-3 mb-3">
                          <label className="form-label">
                            Action
                          </label>
                          <select
                            className="form-control"
                            id="action"
                            name="action"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.action}
                          >
                            <option value="">- Select -</option>
                            <option value="accept">Accept</option>
                            <option value="reject">Reject</option>
                          </select>
                          {formik.touched.action &&
                          formik.errors.action ? (
                            <div className="text-danger">
                              {formik.errors.action}
                            </div>
                          ) : null}
                        </div>
                      </div>
 
            {/* Submit Button */}
            <div className="mt-4">
              <button
                className="btn btn-success w-100"
                type="submit"
                disabled={formSubmitting}
              >
                {formSubmitting ? "Submitting..." : "Submit"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignageEditIncomingProduct;
