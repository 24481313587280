import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";

interface Plan {
  id: string;
  planName: string;
  description: string;
}
interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface PermissionsProps {
  planPermissions: PlanPermission[];
  licenseTypes: LicenseType[];
  deletePlanPermissions: (id: string) => void;
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  default_status: boolean;
  default_volume: number;
  sub_modules: SubModule[];
}

type SubModule = {
  id: string;
  name: string;
  description: string;
  status: boolean;
  volume: number;
  used_volume: number;
};

interface ModulePermission {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  status: boolean;
  volume: number;
}
interface PlanPermission {
  id: string;
  planID: string;
  planName: string;
  licenseType: string;
  modules: ModulePermission[];
}

const Permissions: React.FC<PermissionsProps> = ({ planPermissions, licenseTypes, deletePlanPermissions }) => {
  const [selectedPlan, setSelectedPlan] = useState<string>("");
  const [selectedLicenseType, setSelectedLicenseType] = useState<string>("");
  const [modules, setModules] = useState<Module[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [error, setError] = useState<string>("");
  const [submissionError, setSubmissionError] = useState<string>("");
  const [submissionSuccess, setSubmissionSuccess] = useState<string>("");

  const token = localStorage.getItem("sessionToken");

  useEffect(() => {
      if(planPermissions!=null && planPermissions.length > 0) {
        const table = $("#transactions2").DataTable({
          responsive: false,
          destroy: true,
          lengthChange: false,
          ordering: false,
        });
  
        return () => {
          table.destroy();
        };
      }
    }, [planPermissions]);

  return (
    <div className="permissions" id="permissions">   
    <div className="text-end">
        <Link to="/superadmin/addPlanPermissions" className="btn btn-primary float-right">
          Add Plan Permissions
        </Link>
      </div>       
        <table className="table table-bordered dt-responsive nowrap table-striped align-middle" id="transactions2">
          <thead>
            <tr>
              <th>ID</th>
              <th>Plan Profile</th>
              <th>License Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {planPermissions && planPermissions.map((permission, index) => (
              <tr key={permission.id}>
                <td>{index + 1}</td>
                <td>{permission.planName}</td>
                <td>{permission.licenseType}</td>
                <td>
                    <Link
                        to={`/superadmin/edit-plan-permissions/${permission.id}`}
                        className="btn btn-warning waves-effect waves-light"
                    >
                        <i className="ri-pencil-fill align-bottom me-2"></i>
                    </Link>{" "}
                    <button
                    onClick={() =>
                      window.confirm("Are you sure you want to delete this permission?") &&
                      deletePlanPermissions(permission.id)
                    }
                        className="btn btn-danger waves-effect waves-light"
                    >
                        <i className="ri-delete-bin-fill align-bottom me-2"></i>
                    </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
    </div>
  );
};

export default Permissions;
