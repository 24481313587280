import React, { useState, useRef, useEffect } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./RealtorAddTeammember.css";
import { useNavigate, useParams } from "react-router-dom";

type Office = {
  id: string;
  officeName: string;
};

interface PasswordInputProps {
  formik: FormikProps<{ password: string }>;
}

type SelectOption = {
  value: string;
  label: string;
};

type Teammember = {
    id: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    phone1: string;
    phone2: string;
    email: string;
    assignedOffices: Office[]; // Array of office IDs
    permissions: string[]; // Array of permission strings
    photo?: string;
    statuss: string;
    licenseType: string;
  };
  
  
const SignageBrokerageEditTeammember = () => {
  const { firmId } = useParams<{ firmId: string }>();
  const { teammemberId } = useParams<{ teammemberId: string }>();
  const [teammember, setTeammember] = useState<Teammember | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();
  const [offices, setOffices] = useState<Office[] | null>(null);
  const [selectedOffices, setSelectedOffices] = useState<SelectOption[]>([]);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [validation, setValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    digit: false,
    specialChar: false,
  });

  useEffect(() => {
      const fetchTeammemberData = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${teammemberId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
  
          const data = await response.json();
          if (response.ok && data) {
            const assignedOffices = data.offices || [];
            const officeIds = assignedOffices.map((office: Office) => office.id);
            const officeNames = assignedOffices.map((office: Office) => office.officeName);

            setTeammember({
              id: data.id,
              firstName: data.firstName,
              lastName: data.lastName,
              jobTitle: data.jobTitle,
              phone1: data.phone1,
              phone2: data.phone2,
              email: data.email,
              assignedOffices: officeNames || [],
              permissions: data.accessLevel || [],
              photo: data.photo || "",
              statuss: data.status || "Active",
              licenseType: data.licenseType || "",
            });
          } else {
            throw new Error("Incomplete data");
          }
        } catch (error) {
          setError("Error fetching teammember data");
          console.error(error);
        }
      };
  
      if (teammemberId) fetchTeammemberData();
    }, [teammemberId, token]);

  useEffect(() => {
    const fetchOffices = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/offices`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setOffices(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOffices();
  }, [token]);

  useEffect(() => {
      if (teammember && offices!=null && offices?.length > 0) {
        const selected = teammember.assignedOffices
          .map((officce) => {
            const office = offices.find((o) => o.id === officce.id);
            return office ? { value: office.id, label: office.officeName } : null;
          })
          .filter((option): option is SelectOption => option !== null);
        setSelectedOffices(selected);
    
        // Set the Formik assignedOffices field with the IDs
        const assignedOfficeIds = selected.map((office) => office.value);
        formik.setFieldValue("assignedOffices", assignedOfficeIds);
      }
    }, [teammember, offices]);  

  const officesOptions =
    offices?.map((office) => ({
      value: office,
      label: office.officeName,
    })) || [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      jobTitle: teammember?.jobTitle || "",
      firstName: teammember?.firstName || "",
      lastName: teammember?.lastName || "",
      phone: teammember?.phone1 || "",
      phone2: teammember?.phone2 || "",
      email: teammember?.email || "",
      assignedOffices: teammember?.assignedOffices || [],
      statuss: teammember?.statuss || "active",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name can only contain letters")
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name can only contain letters")
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      assignedOffices: Yup.array()
        // .of(Yup.string().required("Office is required"))
        // .min(1, "At least one office must be assigned")
        .required("Assign Offices is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      const assignedOfficeIds = new Array();
      for(const item of values.assignedOffices)
      {
        assignedOfficeIds.push(item);
      }

      const assignedOffices = new Array();
      for (const oid of assignedOfficeIds) {
        console.log(oid); 
        const assignedOffice = offices?.find((office) => office.id === oid);
        assignedOffices.push(assignedOffice);
        console.log('assignedOffices:', assignedOffices);
      } 

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${teammemberId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: values.email,
              userType: "team_member",
              firstName: values.firstName,
              lastName: values.lastName,
              jobTitle: values.jobTitle,
              phone1: values.phone,
              phone2: values.phone2,
              licenseType: "BROKERAGE_TEAM_MEMBER",
              status: values.statuss,
              offices: assignedOffices,
              accessLevel: [],
              created_by: {
                    id: firmId,
                    name: "Brokerage Firm",
                },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        toast.success("Teammember added successfully!");
        console.log("Form submission successful:", result);
        navigate(`/signage/brokerageFirm/${firmId}`, {
          state: { successMessage: "Teammember added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Teammember.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Team Member </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-1">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Role{" "}                            
                          </label>
                          <select
                            className="form-control"
                            id="jobTitle"
                            name="jobTitle"
                            required
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                          >
                            <option value="">- Select -</option>
                            <option value="Agent">Agent</option>
                            <option value="Manager">Manager</option>
                            <option value="Admin">Admin</option>
                            <option value="VP">VP</option>
                            <option value="Marketing">Marketing</option>
                            <option value="Legal">Legal</option>
                            <option value="Custom">Custom</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select role
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone Number <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Secondary Phone Number 
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone2"
                            name="phone2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone2}
                          />
                          {formik.touched.phone2 && formik.errors.phone2 ? (
                            <div className="text-danger">
                              {formik.errors.phone2}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            required
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Assign Offices{" "}
                          </label>
                          <Select
                            id="assignedOffices"
                            name="assignedOffices"
                            options={officesOptions}
                            isMulti
                            closeMenuOnSelect={false}
                            value={officesOptions.filter((option) =>
                              formik.values.assignedOffices.includes(option.value)
                            )}
                            onChange={(selectedOptions) =>
                              formik.setFieldValue(
                                "assignedOffices",
                                selectedOptions
                                  ? selectedOptions.map((option) => option.value)
                                  : []
                              )
                            }
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.assignedOffices &&
                          formik.errors.assignedOffices ? (
                            <div className="text-danger">
                              {/* {formik.errors.assignedOffices} */}
                            </div>
                          ) : null}
                        </div>
                        
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            required
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                          >
                            <option value="">- Select -</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select status
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageBrokerageEditTeammember;
