import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

interface Plan {
  id: string;
  planName: string;
  description: string;
}

const SuperAdminEditProfile = () => {
  // const token =
    // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQHJlZWx0eS5jb20iLCJleHAiOjE3Mjc5NzIzMzEsInVzZXJUeXBlIjoiIn0.cFNnl3zUN32x3X_Ysj7bttI-0DpTVgLdZmY8V1NI7Xk";
  const token = localStorage.getItem('sessionToken');
  console.log(token);

  const { planId } = useParams<{ planId: string }>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      planName: "",
      description: "",
    },
    validationSchema: Yup.object({
      planName: Yup.string()
        .min(2, "Plan name must be at least 2 characters long")
        .max(50, "Plan name cannot be longer than 50 characters")
        .required("Plan name is required"),
    }),
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/plan-profile/${planId}`, {
          method: "PUT", // Use PUT or PATCH for updates
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: planId,
            planName: values.planName,
            description: values.description,
          }),
        });

        // console.log(response.body);

        if (!response.ok) {
          throw new Error("Failed to submit form");
        }

        const data = await response.json();
        console.log("Form successfully submitted:", data);
        navigate('/superadmin/profiles', { state: { successMessage: "Plan profile updated successfully!" } });
        setLoading(false);
      } catch (error) {
        console.error("Error submitting form:", error);
        setError("Failed to submit the form");
        setLoading(false);
      }
    },
  });

  // Fetch the plan data based on planId
  useEffect(() => {
    const fetchPlanData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/plan-profile/${planId}`, {
          method: "GET", // Use GET for fetching
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch plan data");
        }

        const data = await response.json();

        // Update formik values with the fetched data
        formik.setValues({
          planName: data.plans[0].planName || "",
          description: data.plans[0].description || "",
        });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching plan data:", error);
        setError("Failed to load the plan data");
        setLoading(false);
      }
    };

    if (planId) {
      fetchPlanData();
    }
  }, [planId]);

  return (
    <div className="roles">
      <div className="card">
        <div className="card-body">
          <h2>Edit Plan Profile</h2>

          {loading && <p>Loading...</p>}
          {error && <p className="text-danger">{error}</p>}

          {!loading && !error && (
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Plan Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="planName"
                            name="planName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.planName}
                            required
                          />
                          {formik.touched.planName && formik.errors.planName ? (
                            <div className="text-danger">{formik.errors.planName}</div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Description</label>
                          <input
                            type="text"
                            className="form-control"
                            id="description"
                            name="description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mt-4">
                            <button className="btn btn-success w-100" type="submit" disabled={loading}>
                              {loading ? "Submitting..." : "Submit"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminEditProfile;
