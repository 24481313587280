import React, { useState, useRef, useEffect } from 'react';
import { useLoadScript } from '@react-google-maps/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

// const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

type CountyList = {
  county: string;
  zipList: string;
};

type GeoLocation = {
  id: string;
  country: string;
  countryCode: string;
  state: string;
  stateCode: string;
  countyZipList: CountyList[];  
};

type Country = {
  _id: string;
  name: string;
  code: string;
};

const SignageAddGeoLocation = () => {
  const [countries, setCountries] = useState<Country[]>([]);
  const [counties, setCounties] = useState([{ county: '', zipcode: '' }]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [states, setStates] = useState<{ name: string; code: string }[]>([]);
  // const { isLoaded } = useLoadScript({
  //   googleMapsApiKey: apiKey,
  //   libraries: ['places'],
  // });
  
  const navigate = useNavigate();

  useEffect(() => {
        const fetchCountries = async () => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/location/countries`,
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
              }
            );
            const data = await response.json();
            setCountries(data);
          } catch (err) {
            setError(
              err instanceof Error ? err.message : "An unknown error occurred"
            );
          } finally {
            setLoading(false);
          }
        };
    
        fetchCountries();
      }, [token]);

  const formik = useFormik({
    initialValues: {
      country: '',      
      countryCode: 'US',       
      state: '', 
      stateCode: 'STATE',
    },
    validationSchema: Yup.object({
      country: Yup.string()
        .min(2, 'Country name must be at least 2 characters long')
        .max(50, 'Country name cannot be longer than 50 characters')
        .required('Country Name is required'),
      countryCode: Yup.string()
        .required('Country code is required'), 
      state: Yup.string()
        .min(2, 'State must be at least 2 characters long')
        .max(50, 'State cannot be longer than 50 characters')
        .required('State is required'),
      stateCode: Yup.string()
        .required('State code is required'),       
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      console.log(values);
      console.log(counties);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/geo-locations`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            country: values.country,
            countryCode: values.countryCode,
            state: values.state,
            stateCode: values.stateCode,
            countyZipList: counties
          }),
        });

        if (!response.ok) throw new Error("Failed to save data");

        toast.success("GeoLocation added successfully!");
        navigate("/signage/geolocations", {
          state: { successMessage: "GeoLocation added successfully!" },
        });
      } catch (err) {
        toast.error("Failed to add GeoLocation.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  const addCountyRow = () => {
    setCounties([...counties, { county: '', zipcode: '' }]);
  };

  const handleCountyChange = (
    index: number,
    field: keyof { county: string; zipcode: string },
    value: string
  ) => {
    const updatedCounties = [...counties];
    updatedCounties[index][field] = value; // Safe because `field` is keyof the object
    setCounties(updatedCounties);
  };
  const handleCountryChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
  // const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const country = countries.find((country) => country.name === event.target.value);
    formik.setFieldValue('countryCode', country?.code || '');
    formik.setFieldValue('country', event.target.value);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/location/states/country/${country?._id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("Fetched States:", data); // Log the response
      setStates(Array.isArray(data) ? data : []); // Ensure `states` is always an array
    } catch (err) {
      setStates([]); // Set an empty array as fallback
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
      }
  };

  // if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Add Geo Location</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <label className="form-label">Country</label>
                <select
                  className="form-control"
                  name="country"
                  id="country"
                  onChange={handleCountryChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
                >
                  <option value="">-Select-</option>
                  {countries.map((country, index) => (
                    <option key={index} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                  </select>
                {formik.touched.country && formik.errors.country && (
                  <div className="text-danger">{formik.errors.country}</div>
                )}
              </div>

              <div className="col-md-6 mb-3">
                <label className="form-label">State</label>
                <select
                  className="form-control"
                  name="state"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.state}
                >
                  <option value="">-Select-</option>
                  {states?.map((state, index) => (
                    <option key={index} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                  </select>
                {formik.touched.state && formik.errors.state && (
                  <div className="text-danger">{formik.errors.state}</div>
                )}
              </div>
            </div>

            <div className="row">
              {counties.map((county, index) => (
                <React.Fragment key={index}>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">County</label>
                    <input
                      type="text"
                      className="form-control"
                      value={county.county}
                      onChange={(e) => handleCountyChange(index, 'county', e.target.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label className="form-label">Zip Code</label>
                    <input
                      type="text"
                      className="form-control"
                      value={county.zipcode}
                      onChange={(e) => handleCountyChange(index, 'zipcode', e.target.value)}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>

            <button
              type="button"
              className="btn btn-primary"
              onClick={addCountyRow}
            >
              Add County
            </button>

            <input type="hidden" name='countryCode' id='countryCode' value={formik.values.countryCode} />
            <input type="hidden" name='stateCode' id='stateCode' value={formik.values.stateCode} />
                        
            <button
              type="submit"
              className="btn btn-success w-100 mt-4"
              disabled={formSubmitting}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignageAddGeoLocation;
