import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useParams } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface Agent {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  city: string;
}

type Group = {
  id: string;
  name: string;
  status: string;
  agents: Agent[];
};

const RealtorAddGroupAgent = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const { groupId } = useParams<{ groupId: string }>();
  const [selectedAgentId, setSelectedAgentId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setGroups(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchGroups();
  }, [token]);

  useEffect(() => {
    const fetchAgents = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users-search?userType=agent`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch agents");
        }

        const data: Agent[] = await response.json();
        console.log(data);
        setAgents(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAgents();
  }, [token]);

  const formik = useFormik({
    initialValues: {
      agentId: "",
    },
    validationSchema: Yup.object({
      agentId: Yup.string().required("Agent is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
    
      const selectedAgentId = values.agentId; // Use values.agentId directly
      const selectedAgent = agents.find(
        (agent) => agent.id === selectedAgentId
      );
      console.log("Selected Agent:", selectedAgent);
      const agentName = `${selectedAgent?.firstName} ${selectedAgent?.lastName}`;
    
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/groups/${groupId}/add-agent`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              id: selectedAgent?.id || "",
              name: agentName || "",
              email: selectedAgent?.email || "",
              phone: selectedAgent?.phone1 || "",
              city: selectedAgent?.city || "",
            }),
          }
        );
    
        const result = await response.json();
        if (!response.ok) {
          throw new Error(result.error);
          // toast.error(result.error);
          // console.log('Response:',result.error);
        }    
        
        toast.success("Agent added successfully!");
        console.log("Form submission successful:", result);
        navigate(`/realtor/view-group/${groupId}`, {
          state: { successMessage: "Agent added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error(err instanceof Error ? err.message: "Failed to add!");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Add Group Agent</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Agent Name <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="agentId"
                            name="agentId"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.agentId}
                            required
                          >
                            <option value="">-Select-</option>
                            {agents &&
                              agents.map((agent) => (
                                <option key={agent.id} value={agent.id}>
                                  {agent.firstName} {agent.lastName}
                                </option>
                              ))}
                          </select>
                          {formik.touched.agentId && formik.errors.agentId ? (
                            <div className="text-danger">
                              {formik.errors.agentId}
                            </div>
                          ) : null}
                        </div>
                      

                        <div className="col-md-6 mt-4">
                          
                        <button className="btn btn-success w-100" type="submit">
                          Submit
                        </button>
                      </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorAddGroupAgent;
