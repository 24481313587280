import React, { useState, useEffect, useRef } from 'react';
import { useLoadScript, Autocomplete } from '@react-google-maps/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom'; // Import useParams to get officeId from URL
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

type Office = {
  id: number;
  officeName: string;
//   officeType: string;  
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  phone: string;
};

const SignageBrokerageEditOffice = () => {
    const { firmId } = useParams<{ firmId: string }>();
  const { officeId } = useParams<{ officeId: string }>(); // Get officeId from URL params
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries: ['places'],
  });

  const [office, setOffice] = useState<Office | null>(null); // State to hold the office data
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  
  // Fetch office details when officeId changes
  useEffect(() => {
    const fetchOfficeData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/offices/${officeId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setOffice({
          id: data.id,
          officeName: data.officeName,
        //   officeType: data.officeType,
          streetAddress1: data.officeAddress.addressLine1,
          streetAddress2: data.officeAddress.addressLine2,
          city: data.officeAddress.city,
          state: data.officeAddress.state,
          country: data.officeAddress.country,
          zipcode: data.officeAddress.zip,
          phone: data.officeAddress.phone,
        });

      } catch (error) {
        console.error('Error fetching office data:', error);
      }
    };

    if (officeId) {
      fetchOfficeData();
    }
  }, [officeId]);

  // Initialize formik
  const formik = useFormik({
    initialValues: {
      officeName: office?.officeName || '',
    //   officeType: office?.officeType || '',
      officePhone: office?.phone || '',
      streetAddress1: office?.streetAddress1 || '',
      streetAddress2: office?.streetAddress2 || '',
      city: office?.city || '',
      state: office?.state || '',
      zipcode: office?.zipcode || '',
      country: office?.country || '',
    },
    enableReinitialize: true, // Enable reinitialization when office data is fetched
    validationSchema: Yup.object({
      officeName: Yup.string()
        .min(2, 'Office name must be at least 2 characters long')
        .max(50, 'Office name cannot be longer than 50 characters')
        .required('Office Name is required'),
      officeType: Yup.string()
        .min(2, 'Office type must be at least 2 characters long')
        .max(50, 'Office type cannot be longer than 50 characters'),
      // contactName: Yup.string()
      //   .min(2, 'Contact Name must be at least 2 characters long')
      //   .max(50, 'Contact Name cannot be longer than 50 characters'),
      // contactEmail: Yup.string().email('Invalid email address'),
      // contactPhone: Yup.string().matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
      // officePhone: Yup.string().matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
      streetAddress1: Yup.string()
        .min(2, 'Street address must be at least 8 characters long')
        .max(50, 'Street address must be max 16 characters long'),
      city: Yup.string()
        .min(2, 'City must be at least 2 characters long')
        .max(50, 'City cannot be longer than 50 characters')
        .required('City is required'),
      state: Yup.string()
        .min(2, 'State must be at least 2 characters long')
        .max(50, 'State cannot be longer than 50 characters')
        .required('State is required'),
      zipcode: Yup.string().required('Zip code is required'),
      country: Yup.string()
        .min(2, 'Country must be at least 2 characters long')
        .max(50, 'Country cannot be longer than 50 characters'),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/offices/${officeId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              officeName: values.officeName || "",
            //   officeType: values.officeType || "",
              officeAddress: 
                {
                  addressLine1: values.streetAddress1 || "",
                  addressLine2: values.streetAddress2 || "",
                  city: values.city || "",
                  state: values.state || "",
                  country: values.country || "",
                  zip: values.zipcode || "",
                  cityCode: '',
                  stateCode: '',
                  countryCode: 'US',
                  latitude: '',
                  longitude: '',
                  phone: values.officePhone || "",
                }
              
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        toast.success("Office updated successfully!");
        console.log("Form submission successful:", result);
        navigate(`/signage/brokerageFirm/${firmId}`, {
            state: { successMessage: "Office added successfully!" },
          });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update Office.");
      } finally {
        setFormSubmitting(false);
      }
    },
  }); 

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const addressComponents = place.address_components;

      if (addressComponents) {
        const getComponent = (type: string) => {
          const component = addressComponents.find((c) => c.types.includes(type));
          return component ? component.long_name : '';
        };

        const streetNumber = getComponent('street_number');
        const route = getComponent('route');
        const city = getComponent('locality') || getComponent('administrative_area_level_2');
        const state = getComponent('administrative_area_level_1');
        const postalCode = getComponent('postal_code');
        const street = `${streetNumber} ${route}`.trim();
        const country = getComponent('country');
        
        // Update form values
        formik.setFieldValue('streetAddress1', street);
        formik.setFieldValue('city', city);
        formik.setFieldValue('state', state);
        formik.setFieldValue('zipcode', postalCode);
        formik.setFieldValue('country', country);
      }
    }
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Office </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-0">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Office Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="officeName"
                            name="officeName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.officeName}
                            required
                          />
                          {formik.touched.officeName && formik.errors.officeName ? (
                            <div className="text-danger">{formik.errors.officeName}</div>
                          ) : null}
                        </div>
                        {/* <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Office Type 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="officeType"
                              name="officeType"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.officeType}                              
                            />
                            {formik.touched.officeType && formik.errors.officeType ? (
                              <div className="text-danger">{formik.errors.officeType}</div>
                            ) : null}
                          </div> */}
                          {/* <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Contact Person Name 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contactName"
                              name="contactName"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.contactName}                              
                            />
                            {formik.touched.contactName && formik.errors.contactName ? (
                              <div className="text-danger">{formik.errors.contactName}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                             Contact Person Email 
                            </label>
                            <input
                              type="contactEmail"
                              className="form-control"
                              id="contactEmail"
                              name="contactEmail"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.contactEmail}
                            />
                            {formik.touched.contactEmail && formik.errors.contactEmail ? (
                              <div className="text-danger">{formik.errors.contactEmail}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Contact Person Phone 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="contactPhone"
                              name="contactPhone"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.contactPhone}
                              required
                            />
                            {formik.touched.contactPhone && formik.errors.contactPhone ? (
                              <div className="text-danger">{formik.errors.contactPhone}</div>
                            ) : null}
                          </div>                           */}
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Office Phone Number 
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="officePhone"
                              name="officePhone"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.officePhone}
                              required
                            />
                            {formik.touched.officePhone && formik.errors.officePhone ? (
                              <div className="text-danger">{formik.errors.officePhone}</div>
                            ) : null}
                          </div>                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Street Address 1 <span className="text-danger">*</span>
                            </label>
                            <Autocomplete
                              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                              onPlaceChanged={handlePlaceChanged}
                            >
                            <input
                              type="text"
                              className="form-control"
                              id="streetAddress1"
                              name="streetAddress1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress1}
                              required
                            />
                            </Autocomplete>
                            {formik.touched.streetAddress1 && formik.errors.streetAddress1 ? (
                              <div className="text-danger">{formik.errors.streetAddress1}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Street Address 2{" "}
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="streetAddress2"
                              name="streetAddress2"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress2}
                            />
                            <div className="invalid-feedback">
                              Please enter Address line 2
                            </div>
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              City <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="city"
                              name="city"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.city}
                              required
                            />
                            {formik.touched.city && formik.errors.city ? (
                              <div className="text-danger">{formik.errors.city}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              State <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                              required
                            />
                            {formik.touched.state && formik.errors.state ? (
                              <div className="text-danger">{formik.errors.state}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Zip code <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="zipcode"
                              name="zipcode"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.zipcode}
                              required
                            />
                            {formik.touched.zipcode && formik.errors.zipcode ? (
                              <div className="text-danger">{formik.errors.zipcode}</div>
                            ) : null}
                          </div>                          
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Country 
                            </label>                            
                            <input
                              type="text"
                              className="form-control"
                              id="country"
                              name="country"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.country}                              
                            />                           
                            {formik.touched.country && formik.errors.country ? (
                              <div className="text-danger">{formik.errors.country}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="mt-4">
                          <button className="btn btn-success w-100" type="submit">
                            Update
                          </button>
                        </div>
                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageBrokerageEditOffice;
