import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

interface Plan {
  id: string;
  planName: string;
  description: string;
}
interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  status: boolean;
  volume: number;
  sub_modules: SubModule[];
}

type SubModule = {
  id: string;
  name: string;
  value: string;
  description: string;
  status: boolean;
  volume: number;
  used_volume: number;
};

const SuperAdminAddPlanPermissions = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [modules, setModules] = useState<Module[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = localStorage.getItem("sessionToken");
  const [updatedModulesWithSubModules, setUpdatedModulesWithSubModules] =
    useState<Module[]>([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/plan-profiles`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setPlans(data.plans);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/license-types`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, []);

  const formik = useFormik({
    initialValues: {
      selectedPlan: "",
      selectedLicenseType: "",
      modules: [] as Module[],
      agentsPlan: "",
      brokeragePlan: "",
    },
    validationSchema: Yup.object({
      selectedPlan: Yup.string().required("Plan is required"),
      selectedLicenseType: Yup.string().required("License type is required"),
    }),
    onSubmit: async (values) => {
      setError("");
      const selectedPlanData = plans.find(
        (plan) => plan.planName === values.selectedPlan
      );
      const selectedAgentPlanData = plans.find(
        (plan) => plan.planName === values.agentsPlan
      );
      const selectedBrokeragePlanData = plans.find(
        (plan) => plan.planName === values.brokeragePlan
      );
      if (!selectedPlanData) {
        setError("Selected plan not found.");
        return;
      }
    
      const requestData = {
        planID: selectedPlanData.id,
        planName: values.selectedPlan,
        licenseType: values.selectedLicenseType,
        modules: modules.map((module) => ({
          ...module,
          sub_modules: module.sub_modules
            ? module.sub_modules.map((smodule) => {
                if (module.name === "Agents" && smodule.name === "Plan") {
                  return {
                    ...smodule,
                    value: selectedAgentPlanData?.planName,
                    value_id: selectedAgentPlanData?.id,
                    status: true,
                  };
                } else if (
                  module.name === "Brokerage Firms" &&
                  smodule.name === "Plan"
                ) {
                  return {
                    ...smodule,
                    value: selectedBrokeragePlanData?.planName,
                    value_id: selectedBrokeragePlanData?.id,
                    status: true,
                  };
                }
                return smodule;
              })
            : [],
        })),
      };
    
      console.log("Request Data:", requestData);
    
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/plan-permissions`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );
        if (!response.ok) throw new Error("Failed to submit permissions");
    
        navigate("/superadmin/profiles", {
          state: { successMessage: "Permissions added successfully!" },
        });
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      }
    },    
  });

  useEffect(() => {
    const fetchModules = async () => {
      if (!formik.values.selectedLicenseType) return;

      setLoadingModules(true);
      setError("");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/modules/license-type/${formik.values.selectedLicenseType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) throw new Error("Failed to fetch modules");

        const data = await response.json();
        setModules(data || []);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoadingModules(false);
      }
    };

    fetchModules();
  }, [formik.values.selectedLicenseType]);

  return (
    <div className="permissions">
      <div className="card">
        <div className="card-body">
          <h2>Add Plan Permissions</h2>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="plan-select">Select Plan</label>
                  <select
                    name="selectedPlan"
                    className="form-control mb-3"
                    id="plan-select"
                    value={formik.values.selectedPlan}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select Plan</option>
                    {plans.map((plan) => (
                      <option key={plan.id} value={plan.planName}>
                        {plan.planName}
                      </option>
                    ))}
                  </select>
                  {formik.touched.selectedPlan &&
                    formik.errors.selectedPlan && (
                      <p className="text-danger">
                        {formik.errors.selectedPlan}
                      </p>
                    )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="license-select">Select License Type</label>
                  <select
                    name="selectedLicenseType"
                    className="form-control mb-3"
                    id="license-select"
                    value={formik.values.selectedLicenseType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select License Type</option>
                    {licenseTypes.map((license) => (
                      <option key={license.id} value={license.code}>
                        {license.name}
                      </option>
                    ))}
                  </select>
                  {formik.touched.selectedLicenseType &&
                    formik.errors.selectedLicenseType && (
                      <p className="text-danger">
                        {formik.errors.selectedLicenseType}
                      </p>
                    )}
                </div>
              </div>
            </div>

            {loadingModules ? (
              <p>Loading modules...</p>
            ) : error ? (
              <p className="text-danger">Error: {error}</p>
            ) : modules.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Module</th>
                    <th>Yes/No</th>
                    <th>Volume</th>
                    <th>{" "}</th>
                  </tr>
                </thead>
                <tbody>
                  {modules.map((module, index) => (
                    <tr key={module.id}>
                      <td>{module.name}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={module.status}
                          onChange={(e) =>
                            setModules((prev) =>
                              prev.map((m, i) =>
                                i === index
                                  ? { ...m, status: e.target.checked } // Update the `status` field
                                  : m
                              )
                            )
                          }
                        />
                      </td>

                      <td>
                        <input
                          type="number"
                          value={module.volume}
                          onChange={(e) =>
                            setModules((prev) =>
                              prev.map((m, i) =>
                                i === index
                                  ? { ...m, volume: +e.target.value }
                                  : m
                              )
                            )
                          }
                          style={{ width: "100px" }}
                        />
                      </td>
                      <td>
                        {module.name == "Agents" &&
                          Array.isArray(module.sub_modules) &&
                          module.sub_modules.map((smodule, index) =>
                            smodule.name === "Plan" ? (
                              <div className="form-group" key={index}>
                                <label>{smodule.name}</label>
                                <select
                                  name="agentsPlan"
                                  id="agentsPlan"
                                  className="form-control"
                                  value={formik.values.agentsPlan}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                >
                                  <option value="">-Select-</option>
                                  {Array.isArray(plans) &&
                                    plans.map((plan, index) => (
                                      <option value={plan.planName} key={index}>
                                        {plan.planName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            ) : null
                          )}

                        {module.name == "Brokerage Firms" &&
                          Array.isArray(module.sub_modules) &&
                          module.sub_modules.map((smodule, index) =>
                            smodule.name === "Plan" ? (
                              <div className="form-group" key={index}>
                                <label>{smodule.name}</label>
                                <select
                                  name="brokeragePlan"
                                  id="brokeragePlan"
                                  className="form-control"
                                  value={formik.values.brokeragePlan}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                >
                                  <option value="">-Select-</option>
                                  {Array.isArray(plans) &&
                                    plans.map((plan, index) => (
                                      <option value={plan.planName} key={index}>
                                        {plan.planName}
                                      </option>
                                    ))}
                                </select>
                              </div>
                            ) : null
                          )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No modules available for this license type.</p>
            )}

            <div className="row mt-3 justify-content-center">
              <div className="col-md-3">
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddPlanPermissions;
