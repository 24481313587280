import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "../../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  name: string;
  sku: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  package_dimensions: Dimensions;
  threshold_qty: number;
  image: Image | null;
  status: string;
  is_rotational: boolean;
  lined_mode: string;
  linked_installer: {
    id: string;
    name: string;
  }
  product_type: string;
  description: string;
  notes: string;
};

type Image = {
  url: string;
};

type ProductObj = {
  product_id: string;
  signage_company_id: string;
  product: Product;
  created_by: {
    id: string;
    name: string;
  };
};

const SignageStandardInventory = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [productobjects, setProductObjects] = useState<ProductObj[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/signage/${userId}/inventory/products`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        console.log(data);
        // setProducts(data);
        setProductObjects(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token]);

  // if (products?.length === 0) {
  //   return <div>Loading...</div>;
  // }

  useEffect(() => {
    if (productobjects!=null && productobjects.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [productobjects]);

  const handleDelete = async (productId: string) => {
    setDeleting(productId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/signage/${userId}/inventory/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the tax group.");
      }

      toast.success("Product deleted successfully!");
      // Update the state by removing the deleted license
      setProductObjects((prev) =>
        prev.filter((product) => product.product_id !== productId)
      );
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the tax group.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Products </h2>
          <div className="row justify-content-end">
            <div className="col-md-2">
            <a href="/signage/addProduct" className="btn btn-primary float-right">
              Add Simple Product
            </a>
            </div>
            <div className="col-md-2">
            <a href="/signage/addVariableProduct" className="btn btn-primary float-right">
              Add Variable Product
            </a>
            </div>
            
            
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" />
                          </th>
                          <th>S.No.</th>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Category</th>
                          <th>Qty</th>
                          <th>Status</th>
                          {/* <th>Installer Status</th> */}
                          <th>Condition</th>
                          <th>Dimensions</th>
                          <th>Weight</th>
                          {/* <th>Linked Installer</th> */}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {productobjects != null &&
                          productobjects.map((productobj, index) => (
                            <tr key={index}>
                              <td>
                                <input type="checkbox" />
                              </td>
                              <td>{index + 1}</td>
                              <td>
                                {productobj.product.image?.url ? (
                                  <img
                                    src={`https://dev.reelty.app/${productobj.product.image.url}`}
                                    height={50}
                                    alt="Product"
                                  />
                                ) : (
                                  <span>No image</span>
                                )}
                              </td>

                              <td>{productobj?.product?.name}</td>
                              <td>{productobj?.product?.category}</td>
                              <td>{productobj?.product?.brand}</td>
                              <td>{productobj?.product.status}</td>
                              {/* <td>{product.status}</td> */}
                              <td>{productobj?.product?.item_condition}</td>
                              <td>
                                {productobj?.product?.package_dimensions?.length} x{" "}
                                {productobj?.product?.package_dimensions?.height} x{" "}
                                {productobj?.product?.package_dimensions?.width}
                              </td>
                              <td>
                                {productobj?.product?.package_dimensions?.weight}
                              </td>
                              {/* <td></td> */}
                              <td>
                                {(productobj?.product?.product_type==='variable')?(
                                  <Link
                                  to={`/signage/viewVariableProduct/${productobj.product_id}`}
                                  className="btn btn-info waves-effect waves-light me-2"
                                >
                                  View
                                </Link>
                                ):(
                                  <Link
                                  to={`/signage/viewProduct/${productobj.product_id}`}
                                  className="btn btn-info waves-effect waves-light me-2"
                                >
                                  View
                                </Link>
                                )}

                                {(productobj?.product?.product_type==='variable')?(
                                  <Link
                                  to={`/signage/editVariableProduct/${productobj.product_id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>
                                ):(
                                  <Link
                                  to={`/signage/editProduct/${productobj.product_id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>
                                )}                                

                                <button
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this product?"
                                    ) &&
                                    handleDelete(productobj.product_id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageStandardInventory;
