import React, { useState, useRef, useEffect } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

type State = {
    _id: string;
    countryId: string;
    countryName: string;
    name: string;
    code: string;
  };

const SuperAdminAddCity = () => {
    const { stateId } = useParams<{ stateId: string }>();
  const [states, setStates] = useState<State[]>([]);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("sessionToken");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      const fetchStates = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/location/states`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();
          console.log(data);
          setStates(data);
        } catch (err) {
          setError(
            err instanceof Error ? err.message : "An unknown error occurred"
          );
        } finally {
          setLoading(false);
        }
      };
  
      fetchStates();
    }, [token]);

  const formik = useFormik<{
    stateId: string;
    name: string;
    code: string;
  }>({
    initialValues: {
      stateId: "",
      name: "",
      code: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      code: Yup.string().required("Code is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/location/cities`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            stateId: values.stateId,
            stateName: '',
            name: values.name,
            code: values.code,
          }),
        });
    
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Failed to create city");
        }
    
        navigate(`/superadmin/cities/${stateId}`, {
          state: { successMessage: "City added successfully!" },
        });
        toast.success("City added successfully!");
      } catch (error: any) {
        console.error(error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setFormSubmitting(false);
      }
    }        
  });

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Add City</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            {/* Name Field */}
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                State <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="stateId"
                                name="stateId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.stateId}
                              >
                                <option value="">-Select-</option>
                                {states!=null && states.map((state,index)=>(
                                    <option value={state._id}>{state.name}</option>
                                ))}
                                </select>
                              {formik.touched.stateId && formik.errors.stateId ? (
                                <div className="invalid-feedback">
                                  {formik.errors.stateId}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                City Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="invalid-feedback">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                City Code <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="code"
                                name="code"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.code}
                              />
                              {formik.touched.code && formik.errors.code ? (
                                <div className="invalid-feedback">
                                  {formik.errors.code}
                                </div>
                              ) : null}
                            </div>                            
                          </div>
                        </div>
                       
                      </div>

                      {/* Submit Button */}
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminAddCity;
