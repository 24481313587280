import React, { useState, useRef, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AgentAddCategory = ()=>{
  const [preview, setPreview] = useState<string | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0); // Track upload progress
  const navigate = useNavigate();
  const token = localStorage.getItem("sessionToken");

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "categories");
  
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${process.env.REACT_APP_API_URL}/images/upload`, true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
  
      xhr.onload = () => {
        console.log('xhr:',xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log('upload response:', response);
  
          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };
  
      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  const formik = useFormik<{
    name: string;
    image: File | null; // Allow File objects or null
    statuss: string;
  }>({
    initialValues: {
      name: "",
      image: null, // Match the type (File or null)
      statuss: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Name must be at least 2 characters long')
        .max(50, 'Name cannot be longer than 50 characters')
        .required('Name is required'),     
     statuss: Yup.string()        
        .required('Status is required'), 
    }),
    onSubmit: async (values) => {
      if (!values.image) {
        toast.error("Please select an image to upload.");
        return;
      }
    
      try {
        // 1. Upload the image and get the full image URL
        const imageUrl = await uploadFile(values.image);
        console.log('image url',imageUrl);
    
        // 2. Submit the brand details
        const response = await fetch(`${process.env.REACT_APP_API_URL}/categories`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: values.name,
            image: imageUrl,
            status: values.statuss === "Active",
          }),
        });
    
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Failed to create brand");
        }
    
        navigate("/agent/categories", {
          state: { successMessage: "Category added successfully!" },
        });
        toast.success("Brand added successfully!");
      } catch (error: any) {
        console.error(error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setFormSubmitting(false);
        setUploadProgress(0);
      }
    }        
  });

  // Handle file selection and integrate with Formik
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      formik.setFieldValue("image", file); // Save the file object in Formik
      setPreview(URL.createObjectURL(file)); // Set preview for display
    }
  };

    return(
        <div className="teammember">
            <div className="card">
                <div className="card-body">
                    <h2>Add Category </h2>
                    
            <div className="row justify-content-center">
              <div className="col-md-12 col-lg-12 col-xl-12">
                <div className="card mt-4 card-bg-fill">
                  <div className="card-body p-4">                    
                    <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            {/* Name Field */}
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.touched.name && formik.errors.name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="invalid-feedback">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            {/* Status Field */}
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                                className={`form-control ${
                                  formik.touched.statuss &&
                                  formik.errors.statuss
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="invalid-feedback">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            {/* Brand Image Field */}
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Category Image{" "}
                              </label>
                              <input
                                type="file"
                                accept="image/*"
                                className={`form-control ${
                                  formik.touched.image && formik.errors.image
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="image"
                                name="image"
                                aria-label="Upload brand image"
                                onChange={handleFileChange}
                              />

                              {formik.touched.image && formik.errors.image ? (
                                <div className="invalid-feedback">
                                  {formik.errors.image}
                                </div>
                              ) : null}

                              {preview && (
                                <div style={styles.preview}>
                                  <img
                                    src={preview}
                                    alt="Preview"
                                    style={styles.image}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>

                    {/* Upload Progress Indicator */}
                    {formSubmitting && (
                      <div className="mt-3">
                        <div className="progress">
                          <div
                            className="progress-bar progress-bar-striped progress-bar-animated"
                            role="progressbar"
                            style={{ width: `${uploadProgress}%` }}
                            aria-valuenow={uploadProgress}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          >
                            {uploadProgress}%
                          </div>
                        </div>
                      </div>
                    )}
                    </div>
                  </div>
                </div>                
              </div>
            </div>
                </div>
                
            </div>
        </div>
    );
}

const styles = {
  preview: {
    marginTop: "10px",
    textAlign: "center" as const,
  },
  image: {
    width: "100px",
    height: "100px",
    borderRadius: "5px",
  },
};

export default AgentAddCategory;