import React, { useState, useRef, useEffect } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AgentAddAddress = () => {
  const [preview, setPreview] = useState<string | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0); // Track upload progress
  const navigate = useNavigate();
  const token = localStorage.getItem("sessionToken");

  const formik = useFormik<{
    label_of_address: string;
    firstName: string;
    lastName: string;
    company: string;
    addressLine1: string;
    addressLine2: string;
    country: string;
    county: string;
    city: string;
    state: string;
    zipcode: string;
    phone: string;
  }>({
    initialValues: {
        label_of_address: "",
        firstName: "",
        lastName: "",
        company: "",
        addressLine1: "",
        addressLine2: "",
        country: "",
        county: "",
        city: "",
        state: "",
        zipcode: "",
        phone: "",
    },
    validationSchema: Yup.object({
        lastName: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
    }),
    onSubmit: async (values) => {
      
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/brands`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            label_of_address: values.label_of_address,
            firstName: values.firstName,
            lastName: values.lastName,
            company: values.company,
            addressLine1: values.addressLine1,
            addressLine2: values.addressLine2,
            country: values.country,
            county: values.county,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
            phone: values.phone,
          }),
        });
    
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Failed to create brand");
        }
    
        navigate("/agent/addresses", {
          state: { successMessage: "Address added successfully!" },
        });
        toast.success("Address added successfully!");
      } catch (error: any) {
        console.error(error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setFormSubmitting(false);
        setUploadProgress(0);
      }
    }        
  });

  // Handle file selection and integrate with Formik
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files?.[0];
    if (file) {
      formik.setFieldValue("image", file); // Save the file object in Formik
      setPreview(URL.createObjectURL(file)); // Set preview for display
    }
  };

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Add Address</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Label of address 
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="label_of_address"
                                name="label_of_address"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.label_of_address}
                              />
                              {formik.touched.label_of_address && formik.errors.label_of_address ? (
                                <div className="invalid-feedback">
                                  {formik.errors.label_of_address}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                First Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="firstName"
                                name="firstName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.firstName}
                              />
                              {formik.touched.firstName && formik.errors.firstName ? (
                                <div className="invalid-feedback">
                                  {formik.errors.firstName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                               Last Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="lastName"
                                name="lastName"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.lastName}
                              />
                              {formik.touched.lastName && formik.errors.lastName ? (
                                <div className="invalid-feedback">
                                  {formik.errors.lastName}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Company 
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="company"
                                name="company"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.company}
                              />
                              {formik.touched.company && formik.errors.company ? (
                                <div className="invalid-feedback">
                                  {formik.errors.company}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Address Line 1 <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="addressLine1"
                                name="addressLine1"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.addressLine1}
                              />
                              {formik.touched.addressLine1 && formik.errors.addressLine1 ? (
                                <div className="invalid-feedback">
                                  {formik.errors.addressLine1}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Address Line 2 
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="addressLine2"
                                name="addressLine2"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.addressLine2}
                              />
                              {formik.touched.addressLine2 && formik.errors.addressLine2 ? (
                                <div className="invalid-feedback">
                                  {formik.errors.addressLine2}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="country"
                                name="country"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.country}
                              />
                              {formik.touched.country && formik.errors.country ? (
                                <div className="invalid-feedback">
                                  {formik.errors.country}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                County 
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="county"
                                name="county"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.county}
                              />
                              {formik.touched.county && formik.errors.county ? (
                                <div className="invalid-feedback">
                                  {formik.errors.county}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                City <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.city}
                              />
                              {formik.touched.city && formik.errors.city ? (
                                <div className="invalid-feedback">
                                  {formik.errors.city}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                State <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="state"
                                name="state"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.state}
                              />
                              {formik.touched.state && formik.errors.state ? (
                                <div className="invalid-feedback">
                                  {formik.errors.state}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Zip Code <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.zipcode}
                              />
                              {formik.touched.zipcode && formik.errors.zipcode ? (
                                <div className="invalid-feedback">
                                  {formik.errors.zipcode}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Phone number 
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="phone"
                                name="phone"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.phone}
                              />
                              {formik.touched.phone && formik.errors.phone ? (
                                <div className="invalid-feedback">
                                  {formik.errors.phone}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentAddAddress;
