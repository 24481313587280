import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "../../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Address } from "types";

// type Address = {
//   id: string;
//   label: string;
//   firstName: string;
//   lastName: string;
//   company: string;
//   addressLine1: string;
//   addressLine2: string;
//   country: string;
//   county: string;
//   city: string;
//   state: string;
//   zipcode: string;
//   phone: string;
// };

const AgentAddresses = () => {
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/addresses`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        console.log(addresses);
        setAddresses(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAddresses();
  }, [token]);

  // if (addresses?.length === 0) {
  //   return <div>Loading...</div>;
  // }

  useEffect(() => {
    if (addresses!==null && addresses.length>0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [addresses]);

  const handleDelete = async (brandId: string) => {

    setDeleting(brandId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/addresses/${brandId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the brand.");
      }

      toast.success("Brand deleted successfully!");
      // Update the state by removing the deleted license
      setAddresses((prev) =>
        prev.filter((group) => group.id !== brandId)
      );
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the brand.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Addresses </h2>
          <div className="text-end">
            <a
              href="/agent/addAddress"
              className="btn btn-primary float-right"
            >
              Add Address
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Label of address </th>
                          <th>Address line 1</th>
                          <th>City</th>
                          <th>State</th>
                          <th>Country</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {addresses != null &&
                          addresses.map((address, index) => (
                            <tr key={index}>                              
                              <td>{index + 1}</td>
                              <td></td>
                              <td>{address.label}</td>
                              <td>{address.addressLine1}</td>
                              <td>{address.city}</td>
                              <td>{address.state}</td>
                              <td>{address.country}</td>
                              <td>
                                <Link
                                  to={`/agent/editAddress/${address.id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <button
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this address?"
                                    ) && handleDelete(address.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgentAddresses;
