import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./SignageEditVariableProduct.css";

type FormValues = {
  name: string;
  product_type: string;
  sku: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  condition_note: string;
  purchase_price: string | number;
  retail_price: string | number;
  length: string | number;
  height: string | number;
  width: string | number;
  weight: string | number;
  threshold_qty: string | number;
  attribute: string;
  parameter: string;
  image: File | string | null;
  statuss: string;
  is_rotational: string;
  warehouseId: string;
  on_hand: string;
  available: string;
  reserved: string;
  tax_rule: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
  status: string;
};

type Warehouse = {
  id: string;
  label: string;
  status: string;
};

type Attribute = {
  id: string;
  name: string;
  values: string[];
  description: string;
  status: string;
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Variant = {
  variant_id: string;
  variant_name: string;
  sku: string;
  description: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: Inventory[];
};

type Inventory = {
  warehouse_id: string;
  warehouse_name: string;
  on_hand: number;
  available: number;
  reserved: number;
};

type Image = {
  url: string;
};

type Product = {
  // product_id: string;
  name: string;
  product_type: string;
  sku: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: Dimensions;
  threshold_qty: number;
  variants: Variant[];
  condition_note: string;
  image: Image | null;
  status: string;
  is_rotational: boolean;
  tax_rule: string;
};

type ProductObj = {
  product_id: string;
  signage_company_id: string;
  product: Product;
  created_by: {
    id: string;
    name: string;
  };
};

type TaxGroup = {
  id: string;
  name: string;
  status: string;
};

type TaxRule = {
  id: string;
  country: string;
  state: string;
  stateCode: string;
  county: string;
  countyCode: string;
  zipCodes: string[];
  taxRate: number;
  comments: string;
  status: string;
};

const SignageEditVariableProduct = () => {
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product>();
  const [productobj, setProductObj] = useState<ProductObj>();
  const [variants, setVariants] = useState<Variant[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [preview, setPreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [attributes, setAttributes] = useState<Attribute[]>([]);
  const [options, setOptions] = useState<Record<string, string>[]>([]);
  const [taxGroups, setTaxGroups] = useState<TaxGroup[]>([]);
      const [taxRules, setTaxRules] = useState<TaxRule[]>([]);
  const [selectedWarehouseName, setSelectedWarehouseName] = useState<
    string | null
  >(null);

  // State for toggling warehouse visibility
  const [showWarehouseIndex, setShowWarehouseIndex] = useState<number | null>(
    null
  );

  // Function to toggle warehouse inputs
  const toggleWarehouses = (index: number) => {
    setShowWarehouseIndex((prev) => (prev === index ? null : index));
  };

  const toggleEditVariant = (index: number) => {
    setShowWarehouseIndex((prev) => (prev === index ? null : index));
  };

  const [isEditMode, setIsEditMode] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null); // To track which combination is being edited

  // Fetch product data
  useEffect(() => {
    const fetchProduct = async () => {
      if (!token || !userId) {
        console.error("Missing token or userId");
        return;
      }

      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/signage/${userId}/inventory/${productId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch product data");
        }

        const data = await response.json();
        console.log("Fetched product data:", data);

        // setProduct(data);
        setProductObj(data);
        if (data.product.variants && data.product.variants.length > 0) {
          setVariants(data.product.variants);
        }
      } catch (err) {
        console.error("Error fetching product data:", err);
        toast.error("Error fetching product data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (productId) fetchProduct();
  }, [productId, token, userId]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/brands`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/warehouses`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setWarehouses(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchWarehouses();
  }, [token]);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "brands");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${process.env.REACT_APP_API_URL}/images/upload`, true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        console.log("xhr:", xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log("upload response:", response);

          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  useEffect(() => {
    const fetchAttributes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/product-attributes`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setAttributes(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchAttributes();
  }, [token]);

  useEffect(() => {
            const fetchTaxGroups = async () => {
              try {
                const response = await fetch(
                  `${process.env.REACT_APP_API_URL}/tax-groups`,
                  {
                    method: "GET",
                    headers: {
                      Authorization: `Bearer ${token}`,
                      "Content-Type": "application/json",
                    },
                  }
                );
                const data = await response.json();
                setTaxGroups(data);
              } catch (err) {
                setError(
                  err instanceof Error ? err.message : "An unknown error occurred"
                );
              } finally {
                setLoading(false);
              }
            };
        
            fetchTaxGroups();
          }, [token]);
      
          useEffect(() => {
              const fetchTaxRules = async () => {
                try {
                  const response = await fetch(
                    `${process.env.REACT_APP_API_URL}/tax-rules`,
                    {
                      method: "GET",
                      headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                      },
                    }
                  );
                  const data = await response.json();
                  setTaxRules(data);
                } catch (err) {
                  setError(
                    err instanceof Error ? err.message : "An unknown error occurred"
                  );
                } finally {
                  setLoading(false);
                }
              };
          
              fetchTaxRules();
            }, [token]);

  useEffect(() => {
    if (variants && variants.length > 0) {
      const preselectedOptions = variants.flatMap((variant) =>
        Object.entries(variant.attributes).map(([key, value]) => ({
          [key.toLowerCase()]: value,
        }))
      );
      setOptions(preselectedOptions);
    }
  }, [variants]);

  const handleAttributeClick = (attribute: string, value: string) => {
    setOptions((prevOptions) => {
      const key = attribute.toLowerCase();
      const existingIndex = prevOptions.findIndex(
        (option) => option[key] === value
      );

      if (existingIndex !== -1) {
        // If the attribute-value pair exists, remove it
        return prevOptions.filter((_, index) => index !== existingIndex);
      } else {
        // Otherwise, add it
        return [...prevOptions, { [key]: value }];
      }
    });
  };

  const handleRemoveOption = (index: number) => {
    setOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
  };

  const [combinations, setCombinations] = useState<
    {
      id: string;
      sku: string;
      description: string;
      attributes: Record<string, string>;
      price: number;
      quantity?: number;
      warehouseData?: {
        warehouse_id: string;
        warehouse_name: string;
        on_hand: number;
        available: number;
        reserved: number;
      }[];
    }[]
  >([]);

  const handleGenerateCombinations = () => {
    const attributeValues = attributes.reduce<Record<string, string[]>>(
      (acc, attr) => {
        const selectedValues = attr.values.filter((value) =>
          options.some((option) => option[attr.name.toLowerCase()] === value)
        );
        if (selectedValues.length > 0) {
          acc[attr.name.toLowerCase()] = selectedValues;
        }
        return acc;
      },
      {}
    );

    const keys = Object.keys(attributeValues);
    if (keys.length === 0) {
      toast.error("No attributes selected.");
      return;
    }

    const generateCombinations = (
      remainingKeys: string[],
      currentCombination: Record<string, string>
    ): {
      id: string;
      sku: string;
      description: string;
      attributes: Record<string, string>;
      price: number;
      quantity: number;
      warehouseData: {
        warehouse_id: string;
        warehouse_name: string;
        on_hand: number;
        available: number;
        reserved: number;
      }[];
    }[] => {
      if (remainingKeys.length === 0) {
        return [
          {
            id: "",
            sku: "",
            description: "",
            attributes: currentCombination,
            price: 0,
            quantity: 0,
            warehouseData: warehouses.map((warehouse) => ({
              warehouse_id: warehouse.id,
              warehouse_name: warehouse.label,
              on_hand: 0,
              available: 0,
              reserved: 0,
            })),
          },
        ];
      }

      const [key, ...restKeys] = remainingKeys;
      const values = attributeValues[key];
      const combinations = [];

      for (const value of values) {
        combinations.push(
          ...generateCombinations(restKeys, {
            ...currentCombination,
            [key]: value,
          })
        );
      }

      return combinations;
    };

    const allCombinations = generateCombinations(keys, {});
    // setCombinations(allCombinations);
    toast.success("Combinations generated successfully!");
  };

  useEffect(() => {
    if (variants && variants.length > 0) {
      const existingCombinations = variants.map((variant) => ({
        id: variant.variant_id,
        sku: variant.sku, 
        description: variant.description,
        attributes:
          typeof variant.attributes === "string"
            ? JSON.parse(variant.attributes) // Parse attributes if needed
            : variant.attributes, // Use directly if already an object
        price: variant.retail_price, // Use retail_price
        quantity: variant.inventory.reduce((sum, inv) => sum + inv.on_hand, 0), // Aggregate on_hand
        warehouseData: variant.inventory.map((inventory) => ({
          warehouse_id: inventory.warehouse_id,
          warehouse_name: inventory.warehouse_name,
          on_hand: inventory.on_hand || 0,
          available: inventory.available || 0,
          reserved: inventory.reserved || 0,
        })),
      }));
      setCombinations(existingCombinations);
    }
  }, [variants]);

  const createSlug = (input: string): string => {
    return input
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading and trailing whitespace
      .replace(/[^a-z0-9\s-]/g, "") // Remove invalid characters
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single one
  };

  const generateProductNames = (
    productName: string,
    options: { [key: string]: string }[]
  ) => {
    return options.map((option) => {
      const formattedAttributes = Object.values(option)
        .map((value) => value.trim()) // Trim whitespace
        .join(" - "); // Combine attributes with " - "
      return `${productName} - ${formattedAttributes}`;
    });
  };

  const generateProductVariants = () => {
    return combinations.map((combination, index) => ({
      variant_name: `${formik.values.name} - ${Object.values(
        combination.attributes
      ).join(" - ")}`,
      sku: `${formik.values.sku}-${index + 1}`,
      description: combination.description,
      attributes: combination.attributes,
      purchase_price: parseFloat(formik.values.purchase_price as string) || 0,
      retail_price: combination.price,
      package_dimensions: {
        length: parseFloat(formik.values.length as string) || 0,
        height: parseFloat(formik.values.height as string) || 0,
        width: parseFloat(formik.values.width as string) || 0,
        weight: parseFloat(formik.values.weight as string) || 0,
        weight_type: "kg",
      },
      threshold_qty: 0,
      inventory: combination.warehouseData
        ? combination.warehouseData.map((warehouse) => ({
            warehouse_id: warehouse.warehouse_id,
            warehouse_name: warehouse.warehouse_name,
            on_hand: warehouse.on_hand || 0,
            available: warehouse.available || 0,
            reserved: warehouse.reserved || 0,
          }))
        : [
            {
              warehouse_id: "default", // Fallback
              warehouse_name: "Default Warehouse",
              on_hand: 0,
              available: 0,
              reserved: 0,
            },
          ],
      meta_data: {
        slug: createSlug(
          `${formik.values.name} - ${Object.values(combination.attributes).join(
            " - "
          )}`
        ),
      },
      is_rotational: formik.values.is_rotational === "yes",
    }));
  };

  const reverseProductVariants = (variants: any[]) => {
    if (variants.length === 0) {
      return {
        productNames: [],
        values: {},
        exoptions: [],
      };
    }

    // Extract productNames
    const productNames = variants.map((variant) => variant.variant_name);

    // Extract common values from the first variant
    const firstVariant = variants[0];
    const values: FormValues = {
      name: "",
      product_type: "variable", // Assuming product type is "variable"
      sku: firstVariant.sku.split("-")[0], // Extract SKU base
      description: "", // Placeholder as it's not in variants
      brand: "", // Placeholder as it's not in variants
      category: "", // Placeholder as it's not in variants
      item_condition: "", // Placeholder as it's not in variants
      condition_note: "", // Placeholder as it's not in variants
      purchase_price: firstVariant?.purchase_price,
      retail_price: firstVariant.retail_price,
      length: firstVariant?.package_dimensions?.length,
      height: firstVariant?.package_dimensions?.height,
      width: firstVariant?.package_dimensions?.width,
      weight: firstVariant?.package_dimensions?.weight,
      threshold_qty: "", // Placeholder as it's not in variants
      attribute: "", // Placeholder as it's not in variants
      parameter: "", // Placeholder as it's not in variants
      image: null, // Placeholder as it's not in variants
      statuss: "", // Placeholder as it's not in variants
      is_rotational: "", // Placeholder as it's not in variants
      warehouseId: firstVariant?.inventory[0]?.warehouse_id || "",
      on_hand: String(firstVariant?.inventory[0]?.on_hand || 0),
      available: String(firstVariant?.inventory[0]?.available || 0),
      reserved: String(firstVariant?.inventory[0]?.reserved || 0),
      tax_rule: "",
    };

    // Extract options from each variant
    const exoptions = variants.map((variant) => variant.attributes || {});

    return { productNames, values, exoptions };
  };

  const { productNames, values, exoptions } = reverseProductVariants(variants);
  console.log("Product Names:", productNames);
  console.log("Values:", values);
  console.log("Exoptions:", exoptions);
  console.log("options:", options);
  console.log("Variants:", variants);

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      name: productobj?.product?.name || "",
      product_type: "variable",
      sku: productobj?.product?.sku || "",
      description: productobj?.product?.description || "",
      brand: productobj?.product?.brand || "",
      category: productobj?.product?.category || "",
      item_condition: productobj?.product?.item_condition || "",
      condition_note: productobj?.product?.condition_note || "",
      purchase_price: String(productobj?.product?.purchase_price || 0),
      retail_price: String(productobj?.product?.retail_price || 0),
      length: String(productobj?.product?.package_dimensions?.length || 0),
      height: String(productobj?.product?.package_dimensions?.height || 0),
      width: String(productobj?.product?.package_dimensions?.width || 0),
      weight: String(productobj?.product?.package_dimensions?.weight || 0),
      threshold_qty: String(productobj?.product?.threshold_qty || 0),
      attribute: productobj?.product?.name || "",
      parameter: "",
      image: productobj?.product?.image?.url || null,
      statuss: productobj?.product?.status || "active",
      is_rotational: productobj?.product?.is_rotational ? "yes" : "no",
      warehouseId: productobj?.product?.variants?.[0]?.inventory?.[0]?.warehouse_id || "",
      on_hand: String(productobj?.product?.variants?.[0]?.inventory?.[0]?.on_hand || 0),
      available: String(productobj?.product?.variants?.[0]?.inventory?.[0]?.available || 0),
      reserved: String(productobj?.product?.variants?.[0]?.inventory?.[0]?.reserved || 0),
      tax_rule: productobj?.product?.tax_rule || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
      image: Yup.mixed().test("fileType", "Unsupported file format", (value) =>
        value instanceof File
          ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
          : true
      ),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      //   if (!(values.image instanceof File)) {
      //     toast.error("Please upload a valid image file.");
      //     setFormSubmitting(false);
      //     return;
      //   }

      try {
        const productVariants = generateProductVariants();
        console.log("Product Variants:", productVariants);

        let imageUrl = product?.image?.url;
        if (values.image instanceof File) {
          imageUrl = await uploadFile(values.image);
        }

        // 2. Submit product details
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/signage/${userId}/inventory/${productId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              product_type: values.product_type,
              sku: values.sku,
              description: values.description,
              brand: values.brand,
              category: values.category,
              item_condition: values.item_condition,
              condition_note: values.condition_note,
              purchase_price: parseFloat(values.purchase_price as string),
              retail_price: parseFloat(values.retail_price as string),
              package_dimensions: {
                length: parseFloat(values.length as string),
                height: parseFloat(values.height as string),
                width: parseFloat(values.width as string),
                weight: parseFloat(values.weight as string),
              },
              min_qty: 1,
              threshold_qty: parseFloat(values.threshold_qty as string),
              variants: productVariants,
              image: { url: imageUrl },
              status: values.statuss,
              is_rotational: values.is_rotational === "yes",
              tax_rule: values.tax_rule,
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        const result = await response.json();
        navigate("/signage/products", {
          state: { successMessage: "Product added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Product.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Edit Variable Product </h2>
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-xxl-12">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills nav-success mb-3" role="tablist">
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#product-details"
                      role="tab"
                    >
                      Product Details
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#combinations"
                      role="tab"
                    >
                      Combinations
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#images"
                      role="tab"
                    >
                      Images
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#orders"
                      role="tab"
                    >
                      Orders
                    </a>
                  </li>
                  <li className="nav-item waves-effect waves-light">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#inventory-log"
                      role="tab"
                    >
                      Inventory Log
                    </a>
                  </li>
                </ul>

                <div className="tab-content text-muted">
                  <div
                    className="tab-pane active"
                    id="product-details"
                    role="tabpanel"
                  >
                    <div className="d-flex mt-2">
                      <div className="row">
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-8 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                SKU <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="sku"
                                name="sku"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.sku}
                              />
                              {formik.touched.sku && formik.errors.sku ? (
                                <div className="text-danger">
                                  {formik.errors.sku}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-8">
                              <div className="card">
                                <div className="card-body">
                                  {/* <h5>Branding</h5> */}
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">Brand</label>
                                    <select
                                      className="form-control"
                                      id="brand"
                                      name="brand"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.brand}
                                    >
                                      <option value="">- Select -</option>
                                      {brands !== null &&
                                        brands.map((brand) => (
                                          <option value={brand.name}>
                                            {brand.name}
                                          </option>
                                        ))}
                                    </select>
                                    {formik.touched.brand &&
                                    formik.errors.brand ? (
                                      <div className="text-danger">
                                        {formik.errors.brand}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Category
                                    </label>
                                    <select
                                      className="form-control"
                                      id="category"
                                      name="category"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.category}
                                    >
                                      <option value="">- Select -</option>
                                      {categories !== null &&
                                        categories.map((category) => (
                                          <option value={category.name}>
                                            {category.name}
                                          </option>
                                        ))}
                                    </select>
                                    {formik.touched.category &&
                                    formik.errors.category ? (
                                      <div className="text-danger">
                                        {formik.errors.category}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="card">
                                <div className="card-body">
                                  {/* <h5>Pricing</h5> */}
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Purchase Price{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="purchase_price"
                                      name="purchase_price"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.purchase_price}
                                    />
                                    {formik.touched.purchase_price &&
                                    formik.errors.purchase_price ? (
                                      <div className="text-danger">
                                        {formik.errors.purchase_price}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="col-md-12 mb-3">
                                    <label className="form-label">
                                      Retail Price{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="retail_price"
                                      name="retail_price"
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      value={formik.values.retail_price}
                                    />
                                    {formik.touched.retail_price &&
                                    formik.errors.retail_price ? (
                                      <div className="text-danger">
                                        {formik.errors.retail_price}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                              ></textarea>
                              {formik.touched.description &&
                              formik.errors.description ? (
                                <div className="text-danger">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="row">
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Threshold Quantity{" "}
                                        </label>
                                        <input
                                          type="number"
                                          className="form-control"
                                          id="threshold_qty"
                                          name="threshold_qty"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.threshold_qty}
                                        />
                                        {formik.touched.threshold_qty &&
                                        formik.errors.threshold_qty ? (
                                          <div className="text-danger">
                                            {formik.errors.threshold_qty}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Status{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          id="statuss"
                                          name="statuss"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.statuss}
                                        >
                                          <option value="">- Select -</option>
                                          <option value="Active">Active</option>
                                          <option value="Inactive">
                                            Inactive
                                          </option>
                                        </select>
                                        {formik.touched.statuss &&
                                        formik.errors.statuss ? (
                                          <div className="text-danger">
                                            {formik.errors.statuss}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="col-md-4 mb-3">
                                        <label className="form-label">
                                          Is Rotational{" "}
                                          <span className="text-danger">*</span>
                                        </label>
                                        <select
                                          className="form-control"
                                          id="is_rotational"
                                          name="is_rotational"
                                          onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          value={formik.values.is_rotational}
                                        >
                                          <option value="">- Select -</option>
                                          <option value="yes">Yes</option>
                                          <option value="no">No</option>
                                        </select>
                                        {formik.touched.is_rotational &&
                                        formik.errors.is_rotational ? (
                                          <div className="text-danger">
                                            {formik.errors.is_rotational}
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Dimensions/Measurements (Inches)
                                  </label>
                                  <div className="row">
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="length"
                                        name="length"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.length}
                                        placeholder="Length"
                                      />
                                      {formik.touched.length &&
                                      formik.errors.length ? (
                                        <div className="text-danger">
                                          {formik.errors.length}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="height"
                                        name="height"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.height}
                                        placeholder="Height"
                                      />
                                      {formik.touched.height &&
                                      formik.errors.height ? (
                                        <div className="text-danger">
                                          {formik.errors.height}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="width"
                                        name="width"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.width}
                                        placeholder="Width"
                                      />
                                      {formik.touched.width &&
                                      formik.errors.width ? (
                                        <div className="text-danger">
                                          {formik.errors.width}
                                        </div>
                                      ) : null}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <label className="form-label">
                                        Weight(in gms)
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        id="weight"
                                        name="weight"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.weight}
                                      />
                                      {formik.touched.weight &&
                                      formik.errors.weight ? (
                                        <div className="text-danger">
                                          {formik.errors.weight}
                                        </div>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                {/* <h5>Pricing</h5> */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Item Condition
                                  </label>
                                  <select
                                    className="form-control"
                                    id="item_condition"
                                    name="item_condition"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.item_condition}
                                  >
                                    <option value="">- Select -</option>
                                    <option value="new">New</option>
                                    <option value="used">Used</option>
                                    <option value="damaged">Damaged</option>
                                  </select>
                                  {formik.touched.item_condition &&
                                  formik.errors.item_condition ? (
                                    <div className="text-danger">
                                      {formik.errors.item_condition}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Condition Notes{" "}
                                  </label>
                                  <textarea
                                    className="form-control"
                                    id="condition_note"
                                    name="condition_note"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.condition_note}
                                    rows={3}
                                  ></textarea>
                                  {formik.touched.condition_note &&
                                  formik.errors.condition_note ? (
                                    <div className="text-danger">
                                      {formik.errors.condition_note}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                {/* <h5>Pricing</h5> */}
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Tax Rule
                                  </label>
                                  <select
                                    className="form-control"
                                    id="tax_rule"
                                    name="tax_rule"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.tax_rule}
                                  >
                                    <option value="">- Select -</option>
                                    {taxGroups!=null && taxGroups.map((group, index)=>(
                                      <option value={group.name}>{group.name}</option>
                                    ))}
                                    {taxRules!=null && taxRules.map((rule, index)=>(
                                      <option value={`${rule.country}(${rule.taxRate})`}>
                                      {`${rule.country}(${rule.taxRate}'%')`}
                                    </option>
                                    
                                    ))}
                                  </select>
                                  {formik.touched.tax_rule &&
                                  formik.errors.tax_rule ? (
                                    <div className="text-danger">
                                      {formik.errors.tax_rule}
                                    </div>
                                  ) : null}
                                </div>
                                
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">Image</label>
                                  <input
                                    type="file"
                                    className="form-control"
                                    id="image"
                                    name="image"
                                    onChange={(event) => {
                                      const file =
                                        event?.currentTarget.files?.[0];
                                      if (file) {
                                        formik.setFieldValue("image", file); // Set File object
                                        setPreview(URL.createObjectURL(file)); // Set preview for new file
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                  />

                                  {formik.touched.image &&
                                    formik.errors.image && (
                                      <div className="text-danger">
                                        {formik.errors.image}
                                      </div>
                                    )}

                                  {/* Existing Image */}
                                  {product?.image?.url && !preview && (
                                    <div className="mt-3">
                                      <strong>Current Image:</strong>
                                      <br />
                                      <img
                                        src={
                                          "https://dev.reelty.app/" +
                                          product.image.url
                                        }
                                        alt="Existing Product"
                                        style={{
                                          maxWidth: "100px",
                                          marginTop: "10px",
                                        }}
                                      />
                                    </div>
                                  )}

                                  {/* Preview New Image */}
                                  {preview && (
                                    <div className="mt-3">
                                      <strong>Preview:</strong>
                                      <img
                                        src={preview}
                                        alt="Preview"
                                        style={{
                                          maxWidth: "100px",
                                          marginTop: "10px",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <input
                        type="hidden"
                        id="product_type"
                        name="product_type"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.product_type}
                      />
                      <button className="btn btn-success w-100" type="submit">
                        Save Product
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane" id="combinations" role="tabpanel">
                    {!isEditMode && (
                      <div className="mt-2 combinations-blk">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="card">
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-md-9">
                                    <h4>Combinations</h4>
                                    <div className="text-end">
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={handleGenerateCombinations}
                                      >
                                        Generate
                                      </button>
                                    </div>
                                    <div className="combinations">
                                      {combinations.length === 0 && (
                                        <p>No combinations added yet.</p>
                                      )}
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th>Combination</th>
                                            <th>Price</th>
                                            {/* <th>Warehouses</th> */}
                                            <th>Action</th>
                                            <th>Action</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {combinations.map(
                                            (combination, combIndex) => (
                                              <tr key={combIndex}>
                                                {/* Combination Details */}
                                                <td>
                                                  {Object.entries(
                                                    combination.attributes
                                                  )
                                                    .map(
                                                      ([key, value]) =>
                                                        `${key}: ${value}`
                                                    )
                                                    .join(", ")}
                                                </td>

                                                {/* Editable Price */}
                                                <td>
                                                  <input
                                                    type="number"
                                                    className="form-control"
                                                    value={combination.price}
                                                    onChange={(e) => {
                                                      const price =
                                                        parseFloat(
                                                          e.target.value
                                                        ) || 0;
                                                      setCombinations((prev) =>
                                                        prev.map((comb, i) =>
                                                          i === combIndex
                                                            ? { ...comb, price }
                                                            : comb
                                                        )
                                                      );
                                                    }}
                                                  />
                                                </td>

                                                {/* Toggle Warehouses */}
                                                <td>
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm"
                                                    onClick={() => {
                                                      setIsEditMode(
                                                        !isEditMode
                                                      );
                                                      setEditIndex(combIndex);
                                                    }}
                                                  >
                                                    Edit
                                                  </button>
                                                </td>

                                                {/* Remove Button */}
                                                <td>
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() => {
                                                      setCombinations((prev) =>
                                                        prev.filter(
                                                          (_, i) =>
                                                            i !== combIndex
                                                        )
                                                      );
                                                    }}
                                                  >
                                                    Remove
                                                  </button>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <h4>Attributes</h4>
                                    <div className="attributes">
                                      {attributes != null &&
                                        attributes.map((attr, index) => (
                                          <div key={index}>
                                            <div className="attr-head">
                                              {attr.name}
                                            </div>
                                            <ul className="list-group">
                                              {attr.values.map(
                                                (param, paramIndex) => (
                                                  <li
                                                    key={paramIndex}
                                                    className="list-group-item"
                                                    onClick={() =>
                                                      handleAttributeClick(
                                                        attr.name,
                                                        param
                                                      )
                                                    }
                                                    style={{
                                                      cursor: "pointer",
                                                      backgroundColor:
                                                        options.some(
                                                          (opt) =>
                                                            opt[
                                                              attr.name.toLowerCase()
                                                            ] === param
                                                        )
                                                          ? "lightgreen"
                                                          : "white",
                                                    }}
                                                  >
                                                    {param}
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {isEditMode && editIndex !== null && (
                      <div className="mt-4 card">
                        <div className="card-body">
                          <button
                            type="button"
                            className="btn btn-outline mb-4 border"
                            onClick={() => {
                              setIsEditMode(!isEditMode);
                            }}
                          >
                            Back to Combinations
                          </button>
                          {/* <h5>Edit Combination</h5> */}
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={formik.values.name}
                                readOnly
                              />
                            </div>
                            <div className="col-md-3 mb-3">
                              <label>SKU</label>
                              <input
                                type="text"
                                className="form-control"
                                value={combinations[editIndex].sku || ""}
                                onChange={(e) => {
                                  const newSku = e.target.value;
                                  setCombinations((prev) =>
                                    prev.map((comb, i) =>
                                      i === editIndex
                                        ? { ...comb, sku: newSku }
                                        : comb
                                    )
                                  );
                                }}
                              />
                            </div>
                            <div className="col-md-3 mb-3">
                              <label>Price</label>
                              <input
                                type="number"
                                className="form-control"
                                value={combinations[editIndex].price || ""}
                                onChange={(e) => {
                                  const newPrice =
                                    parseFloat(e.target.value) || 0;
                                  setCombinations((prev) =>
                                    prev.map((comb, i) =>
                                      i === editIndex
                                        ? { ...comb, price: newPrice }
                                        : comb
                                    )
                                  );
                                }}
                              />
                            </div>
                            <div className="col-md-12 mb-3">
                              <label>Description</label>
                              <textarea
                                className="form-control"
                                rows={3}
                                value={
                                  combinations[editIndex].description || ""
                                }
                                onChange={(e) => {
                                  const newDescription = e.target.value;
                                  setCombinations((prev) =>
                                    prev.map((comb, i) =>
                                      i === editIndex
                                        ? {
                                            ...comb,
                                            description: newDescription,
                                          }
                                        : comb
                                    )
                                  );
                                }}
                              ></textarea>
                            </div>

                            {/* Toggle Warehouse Inputs */}
                            {/* <div className="col-md-12">
          <button
            type="button"
            className="btn btn-info"
            onClick={() => setShowWarehouseIndex(editIndex)}
          >
            Edit Warehouse Details
          </button>
        </div> */}

                            {/* Conditionally Render Warehouse Inputs */}

                            <div className="mt-3">
                              {warehouses.map((warehouse, wIndex) => (
                                <div key={wIndex} className="mb-3">
                                  <strong>{warehouse.label}</strong>
                                  <div className="row">
                                    <div className="col-md-4">
                                      <label>On Hand</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={
                                          combinations[editIndex]
                                            ?.warehouseData?.[wIndex]
                                            ?.on_hand || 0
                                        }
                                        onChange={(e) => {
                                          const value =
                                            parseInt(e.target.value, 10) || 0;
                                          setCombinations((prev) =>
                                            prev.map((comb, i) => {
                                              if (i === editIndex) {
                                                const updatedWarehouseData = [
                                                  ...(comb.warehouseData || []),
                                                ];
                                                updatedWarehouseData[wIndex] = {
                                                  ...updatedWarehouseData[
                                                    wIndex
                                                  ],
                                                  warehouse_id: warehouse.id,
                                                  warehouse_name:
                                                    warehouse.label,
                                                  on_hand: value,
                                                };
                                                return {
                                                  ...comb,
                                                  warehouseData:
                                                    updatedWarehouseData,
                                                };
                                              }
                                              return comb;
                                            })
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <label>Available</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={
                                          combinations[editIndex]
                                            .warehouseData?.[wIndex]
                                            ?.available || 0
                                        }
                                        onChange={(e) => {
                                          const value =
                                            parseInt(e.target.value, 10) || 0;
                                          setCombinations((prev) =>
                                            prev.map((comb, i) => {
                                              if (i === editIndex) {
                                                const updatedData = [
                                                  ...(comb.warehouseData || []),
                                                ];
                                                updatedData[wIndex] = {
                                                  ...updatedData[wIndex],
                                                  warehouse_id: warehouse.id,
                                                  warehouse_name:
                                                    warehouse.label,
                                                  available: value,
                                                };
                                                return {
                                                  ...comb,
                                                  warehouseData: updatedData,
                                                };
                                              }
                                              return comb;
                                            })
                                          );
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-4">
                                      <label>Reserved</label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={
                                          combinations[editIndex]
                                            .warehouseData?.[wIndex]
                                            ?.reserved || 0
                                        }
                                        onChange={(e) => {
                                          const value =
                                            parseInt(e.target.value, 10) || 0;
                                          setCombinations((prev) =>
                                            prev.map((comb, i) => {
                                              if (i === editIndex) {
                                                const updatedData = [
                                                  ...(comb.warehouseData || []),
                                                ];
                                                updatedData[wIndex] = {
                                                  ...updatedData[wIndex],
                                                  warehouse_id: warehouse.id,
                                                  warehouse_name:
                                                    warehouse.label,
                                                  reserved: value,
                                                };
                                                return {
                                                  ...comb,
                                                  warehouseData: updatedData,
                                                };
                                              }
                                              return comb;
                                            })
                                          );
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>

                          <button
                            className="btn btn-success mt-3"
                            onClick={() => {
                              setIsEditMode(false);
                              setEditIndex(null);
                              setShowWarehouseIndex(null); // Reset warehouse view
                            }}
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                    )}

                    <div className="mt-4">
                      <button className="btn btn-success w-100" type="submit">
                        Save Product
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane" id="images" role="tabpanel">
                    <div className="d-flex mt-2">
                      <div className="card">
                        <div className="card-header">
                          <h5 className="card-title mb-0">Product Gallery</h5>
                        </div>
                        <div className="card-body">
                          <div className="mb-4">
                            {/* <h5 className="fs-14 mb-1">Product Image</h5>
                          <p className="text-muted">Add Product main Image.</p> */}
                            {/* <div className="text-center">
                            <div className="position-relative d-inline-block">
                              <div className="position-absolute top-100 start-100 translate-middle">
                                <label
                                  htmlFor="product-image-input"
                                  className="mb-0"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="right"
                                  title="Select Image"
                                >
                                  <div className="avatar-xs">
                                    <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                      <i className="ri-image-fill"></i>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control d-none"
                                  value=""
                                  id="product-image-input"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                />
                              </div>
                              <div className="avatar-lg">
                                <div className="avatar-title bg-light rounded">
                                  <img
                                    src=""
                                    id="product-img"
                                    className="avatar-md h-auto"
                                  />
                                </div>
                              </div>
                            </div>
                          </div> */}
                          </div>
                          <div>
                            {/* <h5 className="fs-14 mb-1">Product Gallery</h5> */}
                            <p className="text-muted">
                              Add Product Gallery Images.
                            </p>

                            <div className="dropzone">
                              <div className="fallback">
                                <input name="file" type="file" />
                              </div>
                              {/* <div className="dz-message needsclick">
                              <div className="mb-3">
                                <i className="display-4 text-muted ri-upload-cloud-2-fill"></i>
                              </div>

                              <h5>Drop files here or click to upload.</h5>
                            </div> */}
                            </div>

                            {/* <ul
                            className="list-unstyled mb-0"
                            id="dropzone-preview"
                          >
                            <li className="mt-2" id="dropzone-preview-list">
                              <div className="border rounded">
                                <div className="d-flex p-2">
                                  <div className="flex-shrink-0 me-3">
                                    <div className="avatar-sm bg-light rounded">
                                      <img
                                        data-dz-thumbnail
                                        className="img-fluid rounded d-block"
                                        src="#"
                                        alt="Product-Image"
                                      />
                                    </div>
                                  </div>
                                  <div className="flex-grow-1">
                                    <div className="pt-1">
                                      <h5 className="fs-14 mb-1" data-dz-name>
                                        &nbsp;
                                      </h5>
                                      <p
                                        className="fs-13 text-muted mb-0"
                                        data-dz-size
                                      ></p>
                                      <strong
                                        className="error text-danger"
                                        data-dz-errormessage
                                      ></strong>
                                    </div>
                                  </div>
                                  <div className="flex-shrink-0 ms-3">
                                    <button
                                      data-dz-remove
                                      className="btn btn-sm btn-danger"
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <button className="btn btn-success w-100" type="submit">
                        Save Product
                      </button>
                    </div>
                  </div>
                  <div className="tab-pane" id="orders" role="tabpanel">
                    <div className="d-flex mt-2">
                      <p>Orders</p>
                    </div>
                  </div>
                  <div className="tab-pane" id="inventory-log" role="tabpanel">
                    <div className="d-flex mt-2">
                      <p>Iventory logs</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="row justify-content-center">
        <div className="col-md-12 col-lg-12 col-xl-12">
          <div className="mt-0 card-bg-fill">
            <div className="pt-1">
              <div className="pt-1 mt-1"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageEditVariableProduct;
