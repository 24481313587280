import React, { useState, useRef, useEffect } from "react";
import { useFormik, FormikProps } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SignageAddShippingCost = () => {
  const [formSubmitting, setFormSubmitting] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("sessionToken");

  const formik = useFormik<{
    name: string;
    description: string;
    is_free: boolean;
    cost: number;
    statuss: string;
  }>({
    initialValues: {
      name: "",
      description: "",
      is_free: false,
      cost: 0,
      statuss: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/dynamic-costs`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            name: values.name,
            description: values.description,
            is_free: values.is_free,
            cost: values.cost,
            status: values.statuss === "active",
          }),
        });
    
        if (!response.ok) {
          const error = await response.json();
          throw new Error(error.message || "Failed to create dynamic cost");
        }
    
        navigate("/signage/dynamic-costs", {
          state: { successMessage: "Shipping cost added successfully!" },
        });
        toast.success("Shipping cost added successfully!");
      } catch (error: any) {
        console.error(error);
        toast.error(`Error: ${error.message}`);
      } finally {
        setFormSubmitting(false);
      }
    }        
  });

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Add Shipping Cost</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            {/* Name Field */}
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Name of the Shipping service <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="invalid-feedback">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>                            
                            <div className="col-md-1 mb-3">
                              <label className="form-label">
                                Free 
                              </label>
                              <br></br>
                              <input
                                type="checkbox"
                                id="is_free"
                                name="is_free"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                checked={formik.values.is_free}
                              />
                              {formik.touched.is_free && formik.errors.is_free ? (
                                <div className="invalid-feedback">
                                  {formik.errors.is_free}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-2 mb-3">
                              <label className="form-label">
                                Cost 
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="cost"
                                name="cost"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.cost}
                              />
                              {formik.touched.cost && formik.errors.cost ? (
                                <div className="invalid-feedback">
                                  {formik.errors.cost}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-3 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                               className="form-control"
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="invalid-feedback">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                              Description <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                              />
                              {formik.touched.description && formik.errors.description ? (
                                <div className="invalid-feedback">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>
                            
                          </div>
                        </div>
                       
                      </div>

                      {/* Submit Button */}
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageAddShippingCost;
