import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: Address;
  specialities: string;
  regionsServed: string;
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: Address;
}

interface BrokerageFirm {
  id: string;
  companyID: string;
  companyName: string;
  licenseType: string;
  email: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  firmDetails: FirmInfo;
  // modulePermissions: string[];
  created_at: string;
}

const SignageBrokerageFirms = () => {
  const [brokerageFirms, setBrokerageFirms] = useState<BrokerageFirm[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state?.successMessage && !toastShown) {
      toast.success(location.state.successMessage);
      setToastShown(true);
      location.state.successMessage = null;
    }
  }, [location.state, toastShown]);

  useEffect(() => {
    const fetchBrokerageFirms = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users-search?userType=brokerage_firm`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch agents");
        }

        const data: BrokerageFirm[] = await response.json();
        console.log(data);
        setBrokerageFirms(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrokerageFirms();
  }, [token]);

  useEffect(() => {
    if (brokerageFirms != null && brokerageFirms.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [brokerageFirms]);

  const handleDelete = async (agentId: string) => {
    // const confirmation = window.confirm(
    //   "Are you sure you want to delete this license?"
    // );
    // if (!confirmation) return;

    setDeleting(agentId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/users/${agentId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the brokerage firm.");
      }

      toast.success("Office deleted successfully!");
      // Update the state by removing the deleted license
      setBrokerageFirms((prev) => prev.filter((bfirm) => bfirm.id !== agentId));
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  const handleProButton = async (firmId: string, activate: boolean) => {
    try {
      // Determine the correct endpoint based on the action
      const endpoint = activate
        ? `https://dev.reelty.app/users/signage/brokerage/${firmId}/activate`
        : `https://dev.reelty.app/users/signage/brokerage/${firmId}/deactivate`;
  
      // Send a request to the appropriate endpoint
      const response = await fetch(endpoint, {
        method: "POST", // or "PUT" if your API expects PUT requests
        headers: {
          "Content-Type": "application/json",
          // Include authentication headers if required
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to ${activate ? "activate" : "deactivate"} firm`);
      }
  
      const data = await response.json();
      // Handle successful response
      console.log(`${activate ? "Activated" : "Deactivated"} firm:`, data);
      // Optionally, update local state or notify the user
    } catch (err) {
      console.error(err instanceof Error ? err.message : "Unknown error occurred");
      // Optionally, display an error notification to the user
    }
  };
  

  return (
    <div className="agents-list">
      <div className="card">
        <div className="card-body">
          <h2>Brokerage Firms </h2>
          <div className="text-end">
            <a
              href="/signage/addBrokerageFirm"
              className="btn btn-primary float-right"
            >
              Add Brokerage Firm
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Company Status</th>
                          <th>Company Name</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Email</th>
                          <th>Pro License Status</th>
                          <th>Make Pro License</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(brokerageFirms) &&
                          brokerageFirms.map((company, index) => (
                            <tr key={company.id}>
                              <td>{index + 1}</td>
                              <td>{company.status}</td>
                              <td>
                              <Link
                                  to={`/signage/brokerageFirm/${company.id}`}
                                  className="waves-effect waves-light me-2"
                                >
                                  {company.firmDetails?.companyName || ""}
                                </Link>
                              </td>
                              <td>{company.firstName}</td>
                              <td>{company.lastName}</td>
                              <td>{company.email}</td>
                              <td></td>
                              <td>
                                {company?.status=='active'?(
                                  <button 
                              onClick={() => handleProButton(company?.id, false)}
                               className="btn btn-danger">Make Deactivate</button>
                                ):(
<button 
                              onClick={() => handleProButton(company?.id, true)}
                               className="btn btn-success">Make Pro License</button>
                                )}
                                
                               </td>
                              <td>
                                <Link
                                  to={`/signage/brokerageFirm/${company.id}`}
                                  className="btn btn-secondary waves-effect waves-light me-2"
                                >
                                  <i className="ri-eye-fill align-bottom me-2"></i>
                                </Link>

                                <Link
                                  to={`/signage/editBrokerageFirm/${company.id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>
                                </Link>

                                <button
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this brokerage firm?"
                                    ) && handleDelete(company.id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageBrokerageFirms;
