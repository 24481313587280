import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  product_id: string;
  name: string;
  description: string;
  sku: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: number;
  retail_price: number;
  qty: number;
  threshold_qty: number;
  package_dimensions: Dimensions;
  notes: string;
  image: Image | null;
  status: string;
  linked_mode: string;
  linked_installer: {
    id: string;
    name: string;
  };
  variants: Variant[];
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      location_id: string;
      location_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

type TaxGroup = {
  id: string;
  name: string;
  status: string;
};

type Image = {
  url: string;
};

type Office = {
  id: string;
  officeName: string;
  officeType: string;
};

type AgentProduct = {
  _id: string;
  agent_id: string;
  agent_name: string;
  product: Product;
};

const RealtorAgentInventory = () => {
  const [taxGroups, setTaxGroups] = useState<TaxGroup[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const [agentproducts, setAgentProducts] = useState<AgentProduct[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          //   `${process.env.REACT_APP_API_URL}/office/${userId}/inventory/products`,
          `${process.env.REACT_APP_API_URL}/user/agent-inventories`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }

        const data = await response.json();
        // console.log("Fetched data:", data);
        // setTempObject(data);
        // Ensure the response contains the expected structure
        if (data && Array.isArray(data)) {
          setAgentProducts(data);
          // setProducts(data.products);
        } else {
          console.error("Unexpected API response structure:", data);
          setAgentProducts([]);
          // setProducts(data.products);
        }
      } catch (err) {
        console.error("Error fetching products:", err);
        setError(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token, userId]);

  useEffect(() => {
    const fetchTaxGroups = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tax-groups`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setTaxGroups(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchTaxGroups();
  }, [token]);

  const handleDelete = async (agentId: string, productId: string) => {
    setDeleting(productId); // Set the product being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the product.");
      }

      toast.success("Product deleted successfully!");
      // Update the state by removing the deleted product
      setAgentProducts((prev) =>
        prev.filter((product) => product._id !== productId)
      );
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the product.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  useEffect(() => {
    if (agentproducts != null && agentproducts.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [agentproducts]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Agent Inventory </h2>
          <div className="text-end">
            <a
              href="/realtor/addAgentProduct"
              className="btn btn-primary float-right"
            >
              Add Agent Product
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          {/* <th>
                            <input type="checkbox" />
                          </th> */}
                          <th>S.No.</th>
                          <th>Agent Name</th>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Category</th>
                          <th>Qty</th>
                          <th>Status</th>
                          <th>Installer Status</th>
                          <th>Condition</th>
                          <th>Dimensions</th>
                          <th>Weight</th>
                          <th>Linked Installer</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agentproducts != null &&
                          agentproducts.map((agentproduct, index) => (
                            <tr key={agentproduct._id}>
                              {/* <td><input type="checkbox" /></td> */}
                              <td>{index + 1}</td>
                              <td>{agentproduct.agent_name}</td>
                              <td className="text-center">
                                {agentproduct.product.image?.url ? (
                                  <img
                                    src={`https://dev.reelty.app/${agentproduct.product.image.url}`}
                                    alt="Product"
                                    height={50}
                                  />
                                ) : (
                                  "No Image"
                                )}
                              </td>
                              <td>{agentproduct.product.name}</td>
                              <td>{agentproduct.product.category}</td>
                              <td>
                                {agentproduct?.product.variants?.[0]
                                  ?.inventory?.[0]?.on_hand ?? 0}
                              </td>
                              <td>{agentproduct.product.status}</td>
                              <td>{agentproduct.product.linked_mode}</td>
                              <td>{agentproduct.product.item_condition}</td>
                              <td>
                                {
                                  agentproduct.product.package_dimensions
                                    ?.length
                                }{" "}
                                x{" "}
                                {
                                  agentproduct.product.package_dimensions
                                    ?.height
                                }{" "}
                                x{" "}
                                {agentproduct.product.package_dimensions?.width}
                              </td>
                              <td>
                                {
                                  agentproduct.product.package_dimensions
                                    ?.weight
                                }
                              </td>
                              <td>
                                {agentproduct.product.linked_installer?.name ||
                                  "None"}
                              </td>
                              <td>
                                <Link
                                  to={`/realtor/agent/${agentproduct.agent_id}/viewAgentProduct/${agentproduct?._id}`}
                                  className="btn btn-info me-2"
                                >
                                  View
                                </Link>
                                <Link
                                  to={`/realtor/agent/${agentproduct.agent_id}/editAgentProduct/${agentproduct._id}`}
                                  className="btn btn-warning me-2"
                                >
                                  Edit
                                </Link>
                                <button
                                  onClick={() =>
                                    window.confirm("Delete this product?") &&
                                    handleDelete(
                                      agentproduct.agent_id,
                                      agentproduct._id
                                    )
                                  }
                                  className="btn btn-danger"
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorAgentInventory;
