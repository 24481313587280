import { useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface License {
    id: string;    
    firstName: string;
    lastName: string;    
    statuss: string;
    licenseType: string;
    warehouses: string[] | null;
  }

  type Address = {
    street: string;
    // street_address2: string;
    city: string;
    state: string;
    zipcode: string;
    country: string;
  };
  
  type Warehouse = {
    id: string;
    label: string;
    // warehouseId: string;
    address: Address;
    // assigned_teammember?: string;
    status: string;
    created_at: string;
  };

const TeammemberWarehouses = () => {
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const [license, setLicense] = useState<License | null>(null);

  useEffect(() => {
    const fetchLicense = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${userId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch license data");
        }

        const data = await response.json();
        console.log("Fetched License Data:", data);

        if (data) {
          const fetchedLicense: License = {
            id: data.id || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            statuss: data.status || "Active",
            licenseType: data.licenseType || "", 
            warehouses: data.warehouses || null,            
          };

          setLicense(fetchedLicense); 
          setWarehouses(data.warehouses);             
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching license data:", error);
        setError("Failed to fetch license data.");
      } finally {
        setLoading(false);
      }
    };

    if (!license) {
      fetchLicense();
    }
  }, [userId, license, token]);

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Warehouses </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Warehouse Name</th>
                          {/* <th>Warehouse Type</th>
                          <th>City</th>
                          <th>State</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {warehouses != null &&
                          warehouses.map((warehouse, index) => (
                            <tr key={index}>                              
                              <td>{index + 1}</td>
                              <td>{warehouse.label}</td>
                              {/* <td>{warehouse.label}</td>
                              <td>{warehouse.label}</td>
                              <td>{warehouse.label}</td> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeammemberWarehouses;
