import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom"; // Import useParams from React Router
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

interface AgentDetails {
  brokerageFirmAffiliation: string;
  managingBroker: string;
  agentID: string;
  licenseNumber: string;
  licenseExpiryDate: string;
};

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: Address;
  specialities: string;
  regionsServed: string;
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Agent {
  id: string;
  email: string;
  password: string;
  userType: string;
  firstName: string;
  lastName: string;
  phone1: string;
  phone2: string;
  firmDetails: FirmInfo;
  agentDetails: AgentDetails;
  status: string;
  licenseType: string;
  created_at: string;  
}

const SignageViewAgent = () => {
  const { agentId } = useParams<{ agentId: string }>();
  const [agent, setAgent] = useState<Agent | null>(null);
  const token = localStorage.getItem("sessionToken");
  const [error, setError] = useState<string | null>(null);

  // Fetch the office data based on officeId
  useEffect(() => {
    const fetchAgentData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${agentId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch agent data");
        }
        const data = await response.json();
        if (data && data.firmDetails && data.firmDetails.address) {
          setAgent(data);

          // setUserModules(data.modulePermissions);
          // console.log(userModules);
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching agent data:", error);
      }
    };

    if (agentId) {
      fetchAgentData();
    }
  }, [agentId]);
 
  if (!agent) return <div>Loading Agent Data...</div>;

  return (
    <div className="agent">
      <div className="card">
        <div className="card-body">
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img
                src="/assets/images/profile-bg.jpg"
                alt=""
                className="profile-wid-img"
              />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
            <div className="row g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img
                    src="/assets/images/users/avatar-1.jpg"
                    alt="user-img"
                    className="img-thumbnail rounded-circle"
                  />
                </div>
              </div>

              <div className="col">
                <div className="p-2">
                  <h3 className="text-white mb-1">
                    {agent.firstName} {agent.lastName}
                  </h3>
                  <p className="text-white text-opacity-75">
                    Agent
                  </p>
                  {/* <div className="hstack text-white-50 gap-1">
                                        <div className="me-2"><i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>California, United States</div>
                                        <div>
                                            <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>Themesbrand
                                        </div>
                                    </div> */}
                </div>
              </div>

              <div className="col-12 col-lg-auto order-last order-lg-0">
                {/* <div className="row text text-white-50 text-center">
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">24.3K</h4>
                                            <p className="fs-14 mb-0">Followers</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">1.3K</h4>
                                            <p className="fs-14 mb-0">Following</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <div className="tab-content pt-4 text-muted">
                  <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-xxl-3">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-3">Profile</h5>
                            <div className="table-responsive">
                              <table className="table table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      Full Name :
                                    </th>
                                    <td className="text-muted">
                                      {agent.firstName}{" "}
                                      {agent.lastName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      Role :
                                    </th>
                                    <td className="text-muted">
                                      Agent
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      Phone :
                                    </th>
                                    <td className="text-muted">
                                      {agent.phone1}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      E-mail :
                                    </th>
                                    <td className="text-muted">
                                      {agent.email}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      Location :
                                    </th>
                                    <td className="text-muted">
                                    {agent.firmDetails.address.addressLine1}, 
                                    {agent.firmDetails.address.addressLine2}, 
                                    {agent.firmDetails.address.city}, 
                                    {agent.firmDetails.address.state}, 
                                    {agent.firmDetails.address.zip}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                    Brokerage Firm Affiliation :
                                    </th>
                                    <td className="text-muted">
                                      {agent.agentDetails.brokerageFirmAffiliation}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                    Agent ID/Code :
                                    </th>
                                    <td className="text-muted">
                                      {agent.agentDetails.agentID}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      Status :
                                    </th>
                                    <td className="text-muted">
                                      {agent.status}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageViewAgent;
