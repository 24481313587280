import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";


type FileWithPreview = {
  file: File;
  preview: string;
};

type FormValues = {
  agentId: string;
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  length: string;
  height: string;
  width: string;
  weight: string;
  threshold_qty: string;
  installer: string;
  notes: string;
  image: File | null;
  statuss: string;
};

type Brand = {
  id: string;
  name: string;
  image: string;
};

type Category = {
  id: string;
  name: string;
  image: string;
};

interface Agent {
  id: string;
  companyID: string;
  companyName: string;    
  firstName: string;
  lastName: string;
}

const RealtorAddAgentProduct = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [preview, setPreview] = useState<string | null>(null);
  const navigate = useNavigate();
  const [brands, setBrands] = useState<Brand[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);

  useEffect(() => {
        const fetchAgents = async () => {
          try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/users-search?userType=agent`, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            });
    
            if (!response.ok) {
              throw new Error("Failed to fetch agents");
            }
    
            const data: Agent[] = await response.json();
            console.log(data);
            setAgents(data);
          } catch (err) {
            setError(
              err instanceof Error ? err.message : "An unknown error occurred"
            );
          } finally {
            setLoading(false);
          }
        };
    
        fetchAgents();
      }, [token]);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/brands`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setBrands(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchBrands();
  }, [token]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/categories`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, [token]);

  useEffect(() => {
    return () => {
      if (preview) URL.revokeObjectURL(preview);
    };
  }, [preview]);

  const uploadFile = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("uploadDir", "brands");

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${process.env.REACT_APP_API_URL}/images/upload`, true);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);

      xhr.onload = () => {
        console.log("xhr:", xhr);
        if (xhr.readyState === 4) {
          const response = JSON.parse(xhr.responseText);
          console.log("upload response:", response);

          // Construct the full image URL using the base URL and `path` field
          const imageUrl = `${response.path}`;
          resolve(imageUrl);
        } else {
          reject(new Error("Failed to upload file"));
        }
      };

      xhr.onerror = () => reject(new Error("Network error during file upload"));
      xhr.send(formData);
    });
  };

  const formik = useFormik<FormValues>({
    initialValues: {     
      agentId: "", 
      name: "",
      description: "",
      brand: "",
      category: "",
      item_condition: "",
      purchase_price: "",
      retail_price: "",
      length: "",
      height: "",
      width: "",
      weight: "",
      threshold_qty: "",
      installer: "",
      notes: "",
      image: null,
      statuss: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
      image: Yup.mixed()
        .required("Image is required")
        .test("fileType", "Unsupported file format", (value) =>
          value instanceof File
            ? ["image/jpeg", "image/png", "image/jpg"].includes(value.type)
            : false
        ),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);

      const agentId = values.agentId;

      if (!(values.image instanceof File)) {
        toast.error("Please upload a valid image file.");
        setFormSubmitting(false);
        return;
      }

      try {
        // 1. Upload the file
        const imageUrl = await uploadFile(values.image);
        console.log("Image uploaded successfully:", imageUrl);

        // 2. Submit product details
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              agent_id: agentId,
              inventory_type: "agent",
              product: {
                name: values.name,
                description: values.description,
                brand: values.brand,
                category: values.category,
                item_condition: values.item_condition,
                purchase_price: Number(values.purchase_price),
                retail_price: Number(values.retail_price),
                package_dimensions: {
                  length: Number(values.length),
                  height: Number(values.height),
                  width: Number(values.width),
                  weight: Number(values.weight),
                },
                threshold_qty: Number(values.threshold_qty),
                image: { url: imageUrl },
                status: values.statuss,
                is_rotational: false,
                linked_mode: "standalone",
              }
            }),
          }
        );

        if (!response.ok) throw new Error("Failed to save data");

        const result = await response.json();
        navigate(`/realtor/agentInventory`, {
          state: { successMessage: "Product added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Product.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  //   if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="realtor">
      <div className="card">
        <div className="card-body">
          <h2 className="ps-4">Add Agent Product </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-1 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          
                          <div className="row">
                          <div className="col-md-4 mb-3">
                              <label className="form-label">Agent </label>
                              <select
                                className="form-control"
                                id="agentId"
                                name="agentId"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.agentId}
                              >
                                <option value="">- Select -</option>
                                {agents !== null &&
                                  agents.map((agent) => (
                                    <option value={agent.id}>
                                      {agent.firstName}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.agentId && formik.errors.agentId ? (
                                <div className="text-danger">
                                  {formik.errors.agentId}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-8 mb-3">
                              <label className="form-label">
                                Product Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="text-danger">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Brand</label>
                              <select
                                className="form-control"
                                id="brand"
                                name="brand"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.brand}
                              >
                                <option value="">- Select -</option>
                                {brands !== null &&
                                  brands.map((brand) => (
                                    <option value={brand.name}>
                                      {brand.name}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.brand && formik.errors.brand ? (
                                <div className="text-danger">
                                  {formik.errors.brand}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">Category</label>
                              <select
                                className="form-control"
                                id="category"
                                name="category"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category}
                              >
                                <option value="">- Select -</option>
                                {categories !== null &&
                                  categories.map((category) => (
                                    <option value={category.name}>
                                      {category.name}
                                    </option>
                                  ))}
                              </select>
                              {formik.touched.category &&
                              formik.errors.category ? (
                                <div className="text-danger">
                                  {formik.errors.category}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Item Condition
                              </label>
                              <select
                                className="form-control"
                                id="item_condition"
                                name="item_condition"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.item_condition}
                              >
                                <option value="">- Select -</option>
                                <option value="new">New</option>
                                <option value="used">Used</option>
                                <option value="damaged">Damaged</option>
                              </select>
                              {formik.touched.item_condition &&
                              formik.errors.item_condition ? (
                                <div className="text-danger">
                                  {formik.errors.item_condition}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Purchase Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="purchase_price"
                                name="purchase_price"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.purchase_price}
                              />
                              {formik.touched.purchase_price &&
                              formik.errors.purchase_price ? (
                                <div className="text-danger">
                                  {formik.errors.purchase_price}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Retail Price{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="retail_price"
                                name="retail_price"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.retail_price}
                              />
                              {formik.touched.retail_price &&
                              formik.errors.retail_price ? (
                                <div className="text-danger">
                                  {formik.errors.retail_price}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Threshold Quantity{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="number"
                                className="form-control"
                                id="threshold_qty"
                                name="threshold_qty"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.threshold_qty}
                              />
                              {formik.touched.threshold_qty &&
                              formik.errors.threshold_qty ? (
                                <div className="text-danger">
                                  {formik.errors.threshold_qty}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="text-danger">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Product Description{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                rows={4}
                              ></textarea>
                              {formik.touched.description &&
                              formik.errors.description ? (
                                <div className="text-danger">
                                  {formik.errors.description}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Dimensions/Measurements (Inches)
                              </label>
                              <div className="row">
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="length"
                                    name="length"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.length}
                                    placeholder="Length"
                                  />
                                  {formik.touched.length &&
                                  formik.errors.length ? (
                                    <div className="text-danger">
                                      {formik.errors.length}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="height"
                                    name="height"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.height}
                                    placeholder="Height"
                                  />
                                  {formik.touched.height &&
                                  formik.errors.height ? (
                                    <div className="text-danger">
                                      {formik.errors.height}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="width"
                                    name="width"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.width}
                                    placeholder="Width"
                                  />
                                  {formik.touched.width &&
                                  formik.errors.width ? (
                                    <div className="text-danger">
                                      {formik.errors.width}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Weight(in gms)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="weight"
                                name="weight"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.weight}
                              />
                              {formik.touched.weight && formik.errors.weight ? (
                                <div className="text-danger">
                                  {formik.errors.weight}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Image <span className="text-danger">*</span>
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="image"
                                name="image"
                                onChange={(event) => {
                                  const file = event.currentTarget.files?.[0];
                                  if (file) {
                                    formik.setFieldValue("image", file);
                                    setPreview(URL.createObjectURL(file)); // Optional: show preview
                                  }
                                }}
                                onBlur={formik.handleBlur}
                              />
                              {preview && (
                                <img
                                  src={preview}
                                  alt="Preview"
                                  style={{
                                    maxWidth: "100px",
                                    marginTop: "10px",
                                  }}
                                />
                              )}
                              {formik.touched.image && formik.errors.image ? (
                                <div className="text-danger">
                                  {formik.errors.image}
                                </div>
                              ) : null}
                            </div>

                            <div className="col-md-4 mb-3">
                              <label className="form-label">
                                Installer 
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="installer"
                                name="installer"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.installer}
                              />
                              {formik.touched.installer &&
                              formik.errors.installer ? (
                                <div className="text-danger">
                                  {formik.errors.installer}
                                </div>
                              ) : null}
                            </div>
                            <div className="col-md-12 mb-3">
                              <label className="form-label">
                                Notes for the Installer{" "}
                              </label>
                              <textarea
                                className="form-control"
                                id="notes"
                                name="notes"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.notes}
                              ></textarea>
                              {formik.touched.notes && formik.errors.notes ? (
                                <div className="text-danger">
                                  {formik.errors.notes}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4">
                        <button className="btn btn-success w-100" type="submit">
                          Save Inventory Item
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorAddAgentProduct;
