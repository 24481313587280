import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type TaxGroup = {
  id: string;
  name: string;
  status: string;
};

const SignageEditTaxGroup = () => {
  const { taxId } = useParams<{ taxId: string }>();
  const [taxGroup, setTaxGroup] = useState<TaxGroup | null>(null); // Fixed destructuring issue
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");

  const navigate = useNavigate();

  // Fetch tax group data
  useEffect(() => {
    const fetchTaxGroup = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tax-groups/${taxId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch tax group data");
        }
        const data = await response.json();
        setTaxGroup({
          id: data.id || "",
          name: data.name || "",
          status: data.status ? "Active" : "Inactive",
        });
      } catch (err) {
        setError("Error fetching tax group data. Please try again later.");
        console.error("Error fetching tax group data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (taxId) {
      fetchTaxGroup();
    }
  }, [taxId, token]);

  const formik = useFormik({
    enableReinitialize: true, // Ensures form updates with fetched data
    initialValues: {
      name: taxGroup?.name || "",
      statuss: taxGroup?.status || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      const groupStatus = values.statuss === "Active"?true:false;

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tax-groups/${taxId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.name,
              status: groupStatus,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        toast.success("Tax group updated successfully!");
        navigate("/signage/taxGroups", {
          state: { successMessage: "Group updated successfully!" },
        });
      } catch (err) {
        console.error("Error updating tax group:", err);
        toast.error("Failed to update tax group.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  // Render loading or error state
  if (loading) return <div>Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Tax Group</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="text-danger">{formik.errors.name}</div>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="form-label">
                          Status <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="statuss"
                          name="statuss"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.statuss}
                        >
                          <option value="">- Select -</option>
                          <option value="Active">Active</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                        {formik.touched.statuss && formik.errors.statuss && (
                          <div className="text-danger">{formik.errors.statuss}</div>
                        )}
                      </div>
                    </div>

                    <div className="mt-4">
                      <button
                        className="btn btn-success w-100"
                        type="submit"
                        disabled={formSubmitting}
                      >
                        {formSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageEditTaxGroup;
