import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type RotationalType = {
    id: string;
    name: string;
    count: number;
    status: string;
};

const SignageEditRotationalType = () => {
  const { rotationaltypeId } = useParams<{ rotationaltypeId: string }>();
  const [rotationaltype, setRotationalType] = useState<RotationalType | null>(null);
  const [loading, setLoading] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  // Fetch rotationaltype data
  useEffect(() => {
    const fetchRotationalType = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/rotational-product-types/${rotationaltypeId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch rotationaltype data");
        }
        const data = await response.json();
        setRotationalType(data);
      } catch (err) {
        console.error("Error fetching rotationaltype data:", err);
        toast.error("Error fetching rotationaltype data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (rotationaltypeId) fetchRotationalType();
  }, [rotationaltypeId, token]);

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
        name: "",
        statuss: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);      

      try {        
        // Update rotationaltype data
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/rotational-product-types/${rotationaltypeId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                name: values.name,
                status: values.statuss === "active",
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update rotationaltype");
        }

        toast.success("Rotational Type updated successfully!");
        navigate("/signage/rotationaltypes", {
          state: { successMessage: "Rotational Type updated successfully!" },
        });
      } catch (err) {
        console.error(err);
        toast.error("Failed to update rotational type.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  // Set Formik values when rotationaltype is fetched
  useEffect(() => {
    if (rotationaltype) {
      formik.setValues({
        name: rotationaltype.name,
        statuss: (rotationaltype.status)?"active":"inactive",
      });
    }
  }, [rotationaltype]);

  if (loading) return <div>Loading...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit RotationalType</h2>

          <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            {/* Name Field */}
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                              />
                              {formik.touched.name && formik.errors.name ? (
                                <div className="invalid-feedback">
                                  {formik.errors.name}
                                </div>
                              ) : null}
                            </div>
                            
                            {/* Status Field */}
                            <div className="col-md-6 mb-3">
                              <label className="form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <select
                               className="form-control"
                                id="statuss"
                                name="statuss"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.statuss}
                              >
                                <option value="">- Select -</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </select>
                              {formik.touched.statuss &&
                              formik.errors.statuss ? (
                                <div className="invalid-feedback">
                                  {formik.errors.statuss}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                       
                      </div>

                      {/* Submit Button */}
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Submitting..." : "Submit"}
                        </button>
                      </div>
                    </form>
        </div>
      </div>
    </div>
  );
};

export default SignageEditRotationalType;
