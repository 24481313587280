import React, { useState, useRef, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { sub } from "date-fns";

const SignageAddBrokerageFirm = () => {
  const [loading, setLoading] = useState(true);
  const [licenseType, setLicenseType] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      companyName: "",
      firstName: "",
      lastName: "",
      phone: "",
      phone2: "",
      email: "",
      statuss: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string()
        .min(2, "Company name must be at least 2 characters long")
        .max(50, "Company name cannot be longer than 50 characters"),
      firstName: Yup.string()
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values); // For debugging
      setFormSubmitting(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/user/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: values.email,
              password: "password123",
              userType: "brokerage_firm",
              firstName: values.firstName,
              lastName: values.lastName,
              phone1: values.phone,
              phone2: values.phone2,
              firmDetails: {
                companyName: values.companyName,
                companyWebsite: "https://example.com",
                address: null,
              },
              licenseType: "BROKERAGE_FIRM",
              licenseValidity: null,
              billingCycle: null,
              billingCyclePrice: 0,
              paymentMethod: null,
              status: values.statuss,
              modulePermissions: null,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        // toast.success("License added successfully!");
        console.log("Form submission successful:", result);
        navigate("/signage/brokerageFirms", {
          state: { successMessage: "Brokerage firm added successfully!" },
        });
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to add Brokerage firm.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  // if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Add Brokerage Firm </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-2 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-0">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Company Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="companyName"
                            name="companyName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyName}
                          />
                          {formik.touched.companyName &&
                          formik.errors.companyName ? (
                            <div className="text-danger">
                              {formik.errors.companyName}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="text-danger">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="text-danger">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Secondary Phone Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="phone2"
                            name="phone2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone2}
                          />
                          {formik.touched.phone2 && formik.errors.phone2 ? (
                            <div className="text-danger">
                              {formik.errors.phone2}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            required
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                            required
                          >
                            <option value="">- Select -</option>
                            <option value="active">Active </option>
                            <option value="inactive">Inactive </option>
                            <option value="expired">Expired </option>
                            <option value="pending">Pending </option>
                            <option value="suspended">Suspended </option>
                          </select>
                          <div className="invalid-feedback">
                            Please select a Status
                          </div>
                        </div>
                      </div>

                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          // disabled={formSubmitting}
                        >
                          {formSubmitting ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageAddBrokerageFirm;
