import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/app.css";

type TempObject = {
  agent_id: string;
  products: AgentProduct[];
};

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  name: string;
  category: string;
  status: string;
  item_condition: string;
  package_dimensions: Dimensions;
  image: Image | null;
  linked_installer: LinkedInstaller;
  variants: Variant[];
};

type LinkedInstaller = {
  id: string;
  name: string;
  requested_quantity: number;
  warehouse_info: {
    id: string;
    label: string;
    bin_location: string;
    linked_product_quantity: number;
  };
};

type Variant = {
  inventory: {
    location_id: string;
    location_name: string;
    on_hand: number;
  }[];
};

type Image = { url: string };

type AgentProduct = {
  product_id: string;
  agent_id: string;
  product: Product;
};

const SignageAgentInventory = () => {
  const [agentProducts, setAgentProducts] = useState<AgentProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [deleting, setDeleting] = useState<string | null>(null);

  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/signage/incoming-linked-products`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        // if (!response.ok) {
        //   throw new Error(`Error: ${response.statusText}`);
        // }

        // Check if the response is JSON
        const contentType = response.headers.get("Content-Type");
        let data = [];
        if (contentType && contentType.includes("application/json")) {
          data = await response.json();
          setAgentProducts(data);
        } else {
          throw new Error("No products found");
        }
  
        // const responseText = await response.text();
        // const data = responseText ? JSON.parse(responseText) : [];
        
      } catch (err) {
        setError(err instanceof Error ? err.message : "Failed to load inventory.");
      } finally {
        setLoading(false);
      }
    };
  
    fetchProducts();
  }, [token, userId]);
  

  useEffect(() => {
    if(agentProducts!==null && agentProducts.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [agentProducts]);

  const handleDelete = async (productId: string) => {
    if (!window.confirm("Are you sure you want to delete this product?")) return;

    try {
      setDeleting(productId);
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/agent/${userId}/inventory/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the product.");
      }

      setAgentProducts((prev) =>
        prev.filter((product) => product.product_id !== productId)
      );
      toast.success("Product deleted successfully!");
      navigate(0);
    } catch (err) {
      toast.error(err instanceof Error ? err.message : "Error deleting product.");
    } finally {
      setDeleting(null);
    }
  };

  if (loading) return <div>Loading...</div>;

  if (error) return <div className="text-danger">Error: {error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Agent Inventory</h2>
          
          <div className="table-responsive">
            <table
              id="transactions"
              className="table table-bordered dt-responsive nowrap table-striped align-middle"
              style={{ width: "100%" }}
            >
              <thead>
                <tr>
                  <th>S.No.</th>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Category</th>
                  <th>Qty</th>
                  <th>Status</th>
                  <th>Condition</th>
                  <th>Dimensions</th>
                  <th>Linked Installer</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {agentProducts!==null && agentProducts.map((agentProduct, index) => (
                  <tr key={agentProduct.product_id}>
                    <td>{index + 1}</td>
                    <td>
                      {agentProduct?.product.image?.url ? (
                        <img
                          src={`https://dev.reelty.app/${agentProduct?.product?.image?.url || ""}`}
                          alt="Product"
                          height={50}
                        />
                      ) : (
                        "No Image"
                      )}
                    </td>
                    <td>{agentProduct?.product?.name}</td>
                    <td>{agentProduct?.product?.category}</td>
                    <td>
                      {agentProduct?.product?.linked_installer?.warehouse_info?.linked_product_quantity || 0}
                    </td>

                    <td>{agentProduct?.product?.status}</td>
                    <td>{agentProduct?.product?.item_condition}</td>
                    <td>
                      {`${agentProduct?.product?.package_dimensions?.length} x ${agentProduct?.product?.package_dimensions?.height} x ${agentProduct?.product?.package_dimensions?.width}`}
                    </td>
                    <td>{agentProduct?.product?.linked_installer?.name || "None"}</td>
                    <td>
                      <Link
                        to={`/agent/viewProduct/${agentProduct?.product_id}`}
                        className="btn btn-info me-2"
                      >
                        View
                      </Link>
                      {/* <Link
                        to={`/agent/editProduct/${agentProduct?.product_id}`}
                        className="btn btn-warning me-2"
                      >
                        Edit
                      </Link>
                      <button
                        onClick={() => handleDelete(agentProduct?.product_id)}
                        className="btn btn-danger"
                        disabled={deleting === agentProduct?.product_id}
                      >
                        {deleting === agentProduct?.product_id ? "Deleting..." : "Delete"}
                      </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageAgentInventory;
