import React, { useState, useEffect, useRef } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom'; // Import useParams to get groupId from URL
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface Agent {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  city: string;
}

type Group = {
  id: number;
  groupName: string;
  status: string; 
  agents: Agent[] | null;
};

const RealtorEditGroup = () => {
  const { groupId } = useParams<{ groupId: string }>(); // Get groupId from URL params
  const token = localStorage.getItem("sessionToken");
  const [group, setGroup] = useState<Group | null>(null); // State to hold the group data
  const [formSubmitting, setFormSubmitting] = useState(false);
  const navigate = useNavigate();
  // Fetch group details when groupId changes
  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/groups/${groupId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setGroup({
          id: data.id,
          groupName: data.name,
          status: data.status,
          agents: data.agents,
        });

      } catch (error) {
        console.error('Error fetching group data:', error);
      }
    };

    if (groupId) {
      fetchGroupData();
    }
  }, [groupId]);

  // Initialize formik
  const formik = useFormik({
    initialValues: {
      groupName: group?.groupName || '',
      statuss: (group?.status)?'Active':'Inactive',
    },
    enableReinitialize: true, // Enable reinitialization when group data is fetched
    validationSchema: Yup.object({
      groupName: Yup.string()
        .min(2, 'Group name must be at least 2 characters long')
        .max(50, 'Group name cannot be longer than 50 characters')
        .required('Group Name is required'),
      statuss: Yup.string().required('Status is required'),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values);
      setFormSubmitting(true);
      const groupStatus = (values.statuss=='Active')?true:false;
      const agents = (group?.agents==null)?[]:group?.agents;
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/groups/${groupId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              name: values.groupName || "",
              status: groupStatus,
              agents: agents,              
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        // toast.success("Group updated successfully!");
        // console.log("Form submission successful:", result);
        setTimeout(() => {
          navigate("/realtor/groups", {
            state: { successMessage: "Group updated successfully!" },
          });
        }, 100);
        
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update Group.");
      } finally {
        setFormSubmitting(false);
      }
    },
  }); 

  
  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Group </h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-0">
                  <div className="p-2 mt-1">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Group Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="groupName"
                            name="groupName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.groupName}
                            required
                          />
                          {formik.touched.groupName && formik.errors.groupName ? (
                            <div className="text-danger">{formik.errors.groupName}</div>
                          ) : null}
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-control"
                            id="statuss"
                            name="statuss"
                            required
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                          >
                            <option value="">- Select -</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                          <div className="invalid-feedback">
                            Please select status
                          </div>
                        </div>
                        </div>
                        <div className="mt-4">
                          <button className="btn btn-success w-100" type="submit">
                            Update
                          </button>
                        </div>
                      
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorEditGroup;
