import { useLocation } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "../../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FormikValues } from "formik";

type Dimensions = {
  length: number;
  height: number;
  width: number;
  weight: number;
};

type Product = {
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  package_dimensions: Dimensions;
  // notes: string;
  image: Image | null;
  status: string;
  is_rotational: boolean;
  linked_mode: string;
  linked_installer: LinkedInstaller;
  variants: Variant[];
};

type Image = {
  url: string;
};

type AgentProduct = {
  product_id: string;
  agent_id: string;
  product: Product;
  created_by: { id: string; name: string };
};

type LinkedInstaller = {
  id: string;
  name: string;
  requested_quantity: number;
  warehouse_info: {
    id: string;
    label: string;
    bin_location: string;
    linked_product_quantity: number;
  };
};

type Warehouse = {
  id: string;
  label: string;
  status: string;
};

type Variant = {
  variant_name: string;
  sku: string;
  attributes: string;
  purchase_price: number;
  retail_price: number;
  package_dimensions: {
    length: number;
    height: number;
    width: number;
    weight: number;
    weight_type: string;
  };
  inventory: [
    {
      warehouse_id: string;
      warehouse_name: string;
      on_hand: number;
      available: number;
      reserved: number;
    }
  ];
};

const SignageIncomingProducts = () => {
  const [agentProducts, setAgentProducts] = useState<AgentProduct[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [warehouses, setWarehouses] = useState<Warehouse[]>([]);

  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();

  const fetchProducts = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/signage/incoming-products`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setAgentProducts(data);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchWarehouses = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/warehouses`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setWarehouses(data);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    }
  };

  useEffect(() => {
    fetchProducts();
    fetchWarehouses();
  }, [token]);

  // const handleSaveLocation = async (agentId: string, productId: string, values: FormikValues) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/agent/${agentId}/inventory/${productId}/status`,
  //       {
  //         method: "PUT",
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           link_status: "accepted",
  //           warehouse_info: {
  //             id: values.warehouseId,
  //             label: values.label,
  //             bin_location: values.bin_location,
  //           },
  //         }),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to save location.");
  //     }

  //     toast.success("Location saved successfully!");
  //   } catch (err) {
  //     toast.error("Failed to save location.");
  //     setError(
  //       err instanceof Error
  //         ? err.message
  //         : "An unknown error occurred while deleting."
  //     );
  //     setDeleting(null); // Clear the deleting state
  //   }
  // ;}

  useEffect(() => {
    if (agentProducts != null && agentProducts.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
        searching: true,
        pageLength: 10,
      });

      return () => {
        table.destroy();
      };
    }
  }, [agentProducts]);

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Incoming Products </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th>
                          <th>Installer Status</th>
                          <th>Status</th>
                          <th>Office</th>
                          <th>Agent Name</th>
                          <th>Product Name</th>
                          <th>Product Category</th>
                          <th>Qty</th>
                          <th>Condition</th>
                          <th>Dimensions/Measurements</th>
                          <th>Weight</th>
                          <th>Warehouse</th>
                          <th>Bin Location</th>
                          <th>Rotational Product</th>
                          {/* <th>Actions</th> */}
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {agentProducts &&
                          agentProducts.map((agentProduct, index) => {
                            const product = agentProduct?.product;
                            const linkedInstaller = product?.linked_installer;
                            const packageDimensions =
                              product?.package_dimensions;

                            if (linkedInstaller?.requested_quantity === 0)
                              return null;

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{product?.linked_mode}</td>
                                <td>{product?.status}</td>
                                <td></td>
                                <td>{agentProduct?.created_by?.name}</td>
                                <td>
                                  <Link
                                    to={`/signage/editIncomingProduct/${agentProduct?.created_by?.id}/${agentProduct?.product_id}`}
                                    className="waves-effect waves-light me-2"
                                  >
                                    {product?.name}
                                  </Link>
                                </td>
                                <td>{product?.category}</td>
                                <td>{linkedInstaller?.requested_quantity}</td>
                                <td>{product?.item_condition}</td>
                                <td>
                                  {packageDimensions?.length} x{" "}
                                  {packageDimensions?.height} x{" "}
                                  {packageDimensions?.width}
                                </td>
                                <td>{packageDimensions?.weight}</td>
                                <td>
                                  {linkedInstaller?.warehouse_info?.label || ""}
                                </td>
                                <td>
                                  {linkedInstaller?.warehouse_info
                                    ?.bin_location || ""}
                                </td>
                                <td>{product?.is_rotational ? "Yes" : "No"}</td>
                                <td>
                                  <Link
                                    to={`/signage/editIncomingProduct/${agentProduct?.created_by?.id}/${agentProduct?.product_id}`}
                                    className="btn btn-info waves-effect waves-light me-2"
                                  >
                                    <i className="ri-pencil-fill align-bottom me-2"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageIncomingProducts;
