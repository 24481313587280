import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "../../../node_modules/datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Office = {
  id: number;
  officeName: string;
  officeType: string;
  streetAddress1: string;
  streetAddress2?: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  phone: string;
};

type Dimensions = {
  length: string;
  height: string;
  width: string;
  weight: string;
};

type Product = {
  product_id: string;
  name: string;
  description: string;
  brand: string;
  category: string;
  item_condition: string;
  purchase_price: string;
  retail_price: string;
  package_dimensions: Dimensions;
  installer: string;
  // notes: string;
  image: Image | null;
  status: string;
};

type Image = {
  url: string;
};

const TeammemberViewOffice = () => {
  const { officeId } = useParams<{ officeId: string }>();
  const [office, setOffice] = useState<Office>();
  const [products, setProducts] = useState<Product[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");
  const userId = localStorage.getItem("userId");
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  // Fetch the office data based on officeId
  useEffect(() => {
    const fetchOfficeData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/offices/${officeId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch office data: ${response.statusText}`
          );
        }

        const data = await response.json();
        setOffice({
          id: data.id,
          officeName: data.officeName,
          officeType: data.officeType,
          streetAddress1: data.officeAddress.addressLine1,
          streetAddress2: data.officeAddress.addressLine2,
          city: data.officeAddress.city,
          state: data.officeAddress.state,
          country: data.officeAddress.country,
          zipcode: data.officeAddress.zip,
          phone: data.officeAddress.phone,
        });
      } catch (error) {
        toast.error("Error fetching office data");
        console.error("Error fetching office data:", error);
        setError(error instanceof Error ? error.message : "Unknown error");
      }
    };

    if (officeId) {
      fetchOfficeData();
    }
  }, [officeId]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/office/${officeId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setProducts(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [token]);

  useEffect(() => {
    if (products!=null && products.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [products]);

  const handleDelete = async (productId: string) => {
    setDeleting(productId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/office/inventory/${productId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the tax group.");
      }

      toast.success("GeoLocation deleted successfully!");
      // Update the state by removing the deleted license
      setProducts((prev) =>
        prev.filter((product) => product.product_id !== productId)
      );
      setDeleting(null); // Clear the deleting state
      navigate(0)
    } catch (err) {
      toast.error("Failed to delete the tax group.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

  //   if (loading) {
  //     return <div>Loading...</div>;
  //   }

  return (
    <div className="teammember">
      <div className="row">
        <div className="col-md-9">
          <div className="card">
            <div className="card-body">
              <h2>Office Details</h2>
              <table className="table">
                <tr>
                  <td><strong>Office Name:</strong></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{office?.officeName || ""}</td>
                </tr>
                <tr>
                  <td><strong>Office Type:</strong></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{office?.officeType || ""}</td>
                </tr>
                <tr>
                  <td><strong>Address:</strong></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{office?.streetAddress1 || ""}<br />&nbsp;&nbsp;&nbsp;&nbsp;{office?.streetAddress2 || ""}</td>
                </tr>
                <tr>
                  <td><strong>City:</strong></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{office?.city || "N/A"}</td>
                </tr>
                <tr>
                  <td><strong>State:</strong></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{office?.state || "N/A"}</td>
                </tr>
                <tr>
                  <td><strong>Country:</strong></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{office?.country || "N/A"}</td>
                </tr>
                <tr>
                  <td><strong>Zip code:</strong></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{office?.zipcode || "N/A"}</td>
                </tr>
                <tr>
                  <td><strong>Phone:</strong></td>
                  <td>&nbsp;&nbsp;&nbsp;&nbsp;{office?.phone || "N/A"}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
};

export default TeammemberViewOffice;
