import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";

type TaxRule = {
    id: string;
    country: string;
    state: string;
    stateCode: string;
    county: string;
    countyCode: string;
    zipCodes: string;
    taxRate: number;
    comments: string;
    status: string;
};

type CountyZipList = {
    county: string;
    zipList: string[];
  };
  
  type GeoLocation = {
    id: string;
    country: string;
    countryCode: string;
    state: string;
    stateCode: string;
    countyZipList: CountyZipList;  
  };

const SignageEditTaxRule = () => {
  const { taxId } = useParams<{ taxId: string }>();
  const [taxRule, setTaxRule] = useState<TaxRule | null>(null); // Fixed destructuring issue
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");
  const [geolocations, setGeoLocations] = useState<GeoLocation[]>([]);
  const [filteredCounties, setFilteredCounties] = useState<CountyZipList[]>([]);

  const navigate = useNavigate();

  // Fetch tax rule data
  useEffect(() => {
    const fetchTaxRule = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tax-rules/${taxId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch tax rule data");
        }
        const data = await response.json();
        setTaxRule({
          id: data.id || "",
          country: data.country || "",
          state: data.state || "",
          stateCode: data.stateCode || "",
          county: data.county || "",
          countyCode: data.countyCode || "",
          zipCodes: data.zipCodes.join(",") || "",
          taxRate: data.taxRate || "",
          comments: data.comments || "",
          status: data.status ? "Active" : "Inactive",
        });
      } catch (err) {
        setError("Error fetching tax rule data. Please try again later.");
        console.error("Error fetching tax rule data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (taxId) {
      fetchTaxRule();
    }
  }, [taxId, token]);

  // Fetch countries
  useEffect(() => {
    const fetchGeoLocations = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/geo-locations`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setGeoLocations(data);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchGeoLocations();
  }, [token]);

  useEffect(() => {
    if (taxRule && taxRule.country) {
      // Populate counties based on the taxRule's country
      const counties = geolocations
        .filter((geo) => geo.country === taxRule.country)
        .flatMap((geo) => geo.countyZipList);
  
      setFilteredCounties(counties);
    }
  }, [taxRule, geolocations]);

  const formik = useFormik({
    enableReinitialize: true, // Ensures form updates with fetched data
    initialValues: {
        country: taxRule?.country || "",
        state: taxRule?.state || "",
        stateCode: taxRule?.stateCode || "",
        county: taxRule?.county || "",
        countyCode: taxRule?.countyCode || "",
        zipCodes: taxRule?.zipCodes || "",
        taxRate: taxRule?.taxRate || "",
        comments: taxRule?.comments || "",
        statuss: taxRule?.status || "",
    },
    validationSchema: Yup.object({
      country: Yup.string()
        .min(2, "Name must be at least 2 characters long")
        .max(50, "Name cannot be longer than 50 characters")
        .required("Name is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
      const ruleStatus = (values.statuss==='Active')?true:false;
      const zipCodes = values.zipCodes.split(",");

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tax-rules/${taxId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                country: values.country,
                state: values.state,
                stateCode: values.stateCode,
                county: values.county,
                countyCode: values.countyCode,
                zipCodes: zipCodes,
                taxRate: Number(values.taxRate),
                comments: values.comments,
                status: ruleStatus
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        toast.success("Tax rule updated successfully!");
        navigate("/signage/taxRules", {
          state: { successMessage: "Rule updated successfully!" },
        });
      } catch (err) {
        console.error("Error updating tax rule:", err);
        toast.error("Failed to update tax rule.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCountry = e.target.value;
    formik.setFieldValue("country", selectedCountry);
    formik.setFieldValue("state", "");
    formik.setFieldValue("county", ""); // Reset county when country changes

    // Find states for the selected country
    const states = geolocations
      .filter((geo) => geo.country === selectedCountry)
      .map((geo) => geo.state);

    if (states.length > 0) {
      // If there is only one state, auto-populate the state field
      formik.setFieldValue("state", states[0]);
    } else {
      // Clear state if no unique state exists
      formik.setFieldValue("state", "");
    }    

    const counties = geolocations
    .filter((geo) => geo.country === selectedCountry)
    .flatMap((geo) => geo.countyZipList);

    setFilteredCounties(counties);
  };

  const handleCountyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCounty = e.target.value;
    formik.setFieldValue("county", selectedCounty);
    formik.setFieldValue("zipCodes", "");
    const zips = filteredCounties
      .filter((cnt) => cnt.county === selectedCounty)
      .flatMap((cnt) => cnt.zipList);
console.log('Zips:', zips);
      if (zips.length > 0) {
        // If there is only one state, auto-populate the state field
        formik.setFieldValue("zipCodes", zips.join(","));
      } else {
        // Clear state if no unique state exists
        formik.setFieldValue("zipCodes", "");
      } 
  };

  // Render loading or error state
  if (loading) return <div>Loading...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Edit Tax Rule</h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                    <div className="col-md-6 mb-3">
                <label className="form-label">
                  Country <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  onChange={handleCountryChange}
                  value={formik.values.country}
                >
                  <option value="">-Select-</option>
                  {geolocations.map((location) => (
                    <option key={location.country} value={location.country}>
                      {location.country}
                    </option>
                  ))}
                </select>
                {formik.touched.country && formik.errors.country ? (
                  <div className="text-danger">{formik.errors.country}</div>
                ) : null}
              </div>
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            State <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="state"
                              name="state"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.state}
                            />
                            {formik.touched.state && formik.errors.state ? (
                              <div className="text-danger">{formik.errors.state}</div>
                            ) : null}
                          </div> 
                          <div className="col-md-6 mb-3">
                <label className="form-label">
                  County <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  id="county"
                  name="county"
                  onChange={handleCountyChange}
                  value={formik.values.county}
                >
                  <option value="">-Select-</option>
                  {filteredCounties.map((countyObj) => (
                    <option key={countyObj.county} value={countyObj.county}>
                      {countyObj.county}
                    </option>
                  ))}
                </select>
                {formik.touched.county && formik.errors.county ? (
                  <div className="text-danger">{formik.errors.county}</div>
                ) : null}
              </div> 
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Zip Codes <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="zipCodes"
                              name="zipCodes"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.zipCodes}
                            />
                            {formik.touched.zipCodes && formik.errors.zipCodes ? (
                              <div className="text-danger">{formik.errors.zipCodes}</div>
                            ) : null}
                          </div> 
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                            Tax Rate <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="taxRate"
                              name="taxRate"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.taxRate}
                            />
                            {formik.touched.taxRate && formik.errors.taxRate ? (
                              <div className="text-danger">{formik.errors.taxRate}</div>
                            ) : null}
                          </div> 
                          <div className="col-md-6 mb-3">
                            <label className="form-label">
                              Status{" "} <span className="text-danger">*</span> 
                            </label>
                            <select className="form-control" id="statuss" name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}                            
                            >
                              <option value="">- Select -</option>
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                            {formik.touched.statuss && formik.errors.statuss ? (
                              <div className="text-danger">{formik.errors.statuss}</div>
                            ) : null}
                          </div> 
                          <div className="col-md-12 mb-3">
                            <label className="form-label">
                            Comments <span className="text-danger">*</span>
                            </label>
                            <textarea
                              className="form-control"
                              id="comments"
                              name="comments"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.comments}
                            >
                            </textarea>
                            {formik.touched.comments && formik.errors.comments ? (
                              <div className="text-danger">{formik.errors.comments}</div>
                            ) : null}
                          </div> 

                      
                    </div>

                    <div className="mt-4">
                      <button
                        className="btn btn-success w-100"
                        type="submit"
                        disabled={formSubmitting}
                      >
                        {formSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageEditTaxRule;
