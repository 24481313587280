import React, { useState, useRef, useEffect, useCallback } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import debounce from "lodash/debounce";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import pl from "assets/libs/moment/src/locale/pl";

interface LicenseType {
  id: string;
  name: string;
  code: string;
}

interface Module {
  id: string;
  name: string;
  module_code: string;
  license_type_code: string;
  description: string;
  status: boolean;
  volume: number;
  sub_modules: SubModule[];
}

type SubModule = {
  id: string;
  name: string;
  value: string;
  description: string;
  status: boolean;
  volume: number;
  used_volume: number;
};

interface License {
  id: string;
  companyName: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  statuss: string;
  licenseValidity: string | null;
  billingCycle: string | null;
  billingCyclePrice: number | null;
  paymentMethod: string;
  licenseType: string;
  modules: Module[]; 
}

const libraries: Array<"places"> = ["places"];
const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

interface Plan {
  id: string;
  planName: string;
  description: string;
}

const SuperAdminEditLicense = () => {
  const [plans, setPlans] = useState<Plan[]>([]);
  const { licenseId } = useParams<{ licenseId: string }>();
  const [licenseTypes, setLicenseTypes] = useState<LicenseType[]>([]);
  const [loading, setLoading] = useState(true);
  const [license, setLicense] = useState<License | null>(null);
  const [defaultModules, setDefaultModules] = useState<Module[]>([]);
  const [userModules, setUserModules] = useState<Module[]>([]);
  const [updatedModules, setUpdatedModules] = useState<Module[]>([]);
  const [loadingModules, setLoadingModules] = useState(false);
  const [error, setError] = useState<string>("");
  const [formSubmitting, setFormSubmitting] = useState(false);
  const token = localStorage.getItem("sessionToken");

  const navigate = useNavigate();

// Fetch Plans
  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/plan-profiles`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        setPlans(data.plans);
      } catch (err) {
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
      }
    };
    fetchPlans();
  }, [token]);

  // Fetch License Data
  useEffect(() => {
    const fetchLicense = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${licenseId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch license data");
        }

        const data = await response.json();
        console.log("Fetched License Data:", data);

        if (data && data.firmDetails && data.firmDetails.address) {
          const fetchedLicense: License = {
            id: data.id || "",
            companyName: data.firmDetails.companyName || "",
            jobTitle: data.jobTitle || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            email: data.email || "",
            phone: data.phone1 || "",
            addressLine1: data.firmDetails.address.addressLine1 || "",
            addressLine2: data.firmDetails.address.addressLine2 || "",
            city: data.firmDetails.address.city || "",
            state: data.firmDetails.address.state || "",
            zip: data.firmDetails.address.zip || "",
            country: data.firmDetails.address.country || "",
            statuss: data.status || "Active",
            licenseValidity: data.licenseValidity || null,
            billingCycle: data.billingCycle || null,
            billingCyclePrice: data.billingCyclePrice || null,
            paymentMethod: data.paymentMethod || "",
            licenseType: data.licenseType || "",
            modules: Array.isArray(data.modulePermissions)
              ? data.modulePermissions
              : [],
          };

          setLicense(fetchedLicense);
          setUserModules(
            Array.isArray(data.modulePermissions) ? data.modulePermissions : []
          );
          setUpdatedModules(
            Array.isArray(data.modulePermissions) ? data.modulePermissions : []
          );
          console.log(updatedModules);
          console.log(
            "User Modules:",
            Array.isArray(data.modulePermissions) ? data.modulePermissions : []
          );
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching license data:", error);
        setError("Failed to fetch license data.");
      } finally {
        setLoading(false);
      }
    };

    if (!license) {
      fetchLicense();
    }
  }, [licenseId, license, token]);

  // Fetch License Types
  useEffect(() => {
    const fetchLicenseTypes = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/license-types`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch license types");
        }

        const data = await response.json();
        setLicenseTypes(data);
      } catch (err) {
        console.error("Error fetching license types:", err);
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseTypes();
  }, [token]);

  // Load Google Maps Script
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  // Initialize Formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: license?.companyName || "",
      jobTitle: license?.jobTitle || "",
      firstName: license?.firstName || "",
      lastName: license?.lastName || "",
      email: license?.email || "",
      phone: license?.phone || "",
      streetAddress1: license?.addressLine1 || "",
      streetAddress2: license?.addressLine2 || "",
      city: license?.city || "",
      state: license?.state || "",
      zipcode: license?.zip || "",
      country: license?.country || "",
      statuss: license?.statuss || "",
      licenseValidity: license?.licenseValidity || "",
      billingCycle: license?.billingCycle || "",
      billingCyclePrice: license?.billingCyclePrice || "",
      paymentMethod: license?.paymentMethod || "",
      licenseType: license?.licenseType || "",
      modules: license?.modules || updatedModules,
      agentsPlan: license?.modules.find((m) => m.name === "Agents")?.sub_modules?.find((sm) => sm.name === "Plan")?.value || "",
      brokeragePlan: license?.modules.find((m) => m.name === "Brokerage Firms")?.sub_modules?.find((sm) => sm.name === "Plan")?.value || "",
      plan: "",
    },
    validationSchema: Yup.object({
      companyName: Yup.string().required("Company Name is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      streetAddress1: Yup.string().required("Street Address 1 is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required"),
      zipcode: Yup.string().required("Zip code is required"),
      licenseType: Yup.string().required("License Type is required"),
      statuss: Yup.string().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setFormSubmitting(true);
    
      const selectedAgentPlanData = plans.find(
        (plan) => plan.planName === values.agentsPlan
      );
      const selectedBrokeragePlanData = plans.find(
        (plan) => plan.planName === values.brokeragePlan
      );

      try {        
        const updatedModulesWithSubModules = updatedModules.map((module) => ({
          ...module,
          sub_modules: module.sub_modules?.map((smodule) => {
            if (module.name === "Agents") {
              return {
                id: "",
                name: "Plan",
                value: selectedAgentPlanData?.planName || "",
                value_id: selectedAgentPlanData?.id || "",
                description: "Plan description",
                status: true,
                volume: 0,
                used_volume: 0,
              };
            } else if (module.name === "Brokerage Firms" && smodule.name === "Plan") {
              return {
                id: "",
                name: "Plan",
                value: selectedBrokeragePlanData?.planName || "",
                value_id: selectedBrokeragePlanData?.id || "",
                description: "Plan description",
                status: true,
                volume: 0,
                used_volume: 0,
              };
            }
            return smodule; // Return the original submodule if no conditions match
          }) || [], // Ensure fallback to an empty array if `sub_modules` is undefined
        }));
    
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${licenseId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: values.email,
              userType: values.licenseType.toLowerCase(),
              firstName: values.firstName,
              lastName: values.lastName,
              phone1: values.phone,
              jobTitle: values.jobTitle,
              firmDetails: {
                companyName: values.companyName,
                address: {
                  addressLine1: values.streetAddress1,
                  addressLine2: values.streetAddress2,
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  zip: values.zipcode,
                  phone: values.phone,
                },
              },
              licenseType: values.licenseType,
              licenseValidity: values.licenseValidity,
              billingCycle: values.billingCycle,
              billingCyclePrice: Number(values.billingCyclePrice),
              paymentMethod: values.paymentMethod,
              status: values.statuss,
              modulePermissions: updatedModulesWithSubModules.map((module) => ({
                _id: module.id,
                name: module.name,
                module_code: module.module_code,
                licenseTypeCode: values.licenseType,
                description: module.description,
                status: module.status,
                volume: module.volume,
                sub_modules: module.sub_modules, // Use the safely updated sub-modules
              })),
            }),
          }
        );
    
        if (!response.ok) {
          throw new Error("Failed to save data");
        }
    
        const result = await response.json();
        toast.success("License updated successfully!");
        navigate("/superadmin/license-registrations", {
          state: { successMessage: "License updated successfully!" },
        });
      } catch (err) {
        toast.error("An error occurred while updating the license.");
        console.error(err instanceof Error ? err.message : "Unknown error");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  // Debounce Google Places Autocomplete
  const handlePlaceChanged = useCallback(
    debounce(() => {
      if (autocompleteRef.current) {
        const place = autocompleteRef.current.getPlace();
        const addressComponents = place.address_components;

        if (addressComponents) {
          const getComponent = (type: string) => {
            const component = addressComponents.find((c) =>
              c.types.includes(type)
            );
            return component ? component.long_name : "";
          };

          const streetNumber = getComponent("street_number");
          const route = getComponent("route");
          const city =
            getComponent("locality") ||
            getComponent("administrative_area_level_2");
          const state = getComponent("administrative_area_level_1");
          const postalCode = getComponent("postal_code");
          const country = getComponent('country');
          
          const street = `${streetNumber} ${route}`.trim();
          formik.setFieldValue("streetAddress1", street);
          formik.setFieldValue("city", city);
          formik.setFieldValue("state", state);
          formik.setFieldValue("zipcode", postalCode);
          formik.setFieldValue('country', country);
        }
      }
    }, 300),
    [formik]
  );

  // Fetch Modules Based on License Type
  useEffect(() => {
    const fetchModules = async () => {
      if (!formik.values.licenseType) return;

      setLoadingModules(true);
      setError("");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/modules/license-type/${formik.values.licenseType}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch modules");
        }

        const data = await response.json();
        setDefaultModules(data || []);
      } catch (err) {
        console.error("Error fetching modules:", err);
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoadingModules(false);
      }
    };
    fetchModules();
  }, [formik.values.licenseType, token]);

  // Merge defaultModules and userModules into updatedModules
  useEffect(() => {
    const mergeModules = () => {
      if (!defaultModules || defaultModules.length === 0) {
        setUpdatedModules([]);
        return;
      }

      const merged = defaultModules.map((defaultModule) => {
        const userModule = userModules.find(
          (um) => um.module_code === defaultModule.module_code
        );

        return {
          ...defaultModule,
          status: userModule ? userModule.status : defaultModule.status,
          volume: userModule ? userModule.volume : defaultModule.volume,
        };
      });

      setUpdatedModules(merged);
      console.log("Updated Modules:", merged);
    };

    mergeModules();
  }, [defaultModules, userModules]);

  // Handle Module Status Change
  const handleModuleStatusChange = useCallback(
    (index: number, checked: boolean) => {
      setUpdatedModules((prevModules) => {
        const newModules = [...prevModules];
        newModules[index] = {
          ...newModules[index],
          status: checked,
        };
        return newModules;
      });
    },
    []
  );

  // Handle Module Volume Change
  const handleModuleVolumeChange = useCallback(
    (index: number, value: string) => {
      const parsedValue = parseInt(value, 10);
      setUpdatedModules((prevModules) => {
        const newModules = [...prevModules];
        newModules[index] = {
          ...newModules[index],
          volume: isNaN(parsedValue) ? 0 : parsedValue,
        };
        return newModules;
      });
    },
    []
  );

  if (loading || !isLoaded) return <div>Loading...</div>;

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="roles">
      <ToastContainer />
      <div className="card">
        <div className="card-body">
          <h2>Edit License </h2>

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="p-2 mt-4">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        {/* Company Name */}
                        <div className="col-md-12 mb-3">
                          <label className="form-label">Company Name</label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.companyName &&
                              formik.errors.companyName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="companyName"
                            name="companyName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.companyName}
                          />
                          {formik.touched.companyName &&
                          formik.errors.companyName ? (
                            <div className="invalid-feedback">
                              {formik.errors.companyName}
                            </div>
                          ) : null}
                        </div>

                        {/* Job Title */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Job Title/Role </label>
                          <select
                            className={`form-control ${
                              formik.touched.jobTitle && formik.errors.jobTitle
                                ? "is-invalid"
                                : ""
                            }`}
                            id="jobTitle"
                            name="jobTitle"
                            value={formik.values.jobTitle}
                            onChange={formik.handleChange}
                          >
                            <option value="">- Select -</option>
                            <option value="Owner">Owner</option>
                            <option value="Manager">Manager</option>
                          </select>
                          {formik.touched.jobTitle && formik.errors.jobTitle ? (
                            <div className="invalid-feedback">
                              {formik.errors.jobTitle}
                            </div>
                          ) : null}
                        </div>

                        {/* First Name */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.firstName &&
                              formik.errors.firstName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="firstName"
                            name="firstName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            required
                          />
                          {formik.touched.firstName &&
                          formik.errors.firstName ? (
                            <div className="invalid-feedback">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </div>

                        {/* Last Name */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Last Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.lastName && formik.errors.lastName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="lastName"
                            name="lastName"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            required
                          />
                          {formik.touched.lastName && formik.errors.lastName ? (
                            <div className="invalid-feedback">
                              {formik.errors.lastName}
                            </div>
                          ) : null}
                        </div>

                        {/* Email */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              formik.touched.email && formik.errors.email
                                ? "is-invalid"
                                : ""
                            }`}
                            id="email"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            required
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="invalid-feedback">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        {/* Phone */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.phone && formik.errors.phone
                                ? "is-invalid"
                                : ""
                            }`}
                            id="phone"
                            name="phone"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone}
                            required
                          />
                          {formik.touched.phone && formik.errors.phone ? (
                            <div className="invalid-feedback">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>

                        {/* Street Address 1 */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Street Address 1{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Autocomplete
                            onLoad={(autocomplete) =>
                              (autocompleteRef.current = autocomplete)
                            }
                            onPlaceChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.streetAddress1 &&
                                formik.errors.streetAddress1
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="streetAddress1"
                              name="streetAddress1"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.streetAddress1}
                              required
                            />
                          </Autocomplete>
                          {formik.touched.streetAddress1 &&
                          formik.errors.streetAddress1 ? (
                            <div className="invalid-feedback">
                              {formik.errors.streetAddress1}
                            </div>
                          ) : null}
                        </div>

                        {/* Street Address 2 */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Street Address 2{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="streetAddress2"
                            name="streetAddress2"
                            placeholder="Enter Address line 2"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.streetAddress2}
                          />
                          {formik.touched.streetAddress2 &&
                          formik.errors.streetAddress2 ? (
                            <div className="invalid-feedback">
                              {formik.errors.streetAddress2}
                            </div>
                          ) : null}
                        </div>

                        {/* City */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            City <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.city && formik.errors.city
                                ? "is-invalid"
                                : ""
                            }`}
                            id="city"
                            name="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                            required
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div className="invalid-feedback">
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </div>

                        {/* State */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            State <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.state && formik.errors.state
                                ? "is-invalid"
                                : ""
                            }`}
                            id="state"
                            name="state"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                            required
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div className="invalid-feedback">
                              {formik.errors.state}
                            </div>
                          ) : null}
                        </div>

                        {/* Zip Code */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            Zip code <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.zipcode && formik.errors.zipcode
                                ? "is-invalid"
                                : ""
                            }`}
                            id="zipcode"
                            name="zipcode"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.zipcode}
                            required
                          />
                          {formik.touched.zipcode && formik.errors.zipcode ? (
                            <div className="invalid-feedback">
                              {formik.errors.zipcode}
                            </div>
                          ) : null}
                        </div>

                        {/* Country */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Country</label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.country && formik.errors.country
                                ? "is-invalid"
                                : ""
                            }`}
                            id="country"
                            name="country"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.country}
                          />
                          {formik.touched.country && formik.errors.country ? (
                            <div className="invalid-feedback">
                              {formik.errors.country}
                            </div>
                          ) : null}
                        </div>

                        {/* Status */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Status </label>
                          <select
                            className={`form-control ${
                              formik.touched.statuss && formik.errors.statuss
                                ? "is-invalid"
                                : ""
                            }`}
                            id="statuss"
                            name="statuss"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.statuss}
                          >
                            <option value="">- Select -</option>
                            <option value="active">Active </option>
                            <option value="inactive">Inactive </option>
                            <option value="expired">Expired </option>
                            <option value="pending">Pending </option>
                            <option value="suspended">Suspended </option>
                          </select>
                          {formik.touched.statuss && formik.errors.statuss ? (
                            <div className="invalid-feedback">
                              {formik.errors.statuss}
                            </div>
                          ) : null}
                        </div>

                        {/* Billing Cycle */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Billing cycle </label>
                          <select
                            className={`form-control ${
                              formik.touched.billingCycle &&
                              formik.errors.billingCycle
                                ? "is-invalid"
                                : ""
                            }`}
                            id="billingCycle"
                            name="billingCycle"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.billingCycle}
                          >
                            <option value="">- Select -</option>
                            <option value="Month-Month">Month-Month</option>
                            <option value="12 months">12 months</option>
                          </select>
                          {formik.touched.billingCycle &&
                          formik.errors.billingCycle ? (
                            <div className="invalid-feedback">
                              {formik.errors.billingCycle}
                            </div>
                          ) : null}
                        </div>

                        {/* License Validity */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">
                            License validity duration{" "}
                          </label>
                          <select
                            className={`form-control ${
                              formik.touched.licenseValidity &&
                              formik.errors.licenseValidity
                                ? "is-invalid"
                                : ""
                            }`}
                            id="licenseValidity"
                            name="licenseValidity"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.licenseValidity}
                          >
                            <option value="">- Select -</option>
                            <option value="1 month">1 month</option>
                            <option value="3 months">3 months</option>
                            <option value="6 months">6 months</option>
                            <option value="12 months">12 months</option>
                          </select>
                          {formik.touched.licenseValidity &&
                          formik.errors.licenseValidity ? (
                            <div className="invalid-feedback">
                              {formik.errors.licenseValidity}
                            </div>
                          ) : null}
                        </div>

                        {/* Billing Cycle Price */}
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Billing Cycle Price </label>
                            <input
                              type="text"
                              name="billingCyclePrice"
                              id="billingCyclePrice"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.billingCyclePrice || ""}
                            />
                            {formik.touched.billingCyclePrice &&
                            formik.errors.billingCyclePrice ? (
                              <div className="invalid-feedback">
                                {formik.errors.billingCyclePrice}
                              </div>
                            ) : null}
                          </div>
                        </div>

                        {/* Payment Method */}
                        <div className="col-md-6 mb-3">
                          <label className="form-label">Payment method </label>
                          <select
                            className={`form-control ${
                              formik.touched.paymentMethod &&
                              formik.errors.paymentMethod
                                ? "is-invalid"
                                : ""
                            }`}
                            id="paymentMethod"
                            name="paymentMethod"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.paymentMethod}
                          >
                            <option value="">- Select -</option>
                            <option value="Card">Card</option>
                            <option value="Bank transfer">Bank transfer</option>
                          </select>
                          {formik.touched.paymentMethod &&
                          formik.errors.paymentMethod ? (
                            <div className="invalid-feedback">
                              {formik.errors.paymentMethod}
                            </div>
                          ) : null}
                        </div>

                        {/* Uncomment and handle if needed
                        <div className="col-md-6 mb-3">
                          <div className="form-group">
                            <label>Billing Cycle 12 Months</label>
                            <input
                              type="text"
                              name="billPerYear"
                              id="billPerYear"
                              className="form-control"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.billPerYear || ""}
                            />
                            {formik.touched.billPerYear &&
                            formik.errors.billPerYear ? (
                              <div className="invalid-feedback">
                                {formik.errors.billPerYear}
                              </div>
                            ) : null}
                          </div>
                        </div>
                        */}
                      </div>

                      {/* License Type and Module Permissions */}
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            {/* License Type */}
                            <div className="col-md-3">
                              <div className="form-group">
                                <label>
                                  License Type{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  name="licenseType"
                                  id="licenseType"
                                  className={`form-control mb-3 ${
                                    formik.touched.licenseType &&
                                    formik.errors.licenseType
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  value={formik.values.licenseType}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  required
                                >
                                  <option value="">Select License Type</option>
                                  {licenseTypes.map((licenseType) => (
                                    <option
                                      key={licenseType.id}
                                      value={licenseType.code}
                                    >
                                      {licenseType.name}
                                    </option>
                                  ))}
                                </select>
                                {formik.touched.licenseType &&
                                formik.errors.licenseType ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.licenseType}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            {/* Module Permissions */}
                            <div className="col-md-12">
                              <div className="permissions-list">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Module</th>
                                      <th>Yes/No</th>
                                      <th>Volume</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {updatedModules?.length > 0 ? (
                                      updatedModules.map((module, index) => (
                                        <tr key={module.module_code}>
                                          <td>{module.name}</td>
                                          <td>
                                            <input
                                              type="checkbox"
                                              checked={module.status}
                                              onChange={(e) =>
                                                handleModuleStatusChange(
                                                  index,
                                                  e.target.checked
                                                )
                                              }
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              min="0"
                                              value={module.volume}
                                              onChange={(e) =>
                                                handleModuleVolumeChange(
                                                  index,
                                                  e.target.value
                                                )
                                              }
                                              style={{ width: "100px" }}
                                            />
                                          </td>
                                          <td>
                                          {module.name == "Agents" &&
                                            Array.isArray(module.sub_modules) &&
                                            module.sub_modules.map(
                                              (smodule, index) =>
                                                smodule.name === "Plan" ? (
                                                  <div
                                                    className="form-group"
                                                    key={index}
                                                  >
                                                    <label>
                                                      {smodule.name}
                                                    </label>
                                                    <select
                                                      name="agentsPlan"
                                                      id="agentsPlan"
                                                      className="form-control"
                                                      value={
                                                        formik.values.agentsPlan
                                                      }
                                                      onBlur={formik.handleBlur}
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                    >
                                                      <option value="">
                                                        -Select-
                                                      </option>
                                                      {Array.isArray(plans) &&
                                                        plans.map(
                                                          (plan, index) => (
                                                            <option
                                                              value={
                                                                plan.planName
                                                              }
                                                              key={index}
                                                            >
                                                              {plan.planName}
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                  </div>
                                                ) : null
                                            )}

                                          {module.name == "Brokerage Firms" &&
                                            Array.isArray(module.sub_modules) &&
                                            module.sub_modules.map(
                                              (smodule, index) =>
                                                smodule.name === "Plan" ? (
                                                  <div
                                                    className="form-group"
                                                    key={index}
                                                  >
                                                    <label>
                                                      {smodule.name}
                                                    </label>
                                                    <select
                                                      name="brokeragePlan"
                                                      id="brokeragePlan"
                                                      className="form-control"
                                                      value={
                                                        formik.values
                                                          .brokeragePlan
                                                      }
                                                      onBlur={formik.handleBlur}
                                                      onChange={
                                                        formik.handleChange
                                                      }
                                                    >
                                                      <option value="">
                                                        -Select-
                                                      </option>
                                                      {Array.isArray(plans) &&
                                                        plans.map(
                                                          (plan, index) => (
                                                            <option
                                                              value={
                                                                plan.planName
                                                              }
                                                              key={index}
                                                            >
                                                              {plan.planName}
                                                            </option>
                                                          )
                                                        )}
                                                    </select>
                                                  </div>
                                                ) : null
                                            )}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={3}>
                                          No modules available
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Submit Button */}
                      <div className="mt-4">
                        <button
                          className="btn btn-success w-100"
                          type="submit"
                          disabled={formSubmitting}
                        >
                          {formSubmitting ? "Saving..." : "Save"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminEditLicense;
