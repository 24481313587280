import { useLocation, useNavigate } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type State = {
  _id: string;
  countryId: string;
  name: string;
  code: string;
};

const SuperAdminStates = () => {
  const { countryId } = useParams<{ countryId: string }>();
  const [states, setStates] = useState<State[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [deleting, setDeleting] = useState<string | null>(null); // Track deletion in progress
  const token = localStorage.getItem("sessionToken");

  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStates = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/location/states/country/${countryId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        console.log("Fetched States:", data); // Log the response
        setStates(data);
      } catch (err) {
        setStates([]); // Set an empty array as fallback
        setError(
          err instanceof Error ? err.message : "An unknown error occurred"
        );
      } finally {
        setLoading(false);
      }
    };    

    fetchStates();
  }, [token]);

  // if (states?.length === 0) {
  //   return <div>Loading...</div>;
  // }

  useEffect(() => {
    if(states!=null && states.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [states]);

  const handleDelete = async (stateId: string) => {

    setDeleting(stateId); // Set the license being deleted

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/location/states/${stateId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete the state.");
      }

      toast.success("State deleted successfully!");
      // Update the state by removing the deleted license
      setStates((prev) =>
        prev.filter((state) => state._id !== stateId)
      );
      setDeleting(null); // Clear the deleting state
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete the state.");
      setError(
        err instanceof Error
          ? err.message
          : "An unknown error occurred while deleting."
      );
      setDeleting(null); // Clear the deleting state
    }
  };

//   if (loading) {
//     return <div>Loading...</div>;
//   }

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>States </h2>
          <div className="text-end">
            <a
              href={`/superadmin/addState/${countryId}`}
              className="btn btn-primary float-right"
            >
              Add State
            </a>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          <th>S.No.</th> 
                          {/* <th>Country</th>                          */}
                          <th>State Name</th>
                          <th>Code</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                      {Array.isArray(states) &&
                          states.map((state, index) => (
                            <tr key={index}>                              
                              <td>{index + 1}</td>  
                              {/* <td>{state.countryId}</td>  */}
                              <td>
                                <Link
                                    to={`/superadmin/cities/${state._id}`}
                                    >
                                {state.name}
                                </Link>
                                </td>
                              <td>{state.code}</td>
                              <td>

                              <Link
                                  to={`/superadmin/cities/${state._id}`}
                                  className="btn btn-info waves-effect waves-light me-2"
                                >
                                  Counties
                                </Link>
                                
                                <Link
                                  to={`/superadmin/editState/${state._id}`}
                                  className="btn btn-warning waves-effect waves-light me-2"
                                >
                                  <i className="ri-pencil-fill align-bottom me-2"></i>{" "}
                                </Link>

                                <button
                                  onClick={() =>
                                    window.confirm(
                                      "Are you sure you want to delete this state?"
                                    ) && handleDelete(state._id)
                                  }
                                  className="btn btn-danger waves-effect waves-light"
                                >
                                  <i className="ri-delete-bin-fill align-bottom me-2"></i>
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuperAdminStates;
