import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";

// Auth Pages
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import TestApi from "./pages/auth/TestApi";

// Registration Pages
import SignageRegistration from "./pages/registrations/SignageRegistration";
import RealtorRegistration from "./pages/registrations/RealtorRegistration";
import AgentRegistration from "./pages/registrations/AgentRegistration";
import InstallerRegistration from "./pages/registrations/InstallerRegistration";

// Signage Dashboard and Layout
import SignageDashboard from "./pages/signage/SignageDashboard";
import SignageLayout from "./layouts/SignageLayout";

// Realtor Layout and Pages
import RealtorLayout from "./layouts/RealtorLayout";
import RealtorDashboard from "./pages/realtor/RealtorDashboard";
import RealtorTeammembers from "./pages/realtor/RealtorTeammembers";
import RealtorAddTeammember from "./pages/realtor/RealtorAddTeammember";
import RealtorViewTeammember from "./pages/realtor/RealtorViewTeammember";
import RealtorEditTeammember from "./pages/realtor/RealtorEditTeammember";
import RealtorOffices from "./pages/realtor/RealtorOffices";
import RealtorAddOffice from "./pages/realtor/RealtorAddOffice";
import RealtorViewOffice from "./pages/realtor/RealtorViewOffice";
import RealtorEditOffice from "./pages/realtor/RealtorEditOffice";
import RealtorAgents from "./pages/realtor/RealtorAgents";
import RealtorAddAgent from "./pages/realtor/RealtorAddAgent";
import RealtorViewAgent from "./pages/realtor/RealtorViewAgent";
import RealtorEditAgent from "./pages/realtor/RealtorEditAgent";
import RealtorSuppliers from "./pages/realtor/RealtorSuppliers";

// Super Admin Layout and Pages
import SuperAdminLayout from "./layouts/SuperAdminLayout";
import SuperAdminDashboard from "./pages/superadmin/SuperAdminDashboard";
import SuperAdminProfiles from "./pages/superadmin/SuperAdminProfiles";
import SuperAdminAddProfile from "./pages/superadmin/SuperAdminAddProfile";
import SuperAdminLicenseRegistrations from "./pages/superadmin/SuperAdminLicenseRegistrations";
import SuperAdminAddLicense from "./pages/superadmin/SuperAdminAddLicense";
import SignageWarehouses from "./pages/signage/SignageWarehouses";
import SignageAddWarehouse from "./pages/signage/SignageAddWarehouse";
import SignageEditWarehouse from "./pages/signage/SignageEditWarehouse";
import SignageViewWarehouse from "./pages/signage/SignageViewWarehouse";
import SuperAdminEditProfile from "./pages/superadmin/SuperAdminEditProfile";
import Error from "./pages/Error";
import SignageProfile from "./pages/signage/SignageProfile";
import RealtorProfile from "./pages/realtor/RealtorProfile";
import SuperAdminEditLicense from "./pages/superadmin/SuperAdminEditLicense";
import SuperAdminViewLicense from "./pages/superadmin/SuperAdminViewLicense";
import MasterAdminLayout from "layouts/MasterAdminLayout";
import MasterAdminDashboard from "pages/master/MasterAdminDashboard";
import MasterAdminModules from "pages/master/MasterAdminModules";
import MasterAdminAddModule from "pages/master/MasterAdminAddModule";
import AgentDashboard from "pages/agent/AgentDashboard";
import AgentProfile from "pages/agent/AgentProfile";
import AgentLayout from "layouts/AgentLayout";
import InstallerLayout from "layouts/InstallerLayout";
import InstallerDashboard from "pages/installer/InstallerDashboard";
import InstallerProfile from "pages/installer/InstallerProfile";
import RealtorSupplierPage from "pages/realtor/RealtorSupplierPage";
import SignageTeammembers from "pages/signage/SignageTeammembers";
import SignageAddTeammember from "pages/signage/SignageAddTeammember";
import SignageViewTeammember from "pages/signage/SignageViewTeammember";
import SignageEditTeammember from "pages/signage/SignageEditTeammember";
import RealtorGroups from "pages/realtor/RealtorGroups";
import RealtorAddGroup from "pages/realtor/RealtorAddGroup";
import RealtorViewGroup from "pages/realtor/RealtorViewGroup";
import RealtorEditGroup from "pages/realtor/RealtorEditGroup";
import MasterAdminEditModule from "pages/master/MasterAdminEditModule";
import SuperAdminAddPlanPermissions from "pages/superadmin/SuperAdminAddPlanPermissions";
import SuperAdminEditPlanPermissions from "pages/superadmin/SuperAdminEditPlanPermissions";
import SuperAdminAddPlanGroup from "pages/superadmin/SuperAdminAddPlanGroup";
import SuperAdminEditPlanGroup from "pages/superadmin/SuperAdminEditPlanGroup";
import RealtorAddGroupAgent from "pages/realtor/RealtorAddGroupAgent";
import SignageGeoLocations from "pages/signage/SignageGeoLocations";
import SignageAddGeoLocation from "pages/signage/SignageAddGeoLocation";
import SignageEditGeoLocation from "pages/signage/SignageEditGeoLocation";
import SignageTaxRates from "pages/signage/SignageTaxRates";
import SignageAddTaxRate from "pages/signage/SignageAddTaxRate";
import SignageEditTaxRate from "pages/signage/SignageEditTaxRate";
import SignageTaxGroups from "pages/signage/SignageTaxGroups";
import SignageAddTaxGroup from "pages/signage/SignageAddTaxGroup";
import SignageEditTaxGroup from "pages/signage/SignageEditTaxGroup";
import SignageTaxRules from "pages/signage/SignageTaxRules";
import SignageViewGeolocation from "pages/signage/SignageViewGeolocation";
import SignageAddTaxRule from "pages/signage/SignageAddTaxRule";
import SignageEditTaxRule from "pages/signage/SignageEditTaxRule";
import AgentInventory from "pages/agent/AgentInventory";
import AgentAddProduct from "pages/agent/AgentAddProduct";
import AgentBrands from "pages/agent/AgentBrands";
import AgentAddBrand from "pages/agent/AgentAddBrand";
import AgentCategories from "pages/agent/AgentCategories";
import AgentAddCategory from "pages/agent/AgentAddCategory";
import AgentEditBrand from "pages/agent/AgentEditBrand";
import AgentEditCategory from "pages/agent/AgentEditCategory";
import TeammemberLayout from "layouts/TeammemberLayout";
import TeammemberDashboard from "pages/teammember/TeammemberDashboard";
import TeammemberProfile from "pages/teammember/TeammemberProfile";
import TeammemberInventory from "pages/teammember/TeammemberInventory";
import TeammemberAddProduct from "pages/teammember/TeammemberAddProduct";
import TeammemberAddBrand from "pages/teammember/TeammemberAddBrand";
import TeammemberEditBrand from "pages/teammember/TeammemberEditBrand";
import TeammemberBrands from "pages/teammember/TeammemberBrands";
import TeammemberCategories from "pages/teammember/TeammemberCategories";
import TeammemberAddCategory from "pages/teammember/TeammemberAddCategory";
import TeammemberEditCategory from "pages/teammember/TeammemberEditCategory";
import TeammemberOffices from "pages/teammember/TeammemberOffices";
import TeammemberWarehouses from "pages/teammember/TeammemberWarehouses";
import TeammemberOfficeInventory from "pages/teammember/TeammemberOfficeInventory";
import TeammemberAddOfficeProduct from "pages/teammember/TeammemberAddOfficeProduct";
import AgentEditProduct from "pages/agent/AgentEditProduct";
import SignageInventory from "pages/signage/SignageInventory";
import SignageAddProduct from "pages/signage/SignageAddProduct";
import SignageEditProduct from "pages/signage/SignageEditProduct";
import SignageBrands from "pages/signage/SignageBrands";
import SignageAddBrand from "pages/signage/SignageAddBrand";
import SignageEditBrand from "pages/signage/SignageEditBrand";
import SignageCategories from "pages/signage/SignageCategories";
import SignageAddCategory from "pages/signage/SignageAddCategory";
import SignageEditCategory from "pages/signage/SignageEditCategory";
import TeammemberEditOfficeProduct from "pages/teammember/TeammemberEditOfficeProduct";
import SignageRotationalProducts from "pages/signage/SignageRotationalProducts";
import SignageEditRotationalProduct from "pages/signage/SignageEditRotationalProduct";
import SignageIncomingProducts from "pages/signage/SignageIncomingProducts";
import SignageProducts from "pages/signage/SignageProducts";
import SignageAddVariableProduct from "pages/signage/SignageAddVariableProduct";
import SignageAddAttribute from "pages/signage/SignageAddAttribute";
import SignageAttributes from "pages/signage/SignageAttributes";
import SignageEditAttribute from "pages/signage/SignageEditAttribute";
import SignageEditVariableProduct from "pages/signage/SignageEditVariableProduct";
import SignageEditIncomingProduct from "pages/signage/SignageEditIncomingProduct";
import AgentSuppliers from "pages/agent/AgentSuppliers";
import AgentSupplierPage from "pages/agent/AgentSupplierPage";
import SignageAgents from "pages/signage/SignageAgents";
import SignageAddAgent from "pages/signage/SignageAddAgent";
import SignageViewAgent from "pages/signage/SignageViewAgent";
import SignageEditAgent from "pages/signage/SignageEditAgent";
import SignageAddRotationalType from "pages/signage/SignageAddRotationalType";
import SignageRotationalTypes from "pages/signage/SignageRotationalTypes";
import SignageEditRotationalType from "pages/signage/SignageEditRotationalType";
import TeammemberViewOffice from "pages/teammember/TeammemberViewOffice";
import TeammemberAddAgent from "pages/teammember/TeammemberAddAgent";
import TeammemberAgents from "pages/teammember/TeammemberAgents";
import TeammemberEditAgent from "pages/teammember/TeammemberEditAgent";
import TeammemberAgentInventory from "pages/teammember/TeammemberAgentInventory";
import TeammemberAddAgentProduct from "pages/teammember/TeammemberAddAgentProduct";
import TeammemberEditAgentProduct from "pages/teammember/TeammemberEditAgentProduct";
import SignageBrokerageFirms from "pages/signage/SignageBrokerageFirms";
import SignageCountries from "pages/signage/SignageCountries";
import SignageAddCountry from "pages/signage/SignageAddCountry";
import SignageEditCountry from "pages/signage/SignageEditCountry";
import SignageStates from "pages/signage/SignageStates";
import SignageAddState from "pages/signage/SignageAddState";
import SignageEditState from "pages/signage/SignageEditState";
import SignageCities from "pages/signage/SignageCities";
import SignageAddCity from "pages/signage/SignageAddCity";
import RealtorInventory from "pages/realtor/RealtorInventory";
import RealtorAddProduct from "pages/realtor/RealtorAddProduct";
import RealtorEditProduct from "pages/realtor/RealtorEditProduct";
import RealtorBrands from "pages/realtor/RealtorBrands";
import RealtorAddBrand from "pages/realtor/RealtorAddBrand";
import RealtorEditBrand from "pages/realtor/RealtorEditBrand";
import RealtorCategories from "pages/realtor/RealtorCategories";
import RealtorAddCategory from "pages/realtor/RealtorAddCategory";
import RealtorEditCategory from "pages/realtor/RealtorEditCategory";
import SuperAdminCountries from "pages/superadmin/SuperAdminLocations";
import SuperAdminAddCountry from "pages/superadmin/SuperAdminAddCountry";
import SuperAdminEditCountry from "pages/superadmin/SuperAdminEditCountry";
import SuperAdminStates from "pages/superadmin/SuperAdminStates";
import SuperAdminAddState from "pages/superadmin/SuperAdminAddState";
import SuperAdminEditState from "pages/superadmin/SuperAdminEditState";
import SuperAdminCities from "pages/superadmin/SuperAdminCities";
import SuperAdminAddCity from "pages/superadmin/SuperAdminAddCity";
import SignageAddBrokerageFirm from "pages/signage/SignageAddBrokerageFirm";
import SignageBrokeragePage from "pages/signage/SignageBrokeragePage";
import SignageBrokerageAddTeammember from "pages/signage/SignageBrokerageAddTeammember";
import SignageBrokerageEditTeammember from "pages/signage/SignageBrokerageEditTeammember";
import SignageBrokerageAddOffice from "pages/signage/SignageBrokerageAddOffice";
import SignageBrokerageEditOffice from "pages/signage/SignageBrokerageEditOffice";
import SignageBrokerageAddAgent from "pages/signage/SignageBrokerageAddAgent";
import SignageBrokerageAddOfficeProduct from "pages/signage/SignageBrokerageAddOfficeProduct";
import SignageBrokerageAddAgentProduct from "pages/signage/SignageBrokerageAddAgentProduct";
import SignageBrokerageEditAgentProduct from "pages/signage/SignageBrokerageEditAgentProduct";
import RealtorOfficeInventory from "pages/realtor/RealtorOfficeInventory";
import RealtorAddOfficeProduct from "pages/realtor/RealtorAddOfficeProduct";
import RealtorAgentInventory from "pages/realtor/RealtorAgentInventory";
import RealtorAddAgentProduct from "pages/realtor/RealtorAddAgentProduct";
import RealtorEditAgentProduct from "pages/realtor/RealtorEditAgentProduct";
import SignageBrokerageEditAgent from "pages/signage/SignageBrokerageEditAgent";
import SignageBrokerageEditOfficeProduct from "pages/signage/SignageBrokerageEditOfficeProduct";
import RealtorEditOfficeProduct from "pages/realtor/RealtorEditOfficeProduct";
import SignageEditBrokerageFirm from "pages/signage/SignageEditBrokerageFirm";
import TeammemberProducts from "pages/teammember/TeammemberProducts";
import TeammemberAddSimpleProduct from "pages/teammember/TeammemberAddSimpleProduct";
import TeammemberAttributes from "pages/teammember/TeammemberAttributes";
import TeammemberAddAttribute from "pages/teammember/TeammemberAddAttribute";
import TeammemberEditSimpleProduct from "pages/teammember/TeammemberEditSimpleProduct";
import AgentViewProduct from "pages/agent/AgentViewProduct";
import RealtorViewAgentProduct from "pages/realtor/RealtorViewAgentProduct";
import RealtorViewOfficeProduct from "pages/realtor/RealtorViewOfficeProduct";
import SignageViewProduct from "pages/signage/SignageViewProduct";
import SignageViewVariableProduct from "pages/signage/SignageViewVariableProduct";
import TeammemberViewOfficeProduct from "pages/teammember/TeammemberViewOfficeProduct";
import TeammemberViewAgentProduct from "pages/teammember/TeammemberViewAgentProduct";
import TeammemberViewProduct from "pages/teammember/TeammemberViewProduct";
import TeammemberAddVariableProduct from "pages/teammember/TeammemberAddVariableProduct";
import TeammemberEditVariableProduct from "pages/teammember/TeammemberEditVariableProduct";
import SignageAgentInventory from "pages/signage/SignageAgentInventory";
import SignageOfficeInventory from "pages/signage/SignageOfficeInventory";
import SignageStandardInventory from "pages/signage/SignageStandardInventory";
import SignageDynamicCosts from "pages/signage/SignageDynamicCosts";
import SignageAddDynamicCost from "pages/signage/SignageAddDynamicCost";
import SignageEditDynamicCost from "pages/signage/SignageEditDynamicCost";
import SignageShippingCosts from "pages/signage/SignageShippingCosts";
import SignageAddShippingCost from "pages/signage/SignageAddShippingCost";
import SignageEditShippingCost from "pages/signage/SignageEditShippingCost";
import AgentAddresses from "pages/agent/AgentAddresses";
import AgentAddAddress from "pages/agent/AgentAddAddress";
import AgentEditAddress from "pages/agent/AgentEditAddress";
import RealtorAddresses from "pages/realtor/RealtorAddresses";
import RealtorAddAddress from "pages/realtor/RealtorAddAddress";
import RealtorEditAddress from "pages/realtor/RealtorEditAddress";
import TeammemberAddAddress from "pages/teammember/TeammemberAddAddress";
import TeammemberAddresses from "pages/teammember/TeammemberAddresses";
import TeammemberEditAddress from "pages/teammember/TeammemberEditAddress";
import SignageAddresses from "pages/signage/SignageAddresses";
import SignageAddAddress from "pages/signage/SignageAddAddress";
import SignageEditAddress from "pages/signage/SignageEditAddress";

// Adding root rendering for React DOM
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <Router>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<App />} errorElement={<Error />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/signage/register" element={<SignageRegistration />} />
          <Route path="/realtor/register" element={<RealtorRegistration />} />
          <Route path="/installer/register" element={<InstallerRegistration />} />
          <Route path="/agent/register" element={<AgentRegistration />} />
          <Route path="/testapi" element={<TestApi />} />

          {/* Signage Routes with Layout */}
          <Route element={<SignageLayout />}>
            <Route path="/signage/dashboard" element={<SignageDashboard />} />
            <Route path="/signage/profile" element={<SignageProfile />} />
            <Route path="/signage/teammembers" element={<SignageTeammembers />} />
            <Route path="/signage/add-teammember" element={<SignageAddTeammember />} />
            <Route path="/signage/view-teammember/:teammemberId" element={<SignageViewTeammember />} />
            <Route path="/signage/edit-teammember/:teammemberId" element={<SignageEditTeammember />} />
            <Route path="/signage/warehouses" element={<SignageWarehouses />} />
            <Route path="/signage/add-warehouse" element={<SignageAddWarehouse />} />
            <Route path="/signage/edit-warehouse/:warehouseId" element={<SignageEditWarehouse />} />
            <Route path="/signage/view-warehouse/:warehouseId" element={<SignageViewWarehouse />} />
            <Route path="/signage/geoLocations" element={<SignageGeoLocations />} />
            <Route path="/signage/addGeoLocation" element={<SignageAddGeoLocation />} />
            <Route path="/signage/editGeoLocation/:locationId" element={<SignageEditGeoLocation />} />
            <Route path="/signage/viewGeoLocation/:locationId" element={<SignageViewGeolocation />} />
            <Route path="/signage/taxRates" element={<SignageTaxRates />} />
            <Route path="/signage/addTaxRate" element={<SignageAddTaxRate />} />
            <Route path="/signage/editTaxRate/:taxId" element={<SignageEditTaxRate />} />
            <Route path="/signage/taxGroups" element={<SignageTaxGroups />} />
            <Route path="/signage/addTaxGroup" element={<SignageAddTaxGroup />} />
            <Route path="/signage/editTaxGroup/:taxId" element={<SignageEditTaxGroup />} />
            <Route path="/signage/taxRules" element={<SignageTaxRules />} />
            <Route path="/signage/addTaxRule" element={<SignageAddTaxRule />} />
            <Route path="/signage/editTaxRule/:taxId" element={<SignageEditTaxRule />} />
            <Route path="/signage/inventory" element={<SignageInventory />} />
            <Route path="/signage/standardInventory" element={<SignageStandardInventory />} />
            <Route path="/signage/agentInventory" element={<SignageAgentInventory />} />
            <Route path="/signage/officeInventory" element={<SignageOfficeInventory />} />
            <Route path="/signage/products" element={<SignageProducts />} />
            <Route path="/signage/addProduct" element={<SignageAddProduct />} />
            <Route path="/signage/addVariableProduct" element={<SignageAddVariableProduct />} />
            <Route path="/signage/editProduct/:productId" element={<SignageEditProduct />} />
            <Route path="/signage/editVariableProduct/:productId" element={<SignageEditVariableProduct />} />
            <Route path="/signage/viewProduct/:productId" element={<SignageViewProduct />} />
            <Route path="/signage/viewVariableProduct/:productId" element={<SignageViewVariableProduct />} />
            <Route path="/signage/brands" element={<SignageBrands />} />
            <Route path="/signage/addBrand" element={<SignageAddBrand />} />
            <Route path="/signage/editBrand/:brandId" element={<SignageEditBrand />} />
            <Route path="/signage/categories" element={<SignageCategories />} />
            <Route path="/signage/addCategory" element={<SignageAddCategory />} />
            <Route path="/signage/editCategory/:catId" element={<SignageEditCategory />} />
            <Route path="/signage/rotationalProducts" element={<SignageRotationalProducts />} />
            <Route path="/signage/editRotationalProduct/:agentId/:productId" element={<SignageEditRotationalProduct />} />
            <Route path="/signage/incomingProducts" element={<SignageIncomingProducts />} />
            <Route path="/signage/editIncomingProduct/:agentId/:productId" element={<SignageEditIncomingProduct />} />
            <Route path="/signage/attributes" element={<SignageAttributes />} />
            <Route path="/signage/addAttribute" element={<SignageAddAttribute />} />
            <Route path="/signage/editAttribute/:attributeId" element={<SignageEditAttribute />} />
            <Route path="/signage/agents" element={<SignageAgents />} />
            <Route path="/signage/add-agent" element={<SignageAddAgent />} />
            <Route path="/signage/view-agent/:agentId" element={<SignageViewAgent />} />
            <Route path="/signage/edit-agent/:agentId" element={<SignageEditAgent />} />
            <Route path="/signage/rotationaltypes" element={<SignageRotationalTypes />} />
            <Route path="/signage/addRotationalType" element={<SignageAddRotationalType />} />
            <Route path="/signage/editRotationalType/:rotationaltypeId" element={<SignageEditRotationalType />} />
            <Route path="/signage/countries" element={<SignageCountries />} />
            <Route path="/signage/addCountry" element={<SignageAddCountry />} />
            <Route path="/signage/editCountry/:countryId" element={<SignageEditCountry />} />
            <Route path="/signage/states/:countryId" element={<SignageStates />} />
            <Route path="/signage/addState/:countryId" element={<SignageAddState />} />
            <Route path="/signage/editState/:stateId" element={<SignageEditState />} />
            <Route path="/signage/cities/:stateId" element={<SignageCities />} />
            <Route path="/signage/addCity/:stateId" element={<SignageAddCity />} />
            <Route path="/signage/editCity/:cityId" element={<SignageEditState />} />
            {/* <Route path="/signage/locations" element={<SignageCountries />} /> */}
            <Route path="/signage/brokerageFirms" element={<SignageBrokerageFirms />} />
            <Route path="/signage/addBrokerageFirm" element={<SignageAddBrokerageFirm />} />
            <Route path="/signage/editBrokerageFirm/:firmId" element={<SignageEditBrokerageFirm />} />
            <Route path="/signage/brokerageFirm/:firmId" element={<SignageBrokeragePage />} />
            <Route path="/signage/brokerage/addTeammember/:firmId" element={<SignageBrokerageAddTeammember />} />
            <Route path="/signage/brokerage/editTeammember/:firmId/:teammemberId" element={<SignageBrokerageEditTeammember />} />
            <Route path="/signage/brokerage/addOffice/:firmId" element={<SignageBrokerageAddOffice />} />
            <Route path="/signage/brokerage/editOffice/:firmId/:officeId" element={<SignageBrokerageEditOffice />} />
            <Route path="/signage/brokerage/addOfficeProduct/:firmId" element={<SignageBrokerageAddOfficeProduct />} />
            <Route path="/signage/brokerage/editOfficeProduct/:officeId/:productId" element={<SignageBrokerageEditOfficeProduct />} />
            <Route path="/signage/brokerage/addAgent/:firmId" element={<SignageBrokerageAddAgent />} />
            <Route path="/signage/brokerage/editAgent/:firmId/:agentId" element={<SignageBrokerageEditAgent />} />
            <Route path="/signage/brokerage/addAgentProduct/:firmId" element={<SignageBrokerageAddAgentProduct />} />
            <Route path="/signage/brokerage/editAgentProduct/:agentId/:productId" element={<SignageBrokerageEditAgentProduct />} />
            <Route path="/signage/dynamic-costs" element={<SignageDynamicCosts />} />
            <Route path="/signage/addDynamicCost" element={<SignageAddDynamicCost />} />
            <Route path="/signage/editDynamicCost/:costId" element={<SignageEditDynamicCost />} />
            <Route path="/signage/shipping/shipping-costs" element={<SignageShippingCosts />} />
            <Route path="/signage/shipping/addShippingCost" element={<SignageAddShippingCost />} />
            <Route path="/signage/editShippingCost/:costId" element={<SignageEditShippingCost />} /> 
            <Route path="/signage/addresses" element={<SignageAddresses />} />
            <Route path="/signage/addAddress" element={<SignageAddAddress />} />
            <Route path="/signage/editAddress/:addressId" element={<SignageEditAddress />} />           
          </Route>

          {/* Realtor Routes with Layout */}
          <Route element={<RealtorLayout />}>
            <Route path="/realtor/dashboard" element={<RealtorDashboard />} />
            <Route path="/realtor/profile" element={<RealtorProfile />} />
            <Route path="/realtor/teammembers" element={<RealtorTeammembers />} />
            <Route path="/realtor/add-teammember" element={<RealtorAddTeammember />} />
            <Route path="/realtor/view-teammember/:teammemberId" element={<RealtorViewTeammember />} />
            <Route path="/realtor/edit-teammember/:teammemberId" element={<RealtorEditTeammember />} />
            <Route path="/realtor/offices" element={<RealtorOffices />} />
            <Route path="/realtor/add-office" element={<RealtorAddOffice />} />
            <Route path="/realtor/view-office/:officeId" element={<RealtorViewOffice />} />
            <Route path="/realtor/edit-office/:officeId" element={<RealtorEditOffice />} />
            <Route path="/realtor/agents" element={<RealtorAgents />} />
            <Route path="/realtor/add-agent" element={<RealtorAddAgent />} />
            <Route path="/realtor/view-agent/:agentId" element={<RealtorViewAgent />} />
            <Route path="/realtor/edit-agent/:agentId" element={<RealtorEditAgent />} />
            <Route path="/realtor/suppliers" element={<RealtorSuppliers />} />
            <Route path="/realtor/supplier/:supplierId" element={<RealtorSupplierPage />} />
            <Route path="/realtor/groups" element={<RealtorGroups />} />
            <Route path="/realtor/add-group" element={<RealtorAddGroup />} />
            <Route path="/realtor/view-group/:groupId" element={<RealtorViewGroup />} />
            <Route path="/realtor/edit-group/:groupId" element={<RealtorEditGroup />} />
            <Route path="/realtor/addGroupAgent/:groupId" element={<RealtorAddGroupAgent />} />
            <Route path="/realtor/inventory" element={<RealtorInventory />} />
            <Route path="/realtor/addProduct" element={<RealtorAddProduct />} />
            <Route path="/realtor/editProduct/:productId" element={<RealtorEditProduct />} />
            <Route path="/realtor/brands" element={<RealtorBrands />} />
            <Route path="/realtor/addBrand" element={<RealtorAddBrand />} />
            <Route path="/realtor/editBrand/:brandId" element={<RealtorEditBrand />} />
            <Route path="/realtor/categories" element={<RealtorCategories />} />
            <Route path="/realtor/addCategory" element={<RealtorAddCategory />} />
            <Route path="/realtor/editCategory/:catId" element={<RealtorEditCategory />} />
            <Route path="/realtor/officeInventory" element={<RealtorOfficeInventory />} />
            <Route path="/realtor/addOfficeProduct" element={<RealtorAddOfficeProduct />} />
            <Route path="/realtor/office/:officeId/editOfficeProduct/:productId" element={<RealtorEditOfficeProduct />} />
            <Route path="/realtor/office/:officeId/viewOfficeProduct/:productId" element={<RealtorViewOfficeProduct />} />
            <Route path="/realtor/agentInventory" element={<RealtorAgentInventory />} />
            <Route path="/realtor/addAgentProduct" element={<RealtorAddAgentProduct />} />
            <Route path="/realtor/agent/:agentId/editAgentProduct/:productId" element={<RealtorEditAgentProduct />} />
            <Route path="/realtor/agent/:agentId/viewAgentProduct/:productId" element={<RealtorViewAgentProduct />} />
            <Route path="/realtor/addresses" element={<RealtorAddresses />} />
            <Route path="/realtor/addAddress" element={<RealtorAddAddress />} />
            <Route path="/realtor/editAddress/:addressId" element={<RealtorEditAddress />} />
          </Route>

          {/* Protected Super Admin Routes with Layout */}
          {/* <Route element={<PrivateRoute />}> */}
            <Route element={<SuperAdminLayout />}>
              <Route path="/superadmin/dashboard" element={<SuperAdminDashboard />} />
              <Route path="/superadmin/profiles" element={<SuperAdminProfiles />} />
              <Route path="/superadmin/add-profile" element={<SuperAdminAddProfile />} />
              <Route path="/superadmin/edit-profile/:planId" element={<SuperAdminEditProfile />} />
              <Route path="/superadmin/license-registrations" element={<SuperAdminLicenseRegistrations />} />
              <Route path="/superadmin/add-license" element={<SuperAdminAddLicense />} />
              <Route path="/superadmin/edit-license/:licenseId" element={<SuperAdminEditLicense />} />
              <Route path="/superadmin/view-license/:licenseId" element={<SuperAdminViewLicense />} />
              <Route path="/superadmin/addPlanPermissions" element={<SuperAdminAddPlanPermissions />} />
              <Route path="/superadmin/edit-plan-permissions/:planPermissionsId" element={<SuperAdminEditPlanPermissions />} />
              <Route path="/superadmin/addPlanGroup" element={<SuperAdminAddPlanGroup />} />
              <Route path="/superadmin/editPlanGroup/:planGroupId" element={<SuperAdminEditPlanGroup />} />
              <Route path="/superadmin/locations" element={<SuperAdminCountries />} />
              <Route path="/superadmin/addCountry" element={<SuperAdminAddCountry />} />
              <Route path="/superadmin/editCountry/:countryId" element={<SuperAdminEditCountry />} />
              <Route path="/superadmin/states/:countryId" element={<SuperAdminStates />} />
              <Route path="/superadmin/addState/:countryId" element={<SuperAdminAddState />} />
              <Route path="/superadmin/editState/:stateId" element={<SuperAdminEditState />} />
              <Route path="/superadmin/cities/:stateId" element={<SuperAdminCities />} />
              <Route path="/superadmin/addCity/:stateId" element={<SuperAdminAddCity />} />
              {/* <Route path="/superadmin/editCity/:cityId" element={<SuperAdminEditCity />} /> */}
            </Route>
          {/* </Route> */}

          {/* Protected Master Admin Routes with Layout */}
          {/* <Route element={<PrivateRoute />}> */}
            <Route element={<MasterAdminLayout />}>
              <Route path="/master/dashboard" element={<MasterAdminDashboard />} />             
              <Route path="/master/modules" element={<MasterAdminModules />} />
              <Route path="/master/add-module" element={<MasterAdminAddModule />} />
              <Route path="/master/edit-module/:moduleId" element={<MasterAdminEditModule />} />
            </Route>
          {/* </Route> */}

          {/* Agent Routes with Layout */}
          <Route element={<AgentLayout />}>
            <Route path="/agent/dashboard" element={<AgentDashboard />} />
            <Route path="/agent/profile" element={<AgentProfile />} />
            <Route path="/agent/inventory" element={<AgentInventory />} />
            <Route path="/agent/addProduct" element={<AgentAddProduct />} />
            <Route path="/agent/editProduct/:productId" element={<AgentEditProduct />} />
            <Route path="/agent/viewProduct/:productId" element={<AgentViewProduct />} />
            <Route path="/agent/brands" element={<AgentBrands />} />
            <Route path="/agent/addBrand" element={<AgentAddBrand />} />
            <Route path="/agent/editBrand/:brandId" element={<AgentEditBrand />} />
            <Route path="/agent/categories" element={<AgentCategories />} />
            <Route path="/agent/addCategory" element={<AgentAddCategory />} />
            <Route path="/agent/editCategory/:catId" element={<AgentEditCategory />} />
            <Route path="/agent/suppliers" element={<AgentSuppliers />} />
            <Route path="/agent/supplier/:supplierId" element={<AgentSupplierPage />} />
            <Route path="/agent/addresses" element={<AgentAddresses />} />
            <Route path="/agent/addAddress" element={<AgentAddAddress />} />
            <Route path="/agent/editAddress/:addressId" element={<AgentEditAddress />} />
          </Route>

          {/* Installer Routes with Layout */}
          <Route element={<InstallerLayout />}>
            <Route path="/installer/dashboard" element={<InstallerDashboard />} />
            <Route path="/installer/profile" element={<InstallerProfile />} />
          </Route>

          {/* Team Member Routes with Layout */}
          <Route element={<TeammemberLayout />}>
            <Route path="/teammember/dashboard" element={<TeammemberDashboard />} />
            <Route path="/teammember/profile" element={<TeammemberProfile />} />
            <Route path="/teammember/products" element={<TeammemberProducts />} />
            <Route path="/teammember/addSimpleProduct" element={<TeammemberAddSimpleProduct />} />
            <Route path="/teammember/editSimpleProduct/:productId" element={<TeammemberEditSimpleProduct />} />
            <Route path="/teammember/viewProduct/:productId" element={<TeammemberViewProduct />} />
            <Route path="/teammember/addVariableProduct" element={<TeammemberAddVariableProduct />} />
            <Route path="/teammember/editVariableProduct/:productId" element={<TeammemberEditVariableProduct />} />
            <Route path="/teammember/viewVariableProduct/:productId" element={<TeammemberViewProduct />} />
            <Route path="/teammember/addProduct" element={<TeammemberAddProduct />} />
            <Route path="/teammember/brands" element={<TeammemberBrands />} />
            <Route path="/teammember/addBrand" element={<TeammemberAddBrand />} />
            <Route path="/teammember/editBrand/:brandId" element={<TeammemberEditBrand />} />
            <Route path="/teammember/categories" element={<TeammemberCategories />} />
            <Route path="/teammember/addCategory" element={<TeammemberAddCategory />} />
            <Route path="/teammember/editCategory/:catId" element={<TeammemberEditCategory />} />
            <Route path="/teammember/offices" element={<TeammemberOffices />} />
            <Route path="/teammember/warehouses" element={<TeammemberWarehouses />} />
            <Route path="/teammember/office/:officeId" element={<TeammemberViewOffice />} />
            <Route path="/teammember/officeInventory" element={<TeammemberOfficeInventory />} />
            <Route path="/teammember/addOfficeProduct" element={<TeammemberAddOfficeProduct />} />
            <Route path="/teammember/office/:officeId/editOfficeProduct/:productId" element={<TeammemberEditOfficeProduct />} />
            <Route path="/teammember/office/:officeId/viewOfficeProduct/:productId" element={<TeammemberViewOfficeProduct />} />
            <Route path="/teammember/agents" element={<TeammemberAgents />} />
            <Route path="/teammember/add-agent" element={<TeammemberAddAgent />} />
            <Route path="/teammember/edit-agent/:agentId" element={<TeammemberEditAgent />} />
            {/* <Route path="/teammember/view-agent/:agentId" element={<SignageViewAgent />} /> */}
            <Route path="/teammember/agentInventory" element={<TeammemberAgentInventory />} />
            <Route path="/teammember/addAgentProduct" element={<TeammemberAddAgentProduct />} />
            <Route path="/teammember/agent/:agentId/editAgentProduct/:productId" element={<TeammemberEditAgentProduct />} />
            <Route path="/teammember/agent/:agentId/viewAgentProduct/:productId" element={<TeammemberViewAgentProduct />} />
            <Route path="/teammember/attributes" element={<TeammemberAttributes />} />
            <Route path="/teammember/addAttribute" element={<TeammemberAddAttribute />} />
            <Route path="/teammember/editAttribute/:attributeId" element={<SignageEditAttribute />} />
            <Route path="/teammember/addresses" element={<TeammemberAddresses />} />
            <Route path="/teammember/addAddress" element={<TeammemberAddAddress />} />
            <Route path="/teammember/editAddress/:addressId" element={<TeammemberEditAddress />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  </React.StrictMode>
);

reportWebVitals();
