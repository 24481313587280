import React, { useState, useRef, useEffect } from "react";
import { useLoadScript, Autocomplete } from "@react-google-maps/api";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface Profile {
  id: string;
  companyName: string;
  companyWebsite: string;
  jobTitle: string;
  firstName: string;
  lastName: string;
  email: string;
  phone1: string;
  phone2: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  status: string;
  validityDuration: string | null;
  billingCycle: string | null;
  paymentMethod: string;
  licenseType: string;
  installationSpecialties: string[];
  yearsOfExperience: number;
  certificationsLicenses: string[];
  availability: string;
  preferredWorkLocations: string[];
}

const specialtiesOptions = [
  { value: "Residential Signs", label: "Residential Signs" },
  { value: "Commercial Signs", label: "Commercial Signs" },
  { value: "Real Estate Signs", label: "Real Estate Signs" },
];

const cities = [
  "New York",
  "Los Angeles",
  "Chicago",
  "Houston",
  "Phoenix",
  "Philadelphia",
  "San Antonio",
  "San Diego",
  "Dallas",
  "San Jose",
  "Austin",
  "Jacksonville",
  "Fort Worth",
  "Columbus",
  "Charlotte",
  "San Francisco",
  "Indianapolis",
  "Seattle",
  "Denver",
  "Washington D.C.",
  "Boston",
  "El Paso",
  "Nashville",
  "Detroit",
  "Oklahoma City",
  "Las Vegas",
  "Portland",
  "Memphis",
  "Louisville",
  "Baltimore",
  "Milwaukee",
  "Albuquerque",
  "Tucson",
  "Fresno",
  "Mesa",
  "Sacramento",
  "Atlanta",
  "Kansas City",
  "Colorado Springs",
  "Miami",
  "Raleigh",
  "Omaha",
  "Long Beach",
  "Virginia Beach",
  "Oakland",
  "Minneapolis",
  "Tulsa",
  "Arlington",
  "New Orleans",
  "Wichita",
  "Cleveland",
  "Tampa",
  "Bakersfield",
  "Aurora",
  "Anaheim",
  "Honolulu",
  "Santa Ana",
  "Riverside",
  "Corpus Christi",
  "Lexington",
  "Henderson",
  "Stockton",
  "St. Paul",
  "Cincinnati",
  "St. Louis",
  "Pittsburgh",
  "Greensboro",
  "Lincoln",
  "Anchorage",
  "Plano",
  "Orlando",
  "Irvine",
  "Newark",
  "Durham",
  "Chula Vista",
  "Toledo",
  "Fort Wayne",
  "St. Petersburg",
  "Laredo",
  "Jersey City",
  "Chandler",
  "Madison",
  "Lubbock",
  "Scottsdale",
  "Reno",
  "Buffalo",
  "Gilbert",
  "Glendale",
  "North Las Vegas",
  "Winston-Salem",
  "Chesapeake",
  "Norfolk",
  "Fremont",
  "Garland",
  "Irving",
  "Hialeah",
  "Richmond",
  "Boise",
  "Spokane",
  "Baton Rouge",
];

const cityOptions = cities.map((city) => ({
  value: city,
  label: city,
}));

const libraries: Array<"places"> = ["places"];
const apiKey: string = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "";

const InstallerProfile = () => {
  const token = localStorage.getItem("sessionToken");
  const userType = localStorage.getItem("userType");
  const userId = localStorage.getItem("userId");
  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useState<Profile | null>(null); // type-safe
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: apiKey,
    libraries,
  });

  const [address, setAddress] = useState("");
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!profile) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/users/${userId}`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          if (data && data.firmDetails && data.firmDetails.address) {
            setProfile({
              id: data.id || "",
              companyName: data.firmDetails.companyName || "",
              companyWebsite: data.firmDetails.companyWebsite || "",
              jobTitle: data.userType || "",
              firstName: data.firstName || "",
              lastName: data.lastName || "",
              email: data.email || "",
              phone1: data.phone1 || "",
              phone2: data.phone2 || "",
              addressLine1: data.firmDetails.address.addressLine1 || "",
              addressLine2: data.firmDetails.address.addressLine2 || "",
              city: data.firmDetails.address.city || "",
              state: data.firmDetails.address.state || "",
              zip: data.firmDetails.address.zip || "",
              country: data.firmDetails.address.country || "",
              status: data.status || "Active",
              validityDuration: data.licenseValidity || "",
              billingCycle: data.billingCycle || "",
              paymentMethod: data.paymentMethod || "",
              licenseType: data.licenseType || "",
              installationSpecialties:
                data.installerInfo.installationSpecialties || [],
              yearsOfExperience: data.installerInfo.yearsOfExperience || 0,
              certificationsLicenses:
                data.installerInfo.certificationsLicenses || [],
              availability: data.installerInfo.availability || "",
              preferredWorkLocations:
                data.installerInfo.preferredWorkLocations || [],
            });
          } else {
            console.error("Incomplete data", data);
          }
        } catch (error) {
          console.error("Error fetching license data:", error);
        }
      }
    };

    fetchProfile(); // Always call the fetchProfile function
  }, [userId, profile, token]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      companyName: profile?.companyName || "",
      companyWebsite: profile?.companyWebsite || "",
      jobTitle: profile?.jobTitle || "",
      firstName: profile?.firstName || "",
      lastName: profile?.lastName || "",
      email: profile?.email || "",
      phone1: profile?.phone1 || "",
      phone2: profile?.phone2 || "",
      streetAddress1: profile?.addressLine1 || "",
      streetAddress2: profile?.addressLine2 || "",
      city: profile?.city || "",
      state: profile?.state || "",
      zipcode: profile?.zip || "",
      country: profile?.country || "",
      status: "Active",
      validityDuration: "",
      billingCycle: "",
      paymentMethod: "",
      licenseType: profile?.licenseType || "",
      installationSpecialties: profile?.installationSpecialties || [],
      yearsOfExperience: profile?.yearsOfExperience || 0,
      certificationsLicenses: profile?.certificationsLicenses || [],
      availability: profile?.availability || "",
      preferredWorkLocations: profile?.preferredWorkLocations || [],
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "First Name can only contain letters")
        .min(2, "First Name must be at least 2 characters long")
        .max(50, "First Name cannot be longer than 50 characters")
        .required("First Name is required"),
      lastName: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Last Name can only contain letters")
        .min(2, "Last Name must be at least 2 characters long")
        .max(50, "Last Name cannot be longer than 50 characters")
        .required("Last Name is required"),
      phone1: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Phone number is required"),
      phone2: Yup.string().matches(
        /^\d{10}$/,
        "Phone number must be exactly 10 digits"
      ),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      companyName: Yup.string()
        .min(2, "Company Name must be at least 2 characters long")
        .max(50, "Company Name cannot be longer than 50 characters"),
      streetAddress1: Yup.string()
        .min(2, "Street Name must be at least 2 characters long")
        .max(50, "Street Name cannot be longer than 50 characters")
        .required("Street Name is required"),
      city: Yup.string()
        .min(2, "City must be at least 2 characters long")
        .max(50, "City cannot be longer than 50 characters"),
      state: Yup.string()
        .min(2, "State must be at least 2 characters long")
        .max(50, "State cannot be longer than 50 characters"),
    }),
    onSubmit: async (values) => {
      console.log("Submitting form...", values); // For debugging
      setFormSubmitting(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${userId}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              email: values.email,
              userType: "installer",
              firstName: values.firstName,
              lastName: values.lastName,
              phone1: values.phone1,
              phone2: values.phone2,
              jobTitle: values.jobTitle,
              firmDetails: {
                companyName: values.companyName,
                companyWebsite: values.companyWebsite,
                address: {
                  addressLine1: values.streetAddress1,
                  addressLine2: values.streetAddress2,
                  city: values.city,
                  state: values.state,
                  country: values.country,
                  zip: values.zipcode,
                  phone: values.phone1,
                },
              },
              licenseType: "INSTALLER",
              installerInfo: {
                installationSpecialties: values.installationSpecialties,
                yearsOfExperience: values.yearsOfExperience,
                certificationsLicenses: values.certificationsLicenses,
                availability: values.availability,
                preferredWorkLocations: values.preferredWorkLocations,
              },
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to save data");
        }

        const result = await response.json();
        toast.success("Profile updated successfully!");
        console.log("Form submission successful:", result);
      } catch (err) {
        console.error(err instanceof Error ? err.message : "Unknown error");
        toast.error("Failed to update Profile.");
      } finally {
        setFormSubmitting(false);
      }
    },
  });

  const handlePlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      const addressComponents = place?.address_components;

      if (addressComponents) {
        const getComponent = (type: string) => {
          const component = addressComponents.find((c) =>
            c.types.includes(type)
          );
          return component ? component.long_name : "";
        };

        const streetNumber = getComponent("street_number");
        const route = getComponent("route");
        const city =
          getComponent("locality") ||
          getComponent("administrative_area_level_2");
        const state = getComponent("administrative_area_level_1");
        const postalCode = getComponent("postal_code");

        const street = `${streetNumber} ${route}`.trim();
        formik.setFieldValue("streetAddress1", street);
        formik.setFieldValue("city", city);
        formik.setFieldValue("state", state);
        formik.setFieldValue("zipcode", postalCode);
      }
    }
  };

  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    // Split the input value by commas or any other delimiter and trim whitespace
    const keywordsArray = value
      .split(",")
      .map((keyword) => keyword.trim())
      .filter(Boolean);

    // Update Formik's state with the array
    formik.setFieldValue("certificationsLicenses", keywordsArray);
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="dashboard">
      <ToastContainer />
      <h2>Profile </h2>
      <div className="card">
        <div className="card-body p-4">
          <div className="mt-2">
            <h4>User Details</h4>
          </div>
          <div className="p-2 mt-4">
            {/* <form className="needs-validation" action="/"> */}
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.firstName}
                    required
                  />
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-danger">{formik.errors.firstName}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lastName}
                    required
                  />
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-danger">{formik.errors.lastName}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone1"
                    name="phone1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone1}
                    required
                  />
                  {formik.touched.phone1 && formik.errors.phone1 ? (
                    <div className="text-danger">{formik.errors.phone1}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Secondary Phone Number </label>
                  <input
                    type="text"
                    className="form-control"
                    id="phone2"
                    name="phone2"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone2}
                  />
                  {formik.touched.phone2 && formik.errors.phone2 ? (
                    <div className="text-danger">{formik.errors.phone2}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    readOnly
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </div>

                {/* <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Password <span className="text-danger">*</span>
                  </label>
                  <div className="position-relative auth-pass-inputgroup">
                    <input
                      type="password"
                      className="form-control pe-5 password-input"
                      id="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                      aria-describedby="password"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      required
                    />
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                      type="button"
                      id="password-addon"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                    {formik.touched.password && formik.errors.password ? (
                      <div className="text-danger">
                        {formik.errors.password}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Confirm Password <span className="text-danger">*</span>
                  </label>
                  <div className="position-relative auth-pass-inputgroup">
                    <input
                      type="password"
                      className="form-control pe-5 password-input"
                      id="cpassword"
                      name="cpassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cpassword}
                      aria-describedby="cpassword"
                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                      required
                    />
                    <button
                      className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                      type="button"
                      id="password-addon"
                    >
                      <i className="ri-eye-fill align-middle"></i>
                    </button>
                    {formik.touched.cpassword && formik.errors.cpassword ? (
                      <div className="text-danger">
                        {formik.errors.cpassword}
                      </div>
                    ) : null}
                  </div>
                </div> */}
              </div>
              <h4>Company Details </h4>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <label className="form-label">Company Name </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyName}
                  />
                  {formik.touched.companyName && formik.errors.companyName ? (
                    <div className="text-danger">
                      {formik.errors.companyName}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Company Website </label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyWebsite"
                    name="companyWebsite"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.companyWebsite}
                  />
                  <div className="invalid-feedback">
                    Please enter Company Website
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Job Title/Role </label>
                  <select
                    className="form-control"
                    id="jobTitle"
                    name="jobTitle"
                    value={formik.values.jobTitle} // Bind to Formik value
                    onChange={formik.handleChange}
                  >
                    <option value="">- Select -</option>
                    <option value="Owner">Owner</option>
                    <option value="Manager">Manager</option>
                  </select>
                  <div className="invalid-feedback">Please select role</div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Street Name <span className="text-danger">*</span>
                  </label>
                  <Autocomplete
                    onLoad={(autocomplete) =>
                      (autocompleteRef.current = autocomplete)
                    }
                    onPlaceChanged={handlePlaceChanged}
                  >
                    <input
                      type="text"
                      className="form-control"
                      id="streetAddress1"
                      name="streetAddress1"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.streetAddress1}
                      required
                    />
                  </Autocomplete>
                  {formik.touched.streetAddress1 &&
                  formik.errors.streetAddress1 ? (
                    <div className="text-danger">
                      {formik.errors.streetAddress1}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Address line 2 </label>
                  <input
                    type="text"
                    className="form-control"
                    id="streetAddress2"
                    name="streetAddress2"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.streetAddress2}
                  />
                  <div className="invalid-feedback">
                    Please enter Address line 2
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.city}
                  />
                  {formik.touched.city && formik.errors.city ? (
                    <div className="text-danger">{formik.errors.city}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">State</label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.state}
                  />
                  {formik.touched.state && formik.errors.state ? (
                    <div className="text-danger">{formik.errors.state}</div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Zip code</label>
                  <input
                    type="text"
                    className="form-control"
                    id="zipcode"
                    name="zipcode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.zipcode}
                  />
                  {formik.touched.zipcode && formik.errors.zipcode ? (
                    <div className="text-danger">{formik.errors.zipcode}</div>
                  ) : null}
                </div>
              </div>
              <h3>Installer Details </h3>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label className="form-label">
                    Installation Specialties{" "}
                  </label>
                  <Select
                    id="installationSpecialties"
                    name="installationSpecialties"
                    options={specialtiesOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    value={specialtiesOptions.filter((option) =>
                      formik.values.installationSpecialties.includes(
                        option.value
                      )
                    )}
                    onChange={(selectedOptions) => {
                      formik.setFieldValue(
                        "installationSpecialties",
                        selectedOptions
                          ? selectedOptions.map((option) => option.value)
                          : []
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />

                  {formik.touched.installationSpecialties &&
                  formik.errors.installationSpecialties ? (
                    <div className="text-danger">
                      {formik.errors.installationSpecialties}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-6 mb-3">
                  <label className="form-label">Years of Experience </label>
                  <input
                    type="number"
                    className="form-control"
                    id="yearsOfExperience"
                    name="yearsOfExperience"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.yearsOfExperience}
                  />
                  {formik.touched.yearsOfExperience &&
                  formik.errors.yearsOfExperience ? (
                    <div className="text-danger">
                      {formik.errors.yearsOfExperience}
                    </div>
                  ) : null}
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Certifications/Licenses </label>
                  <input
                    type="text"
                    className="form-control"
                    id="certificationsLicenses"
                    name="certificationsLicenses"
                    onChange={(e) => {
                      const value = e.target.value;
                      const keywordsArray = value
                        .split(",")
                        .map((keyword) => keyword.trim())
                        .filter(Boolean);
                      formik.setFieldValue(
                        "certificationsLicenses",
                        keywordsArray
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.certificationsLicenses.join(", ")} // Join the array as a string for display
                    placeholder="Enter certifications, separated by commas"
                  />
                  {formik.touched.certificationsLicenses &&
                  formik.errors.certificationsLicenses ? (
                    <div className="text-danger">
                      {formik.errors.certificationsLicenses}
                    </div>
                  ) : null}
                </div>

                <div className="col-md-6 mb-3">
                  <label className="form-label">Availability </label>
                  <select
                    className="form-control"
                    id="availability"
                    name="availability"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.availability}
                  >
                    <option value="">- Select -</option>
                    <option value="Full-Time">Full-Time</option>
                    <option value="Part-Time">Part-Time</option>
                    <option value="On-Demand">On-Demand</option>
                  </select>
                  {formik.touched.availability && formik.errors.availability ? (
                    <div className="text-danger">
                      {formik.errors.availability}
                    </div>
                  ) : null}
                </div>
                <div className="col-md-12 mb-3">
                  <label className="form-label">Preferred Work Locations</label>
                  {/* <input
                    type="text"
                    className="form-control"
                    id="preferredWorkLocations"
                    name="preferredWorkLocations"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.preferredWorkLocations}
                  /> */}
                  <Select
                    id="preferredWorkLocations"
                    name="preferredWorkLocations"
                    options={cityOptions}
                    isMulti // Enable multi-select
                    closeMenuOnSelect={false} // Keep the dropdown open while selecting
                    hideSelectedOptions={false} // Keep options visible
                    value={cityOptions.filter((option) =>
                      formik.values.preferredWorkLocations.includes(
                        option.value
                      )
                    )}
                    onChange={(selectedOptions) => {
                      formik.setFieldValue(
                        "preferredWorkLocations",
                        selectedOptions.map((option) => option.value)
                      );
                    }}
                  />
                  {formik.touched.preferredWorkLocations &&
                  formik.errors.preferredWorkLocations ? (
                    <div className="text-danger">
                      {formik.errors.preferredWorkLocations}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                <button
                  className="btn btn-success w-100"
                  type="submit"
                  disabled={formik.isSubmitting}
                >
                  {formik.isSubmitting ? "Updating..." : "Update"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallerProfile;
