import React from "react";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";
import "./SignageSidebar.css";

const SignageSidebar = () => {
  return (
    <div className="app-menu navbar-menu">
      <div className="navbar-brand-box">
        {/* Logo */}
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-dark.png" alt="" height="17" />
          </span>
        </Link>

        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src="/assets/images/logo-sm.png" alt="" height="22" />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo-light.png" alt="" height="17" />
          </span>
        </Link>

        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line"></i>
        </button>
      </div>

      {/* User Dropdown */}
      <div className="dropdown sidebar-user m-1 rounded">
        <button
          type="button"
          className="btn material-shadow-none"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="d-flex align-items-center gap-2">
            <img
              className="rounded header-profile-user"
              src="/assets/images/users/avatar-1.jpg"
              alt="Header Avatar"
            />
            <span className="text-start">
              <span className="d-block fw-medium sidebar-user-name-text">
                Anna Adame
              </span>
              <span className="d-block fs-14 sidebar-user-name-sub-text">
                <i className="ri-circle-fill fs-10 text-success align-baseline"></i>{" "}
                <span className="align-middle">Online</span>
              </span>
            </span>
          </span>
        </button>
        <div className="dropdown-menu dropdown-menu-end">
          <h6 className="dropdown-header">Welcome Anna!</h6>
          <Link to="/profile" className="dropdown-item">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Profile</span>
          </Link>
          <Link to="/messages" className="dropdown-item">
            <i className="mdi mdi-message-text-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Messages</span>
          </Link>
          <Link to="/tasks" className="dropdown-item">
            <i className="mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Taskboard</span>
          </Link>
          <Link to="/help" className="dropdown-item">
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Help</span>
          </Link>
          <div className="dropdown-divider"></div>
          <Link to="/profile" className="dropdown-item">
            <i className="mdi mdi-wallet text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">
              Balance: <b>$5971.67</b>
            </span>
          </Link>
          <Link to="/settings" className="dropdown-item">
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Settings</span>
          </Link>
          <Link to="/lockscreen" className="dropdown-item">
            <i className="mdi mdi-lock text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Lock screen</span>
          </Link>
          <Link to="/logout" className="dropdown-item">
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Logout</span>
          </Link>
        </div>
      </div>

      {/* Sidebar Navigation */}
      <div id="scrollbar">
        <div className="container-fluid">
          <ul className="navbar-nav" id="navbar-nav">
            <li className="nav-item">
              <Link to="/signage/dashboard" className="nav-link menu-link">
                <i className="ri-dashboard-2-line"></i>{" "}
                <span data-key="t-dashboards">Dashboard</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/teammembers" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Team Members</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/agents" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Agents</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/warehouses" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Warehouses</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/brokerageFirms" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Brokerage Firms</span>
              </Link>
            </li>

            <li className="nav-item">
              <a
                className="nav-link menu-link"
                href="#inventory-menu"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarLanding"
              >
                <i className="ri-rocket-line"></i>{" "}
                <span data-key="t-landing">Inventory</span>
              </a>
              <div className="collapse menu-dropdown" id="inventory-menu">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <a
                      href="/signage/products"
                      className="nav-link"
                      data-key="t-one-page"
                    >
                      {" "}
                      Standard Inventory{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/signage/agentInventory"
                      className="nav-link"
                      data-key="t-one-page"
                    >
                      {" "}
                      Agent Inventory{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/signage/officeInventory"
                      className="nav-link"
                      data-key="t-one-page"
                    >
                      {" "}
                      Office Inventory{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/signage/categories"
                      className="nav-link"
                      data-key="t-nft-landing"
                    >
                      {" "}
                      Categories{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/signage/brands"
                      className="nav-link"
                      data-key="t-job"
                    >
                      {" "}
                      Brands{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/signage/attributes"
                      className="nav-link"
                      data-key="t-job"
                    >
                      {" "}
                      Attributes{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link
                to="/signage/incomingProducts"
                className="nav-link menu-link"
              >
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Incoming Products </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/signage/rotationalProducts"
                className="nav-link menu-link"
              >
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Rotational Products </span>
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/signage/rotationaltypes"
                className="nav-link menu-link"
              >
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Rotational Types </span>
              </Link>
            </li>

            <li className="nav-item">
              <a
                className="nav-link menu-link"
                href="#taxesMenu"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="taxesMenu"
              >
                <i className="ri-rocket-line"></i>{" "}
                <span data-key="t-landing">Tax Settings</span>
              </a>
              <div className="collapse menu-dropdown" id="taxesMenu">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <Link
                      to="/signage/geoLocations"
                      className="nav-link menu-link"
                    >
                      <i className="ri-user-line"></i>{" "}
                      <span data-key="t-dashboards">Geo Locations</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/signage/taxRates" className="nav-link menu-link">
                      <i className="ri-user-line"></i>{" "}
                      <span data-key="t-dashboards">Tax Rates</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="/signage/taxGroups"
                      className="nav-link menu-link"
                    >
                      <i className="ri-user-line"></i>{" "}
                      <span data-key="t-dashboards">Tax Groups</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/signage/taxRules" className="nav-link menu-link">
                      <i className="ri-user-line"></i>{" "}
                      <span data-key="t-dashboards">Tax Rules</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            {/* <li className="nav-item">
              <a
                className="nav-link menu-link"
                href="#location-menu"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarLanding"
              >
                <i className="ri-rocket-line"></i>{" "}
                <span data-key="t-landing">Location Settings</span>
              </a>
              <div className="collapse menu-dropdown" id="location-menu">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <a
                      href="/signage/countries"
                      className="nav-link"
                      data-key="t-one-page"
                    >
                      {" "}
                      Countries{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/signage/states"
                      className="nav-link"
                      data-key="t-nft-landing"
                    >
                      {" "}
                      States{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="/signage/cities"
                      className="nav-link"
                      data-key="t-job"
                    >
                      {" "}
                      Cities{" "}
                    </a>
                  </li>
                  
                </ul>
              </div>
            </li> */}
            {/* <li className="nav-item">
              <Link to="/signage/customers" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Customers</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/teammembers" className="nav-link menu-link">
                <i className="ri-group-line"></i>{" "}
                <span data-key="t-dashboards">Team Members</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/agents" className="nav-link menu-link">
                <i className="ri-user-3-line"></i>{" "}
                <span data-key="t-dashboards">Agents</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/inventory" className="nav-link menu-link">
                <i className="ri-database-2-line"></i>{" "}
                <span data-key="t-dashboards">Inventory</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/work-orders" className="nav-link menu-link">
                <i className="ri-calendar-check-line"></i>{" "}
                <span data-key="t-dashboards">Work Orders</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/categories" className="nav-link menu-link">
                <i className="ri-folder-line"></i>{" "}
                <span data-key="t-dashboards">Categories</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/products" className="nav-link menu-link">
                <i className="ri-shopping-bag-line"></i>{" "}
                <span data-key="t-dashboards">Products</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/signage/orders" className="nav-link menu-link">
                <i className="ri-shopping-cart-line"></i>{" "}
                <span data-key="t-dashboards">Orders</span>
              </Link>
            </li> */}
            <li className="nav-item">
              <Link to="/signage/profile" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Profile</span>
              </Link>
            </li>
            {/* <li className="nav-item">
              <a
                className="nav-link menu-link"
                href="#settings-menu"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarLanding"
              >
                <i className="ri-rocket-line"></i>{" "}
                <span data-key="t-landing">Settings </span>
              </a>
              <div className="collapse menu-dropdown" id="settings-menu">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <a
                      href="/signage/dynamic-costs"
                      className="nav-link"
                      data-key="t-one-page"
                    >
                      {" "}
                      Dynamic costs{" "}
                    </a>
                  </li>
                  
                </ul>
              </div>
            </li> */}
            <li className="nav-item">
              <Link to="/signage/addresses" className="nav-link menu-link">
                <i className="ri-user-line"></i>{" "}
                <span data-key="t-dashboards">Addresses </span>
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link menu-link"
                href="#sidebarMultilevel"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarMultilevel"
              >
                <i className="ri-share-line"></i>{" "}
                <span data-key="t-multi-level">Settings </span>
              </a>
              <div className="collapse menu-dropdown" id="sidebarMultilevel">
                <ul className="nav nav-sm flex-column">
                  <li className="nav-item">
                    <a
                      href="/signage/dynamic-costs"
                      className="nav-link"
                      data-key="t-level-1.1"
                    >
                      {" "}
                      Dynamic costs{" "}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#sidebarAccount"
                      className="nav-link"
                      data-bs-toggle="collapse"
                      role="button"
                      aria-expanded="false"
                      aria-controls="sidebarAccount"
                      data-key="t-level-1.2"
                    >
                      {" "}
                      Shipping
                    </a>
                    <div className="collapse menu-dropdown" id="sidebarAccount">
                      <ul className="nav nav-sm flex-column">
                        <li className="nav-item">
                          <a
                            href="/signage/shipping/shipping-costs"
                            className="nav-link"
                            data-key="t-level-2.1"
                          >
                            {" "}
                            Shipping Cost{" "}
                          </a>
                        </li>
                       
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <LogoutButton />
            </li>
          </ul>
        </div>
      </div>

      <div className="sidebar-background"></div>
    </div>
  );
};

export default SignageSidebar;
