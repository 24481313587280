import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom"; // Import useParams from React Router
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type warehouseAddress = {
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  // phone: string;
};
type Warehouse = {
  id: string;
  label: string;
};

type Teammember = {
  id: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  phone: string;
  email: string;
  assignedWarehouses: Warehouse[];
  permissions: string[];
  // password: string;
  photo?: string;
  status: string;
  licenseType: string;
};

const SignageViewTeammember = () => {
  const { teammemberId } = useParams<{ teammemberId: string }>();
  const [teammember, setTeammember] = useState<Teammember | null>(null);
  const token = localStorage.getItem("sessionToken");
  const [error, setError] = useState<string | null>(null);

  // Fetch the warehouse data based on warehouseId
  useEffect(() => {
    const fetchTeammemberData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/users/${teammemberId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch teammember data");
        }
        const data = await response.json();
        if (data && data.firmDetails && data.firmDetails.address) {
          setTeammember({
            id: data.id || "",
            firstName: data.firstName || "",
            lastName: data.lastName || "",
            jobTitle: data.jobTitle || "",
            phone: data.phone1 || "",
            email: data.email || "",
            assignedWarehouses: data.warehouses || "",
            permissions: data.permissions || "",
            photo: data.photo || "",
            status: data.status || "Active",
            licenseType: data.licenseType || "",
          });

          // setUserModules(data.modulePermissions);
          // console.log(userModules);
        } else {
          console.error("Incomplete data", data);
        }
      } catch (error) {
        console.error("Error fetching teammember data:", error);
      }
    };

    if (teammemberId) {
      fetchTeammemberData();
    }
  }, [teammemberId]);

  if (!teammember) return <div>Loading Teammember Data...</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
              <img
                src="/assets/images/profile-bg.jpg"
                alt=""
                className="profile-wid-img"
              />
            </div>
          </div>
          <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
            <div className="row g-4">
              <div className="col-auto">
                <div className="avatar-lg">
                  <img
                    src="/assets/images/users/avatar-1.jpg"
                    alt="user-img"
                    className="img-thumbnail rounded-circle"
                  />
                </div>
              </div>

              <div className="col">
                <div className="p-2">
                  <h3 className="text-white mb-1">
                    {teammember.firstName} {teammember.lastName}
                  </h3>
                  <p className="text-white text-opacity-75">
                    {teammember.jobTitle}
                  </p>
                  {/* <div className="hstack text-white-50 gap-1">
                                        <div className="me-2"><i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>California, United States</div>
                                        <div>
                                            <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>Themesbrand
                                        </div>
                                    </div> */}
                </div>
              </div>

              <div className="col-12 col-lg-auto order-last order-lg-0">
                {/* <div className="row text text-white-50 text-center">
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">24.3K</h4>
                                            <p className="fs-14 mb-0">Followers</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-4">
                                        <div className="p-2">
                                            <h4 className="text-white mb-1">1.3K</h4>
                                            <p className="fs-14 mb-0">Following</p>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div>
                <div className="tab-content pt-4 text-muted">
                  <div
                    className="tab-pane active"
                    id="overview-tab"
                    role="tabpanel"
                  >
                    <div className="row">
                      <div className="col-xxl-3">
                        <div className="card">
                          <div className="card-body">
                            <h5 className="card-title mb-3">Profile</h5>
                            <div className="table-responsive">
                              <table className="table table-borderless mb-0">
                                <tbody>
                                  <tr>
                                    <th scope="row">
                                      Full Name :
                                    </th>
                                    <td className="text-muted">
                                      {teammember.firstName}{" "}
                                      {teammember.lastName}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      Role :
                                    </th>
                                    <td className="text-muted">
                                      {teammember.jobTitle}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      Phone :
                                    </th>
                                    <td className="text-muted">
                                      {teammember.phone}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      E-mail :
                                    </th>
                                    <td className="text-muted">
                                      {teammember.email}
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      Location :
                                    </th>
                                    <td className="text-muted">
                                      California, United States
                                    </td>
                                  </tr>
                                  <tr>
                                    <th scope="row">
                                      Status :
                                    </th>
                                    <td className="text-muted">
                                      {teammember.status}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xxl-9">
                        <div className="card">
                          <div className="card-body">
                            <div className="swiper project-swiper mt-4">
                              <h5 className="card-title">Assigned Warehouses </h5>

                              <div className="swiper-wrapper">
                              {teammember.assignedWarehouses.map((warehouse)=>(
                               <div className="swiper-slide">
                               <div className="card profile-project-card shadow-none profile-project-success mb-0 material-shadow">
                                 <div className="card-body p-4">
                                   <div className="d-flex">
                                     <div className="flex-grow-1 text-muted overflow-hidden">
                                       <h5 className="fs-14 text-truncate mb-1">
                                       <Link
                                to={`/signage/view-warehouse/${warehouse.id}`}>
                                           {warehouse.label}
                                         </Link>
                                       </h5>
                                       {/* <p className="text-muted text-truncate mb-0">
                                         {" "}
                                         Location : {warehouse.warehouseAddress.streetAddress1},
                                         {warehouse.warehouseAddress.streetAddress2},
                                         {warehouse.warehouseAddress.city},
                                         {warehouse.warehouseAddress.state},
                                         {warehouse.warehouseAddress.zip},
                                         {warehouse.warehouseAddress.country}
                                         <span className="fw-semibold text-body"></span>
                                       </p> */}
                                     </div>
                                   </div>
                                   <div className="d-flex mt-4">
                                     <div className="flex-grow-1">
                                       <div className="d-flex align-items-center gap-2">
                                         <div>
                                           <h5 className="fs-12 text-muted mb-0">
                                             {" "}
                                             Members :
                                           </h5>
                                         </div>
                                         <div className="avatar-group">
                                           <div className="avatar-group-item material-shadow">
                                             <div className="avatar-xs">
                                               <img
                                                 src="assets/images/users/avatar-4.jpg"
                                                 alt=""
                                                 className="rounded-circle img-fluid"
                                               />
                                             </div>
                                           </div>
                                           <div className="avatar-group-item material-shadow">
                                             <div className="avatar-xs">
                                               <img
                                                 src="assets/images/users/avatar-5.jpg"
                                                 alt=""
                                                 className="rounded-circle img-fluid"
                                               />
                                             </div>
                                           </div>
                                           <div className="avatar-group-item material-shadow">
                                             <div className="avatar-xs">
                                               <div className="avatar-title rounded-circle bg-light text-primary">
                                                 0
                                               </div>
                                             </div>
                                           </div>
                                           <div className="avatar-group-item material-shadow">
                                             <div className="avatar-xs">
                                               <img
                                                 src="assets/images/users/avatar-2.jpg"
                                                 alt=""
                                                 className="rounded-circle img-fluid"
                                               />
                                             </div>
                                           </div>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             </div> 
                              ))}                                                                
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageViewTeammember;
