import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import "./AgentSuppliers.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

interface FirmInfo {
  companyID: string;
  companyName: string;
  address: Address;
  specialities: string;
  regionsServed: string;
}

interface Address {
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface Supplier {
  id: string;  
  companyID: string;
  companyName: string;
  email: string;
  licenseType: string;
  status: string;
  billingCycle: string;
  licenseValidity: string;
  firstName: string;
  lastName: string;
  userType: string;
  firmDetails: FirmInfo;
  modulePermissions: string[];
  created_at: string;
}

type LinkedSupplier = {
  supplier_id: string;
  name: string;
  email: string;
}

const AgentSuppliers = () => {
  const [suppliers, setSuppliers] = useState<Supplier[]>([]);
  const [linkedSuppliers, setLinkedSuppliers] = useState<LinkedSupplier[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("sessionToken");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLinkedInstallers = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/agent/suppliers`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch installers data");
        }

        const data = await response.json();
        console.log("Fetched installers Data:", data);

        if (Array.isArray(data)) {
          setLinkedSuppliers(data);
        } else {
          console.error("Unexpected data format:", data);
        }
      } catch (error) {
        console.error("Error fetching installers data:", error);
        setError("Failed to fetch installers data.");
      } finally {
        setLoading(false);
      }
    };

    // Only fetch if the installers array is empty
    if (linkedSuppliers.length === 0) {
      fetchLinkedInstallers();
    }
  }, [token]);

  useEffect(() => {
    const fetchSuppliers = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users?userType=signage_firm`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
  
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
  
        const data: Supplier[] = await response.json();
        console.log("Fetched Suppliers:", data);
  
        // Check if data contains the expected structure
        if (Array.isArray(data)) {
          setSuppliers(data);
        } else {
          console.error("Unexpected API response:", data);
          setSuppliers([]);
        }
      } catch (err) {
        console.error("Error fetching suppliers:", err);
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };
  
    fetchSuppliers();
  }, [token]);

  const filteredSuppliers = suppliers.filter((supplier) => {
    console.log("Filtering supplier:", supplier);
    return supplier.userType === "signage_firm";
  });

  function isSupplierLinked(supplierId: string): boolean {
    console.log('Linked installers',linkedSuppliers);
    console.log('Supplier id to check',supplierId);
    return linkedSuppliers.some(installer => installer.supplier_id === supplierId);
}

  const handleLinkSupplier = async (supplierId: string, supplierEmail: string, supplierName: string) => {
    console.log("Payload:", {
      supplier_id: supplierId,
      email: supplierEmail,
      name: supplierName,
    });
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/agent/link-supplier`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            supplier_id: supplierId,
            email: supplierEmail,
            name: supplierName, // Ensure this is correctly passed
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to link.");
      }
  
      toast.success("Supplier linked successfully!");
      navigate("/agent/suppliers", {
        state: { successMessage: "Supplier linked successfully!" },
      });
    } catch (err) {
      console.error("API Error:", err);
      toast.error("Failed to link the supplier.");
      setError(err instanceof Error ? err.message : "An unknown error occurred while linking.");
    }
  };
  
  const handleUnlinkSupplier = async (supplierId: string) => {
    console.log("Payload:", {
      supplier_id: supplierId
    });
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/agent/suppliers/unlink`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            supplier_id: supplierId
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error("Failed to unlink.");
      }
  
      toast.success("Supplier unlinked successfully!");
      navigate("/agent/suppliers", {
        state: { successMessage: "Supplier linked successfully!" },
      });
    } catch (err) {
      console.error("API Error:", err);
      toast.error("Failed to unlink the supplier.");
      setError(err instanceof Error ? err.message : "An unknown error occurred while unlinking.");
    }
  };

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Suppliers</h2>

          {loading && <p>Loading suppliers...</p>}
          {error && <p className="text-danger">Error: {error}</p>}

          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              {/* Search and Filter Section */}
              <div className="card">
                <div className="card-header border-0 rounded">
                  <div className="row g-2">
                    <div className="col-xl-3">
                      <div className="search-box">
                        <input
                          type="text"
                          className="form-control"
                          id="searchResultList"
                          placeholder="Search for sellers & owner name..."
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                    <div className="col-xxl-3 ms-auto">
                      <select className="form-control" id="category-select">
                        <option value="All">Select Categories</option>
                        <option value="Computers & Electronics">
                          Computers & Electronics
                        </option>
                        <option value="Food Service">Food Service</option>
                        <option value="Health & Medicine">
                          Health & Medicine
                        </option>
                        <option value="Manufacturer">Manufacturer</option>
                        <option value="Retailer">Retailer</option>
                      </select>
                    </div>
                    <div className="col-lg-auto">
                      <div className="hstack gap-2">
                        <button type="button" className="btn btn-danger">
                          <i className="ri-equalizer-fill me-1 align-bottom"></i>{" "}
                          Filters
                        </button>
                        {/* <button
                          className="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target="#addSeller"
                        >
                          <i className="ri-add-fill me-1 align-bottom"></i> Add
                          Supplier
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* Supplier Cards */}
              <div className="row mt-4" id="seller-list">
                {filteredSuppliers && suppliers
                  .filter((supplier) => supplier.userType === "signage_firm") // Filter out 'super_admin' suppliers
                  .map((supplier) => (
                    <div key={supplier.id} className="col-xl-3 col-lg-6">
                      <div className="card ribbon-box right overflow-hidden">
                        <div className="card-body text-center p-4">
                          <div className="ribbon ribbon-info ribbon-shape trending-ribbon">
                            <i className="ri-flashlight-fill text-white align-bottom"></i>
                            <span className="trending-ribbon-text">
                              Trending
                            </span>
                          </div>
                          <img
                            src="/assets/images/companies/img-1.png"
                            alt="Supplier Logo"
                            height="45"
                          />
                          <h5 className="mb-1 mt-4">
                            <Link
                              to={`/realtor/supplier/${supplier.id}`}
                              className="link-primary"
                            >
                              {supplier.firmDetails.companyName}
                            </Link>
                          </h5>
                          <p className="text-muted mb-4">
                            {supplier.firmDetails.address.city}
                          </p>

                          <div className="row justify-content-center">
                            <div className="col-lg-12">
                              <h5>Specialities:</h5>
                              <span className="text-muted">
                                {supplier.firmDetails.specialities}
                              </span>
                            </div>
                            <div className="col-lg-12">
                              <h5>Regions Served</h5>
                              <span className="text-muted">
                                {supplier.firmDetails.regionsServed}
                              </span>
                            </div>
                          </div>

                          <div className="mt-4">
                            <Link
                              to={`/agent/supplier/${supplier.id}`}
                              className="btn btn-info w-100"
                            >
                              View Details
                            </Link>
                          </div>
                          <div className="mt-4">

                          {isSupplierLinked(supplier.id) ? (
  <button
    type="button"
    onClick={() =>
      window.confirm("Do you want to unlink this Supplier?") &&
      handleUnlinkSupplier(supplier.id)
    }
    className="btn btn-danger w-100"
  >
    Unlink
  </button>
) : (
  <button
    type="button"
    onClick={() =>
      window.confirm("Do you want to link this Supplier?") &&
      handleLinkSupplier(
        supplier.id,
        supplier.email,
        supplier.firmDetails.companyName
      )
    }
    className="btn btn-success w-100"
  >
    Link
  </button>
)}

                            
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              {!loading && suppliers.length === 0 && (
                <p>No suppliers available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>    
  );
};

export default AgentSuppliers;
