import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "../../assets/libs/bootstrap/js/bootstrap.bundle.min";
import "./RealtorGroups.css";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";

type Agent = {
  id: string;
  // groupName: string;
  // status: string;
};

type Group = {
  id: string;
  name: string;
  // description: string;
  agents: Agent[];
  status: boolean;
};

const RealtorGroups = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const token = localStorage.getItem("sessionToken");
  
  const [toastShown, setToastShown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.successMessage && !toastShown) {
      toast.success(location.state.successMessage);
      setToastShown(true);
      location.state.successMessage = null;
    }
  }, [location.state, toastShown]);
  
  
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/groups`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (!response.ok) {
          throw new Error("Failed to fetch groups.");
        }
        const data = await response.json();
        setGroups(data);
      } catch (err) {
        toast.error("Failed to load groups.");
        setError(err instanceof Error ? err.message : "An unknown error occurred");
      } finally {
        setLoading(false);
      }
    };
  
    fetchGroups();
  }, [token]);
  

  const handleDelete = async (groupId: string) => {
    setLoading(true);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/groups/${groupId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setGroups(groups.filter((group) => group.id !== groupId));
      toast.success("Group deleted successfully.");
      navigate(0);
    } catch (err) {
      toast.error("Failed to delete group.");
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(groups!=null && groups.length > 0) {
      const table = $("#transactions").DataTable({
        responsive: false,
        destroy: true,
        lengthChange: false,
        ordering: false,
      });

      return () => {
        table.destroy();
      };
    }
  }, [groups]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-danger">{error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>Groups</h2>
          {/* <button onClick={() => toast.success("Test message")}>Show Test Toast</button> */}

          <div className="text-end">
            <Link to="/realtor/add-group" className="btn btn-primary float-right">
              Add Group
            </Link>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table
                      id="transactions"
                      className="table table-bordered dt-responsive nowrap table-striped align-middle"
                      style={{ width: "100%" }}
                    >
                      <thead>
                        <tr>
                          {/* <th scope="col" style={{ width: "10px" }}>
                            <div className="form-check">
                              <input
                                className="form-check-input fs-15"
                                type="checkbox"
                                id="checkAll"
                                value="option"
                              />
                            </div>
                          </th> */}
                          <th>S.No.</th>
                          <th>Group Name</th>
                          <th>Status</th>
                          {/* <th>Total Agents</th> */}
                          <th>Action</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {groups && groups.map((group: Group, index) => (
                          <tr key={index}>
                            {/* <th scope="row">
                              <div className="form-check">
                                <input
                                  className="form-check-input fs-15"
                                  type="checkbox"
                                  name="checkAll"
                                  value="option1"
                                />
                              </div>
                            </th> */}
                            <td>{index + 1}</td>
                            <td>
                              <Link
                                to={`/realtor/view-group/${group.id}`}
                              >
                              {group.name}
                              </Link>
                              </td>
                            <td>
                              {(group.status)?'Active':'Inactive'}
                            </td>
                            {/* <td></td> */}
                            <td>
                              <Link
                                to={`/realtor/addGroupAgent/${group.id}`}
                                className="btn btn-secondary waves-effect waves-light"
                              >
                                Add Group Agent
                              </Link>
                              </td>
                            <td>
                              <Link
                                to={`/realtor/view-group/${group.id}`}
                                className="btn btn-secondary waves-effect waves-light"
                              >
                                <i className="ri-eye-fill align-bottom me-2"></i>
                              </Link>
                              <Link
                                to={`/realtor/edit-group/${group.id}`}
                                className="btn btn-warning waves-effect waves-light"
                              >
                                <i className="ri-pencil-fill align-bottom me-2"></i>
                              </Link>
                              <button
                                onClick={() =>  window.confirm(
                                    "Are you sure you want to delete this group?"
                                  ) && handleDelete(group.id)}
                                className="btn btn-danger waves-effect waves-light"
                              >
                                <i className="ri-delete-bin-fill align-bottom me-2"></i>
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealtorGroups;
