import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/icons.min.css";
import "../../assets/css/app.css";
import "../../assets/css/custom.min.css";

type CountyZipList = {
  county: string;
  zipList: string[];
};

type Geolocation = {
  id: string;
  country: string;
  countryCode: string;
  state: string;
  stateCode: string;
  countyZipList: CountyZipList[];
};

const SignageViewGeolocation: React.FC = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const [geolocation, setGeolocation] = useState<Geolocation | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const token = localStorage.getItem("sessionToken");

  useEffect(() => {
    const fetchGeolocation = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/geo-locations/${locationId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        if (!response.ok) {
          throw new Error("Failed to fetch geolocation data");
        }
        const data: Geolocation = await response.json();
        setGeolocation(data);
      } catch (error) {
        setError("Error fetching geolocation data. Please try again later.");
        console.error("Error fetching geolocation data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (locationId) {
      fetchGeolocation();
    }
  }, [locationId, token]);

  if (loading) return <div>Loading Geolocation Data...</div>;
  if (error) return <div className="alert alert-danger">{error}</div>;

  return (
    <div className="teammember">
      <div className="card">
        <div className="card-body">
          <h2>View Geolocation Details</h2>
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-12 col-xl-12">
              <div className="card mt-4 card-bg-fill">
                <div className="card-body p-4">
                  <div className="table-container">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th colSpan={4}>Geolocation Information</th>
                        </tr>
                        <tr>
                          <th>Country:</th>
                          <td>{geolocation?.country ?? "N/A"}</td>
                          <th>State:</th>
                          <td>{geolocation?.state ?? "N/A"}</td>
                        </tr>
                        <tr>
                          <th colSpan={4}>Counties List</th>
                        </tr>
                        {geolocation?.countyZipList &&
                          geolocation.countyZipList.map((countyObj, index) => (
                            <tr key={index}>
                              <th>County:</th>
                              <td>{countyObj.county || "N/A"}</td>
                              <th>Zip Codes:</th>
                              <td>
                                {countyObj.zipList.length > 0
                                  ? countyObj.zipList.join(", ")
                                  : "N/A"}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignageViewGeolocation;
